import { apiClient, ApiClientConfig, PaginatedRequestParams } from 'api';
import { axiosInstance } from 'api/axios-instance';
import { CONFIG } from 'config';
import { UpdateTermDTO } from 'features/community/api/community-api.service';
export type CreateLabelDTO = {
  title?: string;
  description?: string | null;
};
export type UpdateLabelDTO = {
  id?: string;
  title?: string;
  description?: string;
  title_arabic?: string;
  description_arabic?: string;
};
export const PackageApiService = () => {
  const baseURL = process.env.REACT_APP_BASE_V2_URL;
  const replaceSubstring = (
    input: string,
    target: string,
    replacement: string,
  ): string => {
    // alert(input);
    // alert(target);
    // alert(replacement);
    const startIndex = input.indexOf(target);
    // alert(startIndex);
    const endIndex = startIndex + target.length;
    // alert(endIndex);
    const replacedStr =
      input.slice(0, startIndex) + replacement + input.slice(endIndex);

    return replacedStr;
  };
  const getPackage = async (
    params: PaginatedRequestParams,
    callConfig?: Partial<ApiClientConfig>,
  ) => {
    const response = await apiClient<any>({
      ...callConfig,
      endpoint: `${baseURL}/admin/packages`,
      params,
    });

    return response.data;
  };
  const getPackageBooking = async (
    params: PaginatedRequestParams,
    callConfig?: Partial<ApiClientConfig>,
  ) => {
    const response = await apiClient<any>({
      ...callConfig,
      endpoint: `${baseURL}/admin/packages/PackageBookings`,
      params,
    });

    return response.data;
  };
  const createCancellation = async (payload: any) => {
    const response = await apiClient({
      endpoint: `${baseURL}/admin/Packages/SetCancellatoinPolicy`,
      method: 'POST',
      data: payload,
    });

    return response.data;
  };
  const updateCancellation = async (
    payload: UpdateTermDTO,
    callConfig?: Partial<ApiClientConfig>,
  ) => {
    const { id, ...data } = payload;

    const response = await apiClient<any>({
      ...callConfig,
      endpoint: `${baseURL}/admin/Packages/SetCancellatoinPolicy`,
      method: 'POST',
      data: {
        id,
        ...data, // Include other data from the payload
      },
    });

    return response.data;
  };
  const getPackageBookingExport = async (
    params: PaginatedRequestParams,
    callConfig?: Partial<ApiClientConfig>,
  ) => {
    const response = await apiClient<any>({
      ...callConfig,
      endpoint: `${baseURL}/admin/packages/PackageBookings`,
      params,
    });

    return response.data;
  };

  const getBookingdetails = async (
    id: string,
    callConfig?: Partial<ApiClientConfig>,
  ) => {
    const response = await apiClient<any>({
      ...callConfig,
      endpoint: `${baseURL}/admin/packages/PackageBookings?${id}`,
    });

    return response.data;
  };
  const getPackageId = async (
    id: string,
    callConfig?: Partial<ApiClientConfig>,
  ) => {
    const response = await apiClient<any>({
      ...callConfig,
      endpoint: `${baseURL}/admin/packages/${id}`,
    });

    return response.data;
  };
  // const getBookingdetails = async (
  //   id: string,
  //   payload: any,
  //   callConfig?: Partial<ApiClientConfig>,
  // ) => {
  //   const response = await apiClient<any>({
  //     ...callConfig,
  //     endpoint: `${baseURL}/admin/packages/PackageBookings?${id}`,
  //     data: payload, // Include the payload in the request body
  //   });

  //   return response.data;
  // };
  const updatePackage = async (
    payload: any,
    callConfig?: Partial<ApiClientConfig>,
  ) => {
    const { id, ...data } = payload;

    const response = await apiClient<any>({
      ...callConfig,
      endpoint: `${baseURL}/admin/packages/update`,
      method: 'POST',
      data: {
        id,
        ...data, // Include other data from the payload
      },
    });

    return response.data;
  };
  const createPackage = async (payload: any) => {
    const response = await apiClient({
      endpoint: `${baseURL}/admin/packages/create`,
      method: 'POST',
      data: payload,
    });

    return response.data;
  };
  const getCancellationPolicy = async (
    params: PaginatedRequestParams,
    callConfig?: Partial<ApiClientConfig>,
  ) => {
    const response = await apiClient<any>({
      ...callConfig,
      endpoint: `${baseURL}/admin/Packages/getCancellatoinPolicy`,
      params,
    });

    return response.data;
  };
  const uploadPackageImage = async (id: string, formData: FormData) => {
    // Replace baseUrl for Sports API if the request is for Sports APIs..
    const sportsService = replaceSubstring(
      CONFIG.LI3IB_API_BASE_URL,
      '/identity/',
      '/sports/',
    );

    const response = await axiosInstance.post(
      `${sportsService}/v2/admin/packages/${id}/cover_image`,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      },
    );

    return response.data;
  };
  const uploadPackageGalleryImage = async (id: string, formData: FormData) => {
    // Replace baseUrl for Sports API if the request is for Sports APIs..
    const sportsService = replaceSubstring(
      CONFIG.LI3IB_API_BASE_URL,
      '/identity/',
      '/sports/',
    );

    const response = await axiosInstance.post(
      `${sportsService}/v2/admin/packages/${id}/images`,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      },
    );

    return response.data;
  };
  const updateStatusPackage = async (
    payload: any,
    callConfig?: Partial<ApiClientConfig>,
  ) => {
    const { id, ...data } = payload;

    const response = await apiClient<any>({
      ...callConfig,
      endpoint: `${baseURL}/admin/packages/MakeActiveInactive`,
      method: 'POST',
      data,
    });

    return response.data;
  };
  // const updateBooking = async (
  //   payload: any,
  //   callConfig?: Partial<ApiClientConfig>,
  // ) => {
  //   const { id, ...data } = payload;
  //    console.log(id,"id");

  //   const response = await apiClient<any>({
  //     ...callConfig,
  //     endpoint: `${baseURL}/admin/packages/cancelbooking`,
  //     method: 'POST',
  //     data,
  //   });

  //   return response.data;
  // };
  const updateBooking = async (payload: any) => {
    const response = await apiClient({
      endpoint: `${baseURL}/admin/packages/cancelbooking`,
      method: 'POST',
      data: payload,
    });

    return response.data;
  };
  const deletePackageCoverImage = async (
    id: string,
    callConfig?: Partial<ApiClientConfig>,
  ) => {
    const response = await apiClient({
      ...callConfig,
      endpoint: `${baseURL}/admin/packages/${id}/cover_image`,
      method: 'DELETE',
    });

    return response.data;
  };
  const deletePackageGalleryImage = async (
    payload: any,
    callConfig?: Partial<ApiClientConfig>,
  ) => {
    const { id, image_url } = payload;

    const response = await apiClient({
      ...callConfig,
      endpoint: `${baseURL}/admin/packages/${id}/images/remove`,
      method: 'POST',
      data: { image_url },
    });

    return response.data;
  };

  return {
    getPackage,
    getPackageBooking,
    // getLabeldetails,
    getCancellationPolicy,
    createPackage,
    uploadPackageImage,
    getPackageId,
    deletePackageCoverImage,
    getBookingdetails,
    updateStatusPackage,
    uploadPackageGalleryImage,
    updatePackage,
    updateBooking,
    updateCancellation,
    createCancellation,
    deletePackageGalleryImage,
    getPackageBookingExport,
  };
};
