import { Button, DatePicker, Form, Grid, Input, Select } from 'antd';
import { ContentFilters } from 'components';
import { StandardItemsWrapper } from 'components/content-filters/content-filters.styles';
import dayjs from 'dayjs';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DATE_FORMATS } from 'utils/date';

import { bookingFilterFormField, BookingPackageFilterFormValues } from './type';
const { useBreakpoint } = Grid;

type UsersWithWalletFilterFormProps = {
  formId: string;
  handleFilter: (values: BookingPackageFilterFormValues) => void;
  initialValues: BookingPackageFilterFormValues;
  closeModal?: () => void;
};

export const PackageFilterBookingForm = ({
  formId,
  handleFilter,
  initialValues,
  closeModal,
}: UsersWithWalletFilterFormProps) => {
  const [validateTrigger, setValidateTrigger] = useState(['onSubmit']);
  const [form] = Form.useForm<BookingPackageFilterFormValues>();
  const { t } = useTranslation();
  const screens = useBreakpoint();
  const { RangePicker } = DatePicker;

  const onReset = () => {
    form.resetFields();
    handleFilter({ ...initialValues });
  };

  const handleOnFinish = (values: BookingPackageFilterFormValues) => {
    handleFilter(values);
    // setQueryFilters(values);
    closeModal?.();
  };

  return (
    <>
      <div className='pckg_wrapp'>
        <Form<BookingPackageFilterFormValues>
          id={formId}
          name={formId}
          form={form}
          layout='vertical'
          onFinish={handleOnFinish}
          initialValues={initialValues}
          onFinishFailed={() => {
            setValidateTrigger(['onChange']);
          }}
          validateTrigger={validateTrigger}
        >
          <ContentFilters.FormItemsWrapper>
            <StandardItemsWrapper>
              <Form.Item<BookingPackageFilterFormValues>
                label={'Customer'}
                name={bookingFilterFormField.customername}
              >
                <Input placeholder={'Customer'} />
              </Form.Item>
              <Form.Item<BookingPackageFilterFormValues>
                label={'Phone'}
                name={bookingFilterFormField.PhoneNumber}
              >
                <Input placeholder={'Phone'} />
              </Form.Item>
              <Form.Item<BookingPackageFilterFormValues>
                label={'Package Type'}
                name={bookingFilterFormField.type}
              >
                <Select
                  placeholder='All'
                  options={[
                    { value: 0, label: 'All' },
                    { value: '1', label: 'Monthly' },
                    { value: '2', label: 'Sessions' },
                  ]}
                />
              </Form.Item>

              <Form.Item<BookingPackageFilterFormValues>
                label={'Package'}
                name={bookingFilterFormField.packagename}
              >
                <Input placeholder={'Package'} />
              </Form.Item>
              <Form.Item<BookingPackageFilterFormValues>
                label={'Status'}
                name={bookingFilterFormField.statusid}
              >
                <Select
                  placeholder='All'
                  options={[
                    { value: 0, label: 'All' },
                    { value: `1`, label: 'Booked' },
                    { value: '2', label: 'Cancelled' },
                  ]}
                />
              </Form.Item>
              <Form.Item<BookingPackageFilterFormValues>
                label={t('social.formfield.date.label')}
                name={bookingFilterFormField.Date}
              >
                <RangePicker
                  allowEmpty={[true, true]}
                  format={DATE_FORMATS.date}
                  presets={[
                    {
                      label: t('form.formFields.date.presets.today'),
                      value: [dayjs(), dayjs()],
                    },
                    {
                      label: t('form.formFields.date.presets.fromTomorrow'),
                      value: [dayjs().add(1, 'day'), null],
                    },
                    {
                      label: t('form.formFields.date.presets.untilYesterday'),
                      value: [null, dayjs().add(-1, 'day')],
                    },
                  ]}
                />
              </Form.Item>
              <Form.Item<BookingPackageFilterFormValues>
                label={'Payment Type'}
                name={bookingFilterFormField.paymentmethod}
              >
                <Select
                  placeholder='All'
                  options={[
                    { value: null, label: 'All' },
                    { value: `cash`, label: 'Cash' },
                    { value: 'knet', label: 'Knet' },
                    { value: 'wallet', label: 'Wallet' },
                    { value: 'CREDITCARD', label: 'Credit card' },
                    { value: 'Apple Pay', label: 'Apple pay' },
                  ]}
                />
              </Form.Item>
            </StandardItemsWrapper>
          </ContentFilters.FormItemsWrapper>

          {screens.lg && (
            <ContentFilters.ButtonsWrapper>
              <Button type='primary' htmlType='submit'>
                {t('wallet.buttons.filter', { count: 1 })}
              </Button>
              <Button onClick={onReset}>{t('wallet.buttons.reset')}</Button>
            </ContentFilters.ButtonsWrapper>
          )}
        </Form>
      </div>
    </>
  );
};
