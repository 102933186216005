import { useMutation } from '@tanstack/react-query';
import { App } from 'antd';
import { handleError } from 'api';
import { PackageApiService } from 'features/package/api/package-api.service';
import { UpdateBadgeDTO } from 'features/user-level/api/questions-api.service';
import { queryClient } from 'providers';

import { packageQueryKeys } from './query.keys';
// ../api/package-api.service

const { updateCancellation } = PackageApiService();
export const useUpdateCancellation = () => {
  const { message } = App.useApp();

  return useMutation(
    (payload: UpdateBadgeDTO) => {
      return updateCancellation(payload as any);
    },
    {
      onError: (error) => {
        const uiMessage = handleError(error);

        message.error(uiMessage);
      },
      onSuccess: (data, variables) => {
        Promise.all([
          queryClient.invalidateQueries({
            queryKey: packageQueryKeys.lists(),
          }),
          queryClient.invalidateQueries({
            queryKey: packageQueryKeys.detail(variables.id as any),
          }),
        ]);
        message.success('Udated Successfully');
      },
    },
  );
};
