import { Form, Spin } from 'antd';
import { Loader, Modal, Result404 } from 'components';
import { UpdateQuestionDTO } from 'features/user-level/api/questions-api.service';
import {
  EndQuestionrFormValues,
  QuestionFormFields,
} from 'features/user-level/components/interface/type';
import { QuestionInfoFormItems } from 'features/user-level/components/modals/question-info-form.item';
import { useGetUserQuestion } from 'features/user-level/use-cases/get-question';
import { useUpdateQuestion } from 'features/user-level/use-cases/update-question';
import { StyledUserModalWrapper } from 'features/users/components/modals/user-modal.styles';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

export const EditUserQuestionModal = ({
  onClose,
  onSuccess,
  id,
}: {
  onClose: () => void;
  onSuccess: () => void;
  id: string;
}) => {
  const [form] = Form.useForm();
  const [validateTrigger, setValidateTrigger] = useState(['onSubmit']);
  const formName = 'edit-user-form';
  const { t } = useTranslation();
  const userQueryResult = useGetUserQuestion(`question_id=${id}`, {
    cacheTime: 0,
  });
  const {
    isLoading: isLoadingGetQuestion,
    isFetching: isFetchingGetQuestion,
    data: questionData,
    isError: isErrorQuestion,
  } = userQueryResult;
  const { mutate: mutateUpdateQuestion, isLoading: isLoadingUpdateQuestion } =
    useUpdateQuestion();
  const isLoading = {
    loading:
      isLoadingGetQuestion || isFetchingGetQuestion || isLoadingUpdateQuestion,
    isLoadingGetQuestion,
    isLoadingUpdateQuestion,
  };
  const handleUpdate = (formData: EndQuestionrFormValues) => {
    const payload: UpdateQuestionDTO = {
      question_id: id,
      question: formData.question,
      OptionList: formData.OptionList,
      question_arabic: formData.question_arabic,
    };
    mutateUpdateQuestion(payload, { onSuccess });
  };
  const getInitialValues = () => ({
    [QuestionFormFields.question]: questionData?.items[0].question || '',
    [QuestionFormFields.question_arabic]:
      questionData?.items[0].question_arabic || '',
    [QuestionFormFields.OptionList]: questionData?.items[0].optionList.map(
      (res: any) => ({
        option: res.option,
        option_arabic: res.option_arabic,
        weightage: res.weightage,
      }),
    ) || [{ option: null, weightage: null }],
  });

  const initialValues = getInitialValues();

  return (
    <StyledUserModalWrapper>
      <Modal
        open
        className='right-modal '
        centered
        title={t('userLevel.UpdateQuestion')}
        onCancel={() => {
          document.body.style.overflow = 'auto';
          onClose(); // Call onClose function
        }}
        getContainer={false}
        onOk={form.submit}
        okText={t('userLevel.buttons.questionUpdate')}
        cancelButtonProps={{ style: { display: 'none' } }}
        okButtonProps={{
          form: formName,
        }}
      >
        {isLoadingGetQuestion ? (
          <Loader />
        ) : isErrorQuestion ? (
          <Result404 />
        ) : (
          <Spin spinning={isLoading.loading}>
            <Form
              id={formName}
              name={formName}
              form={form}
              layout='vertical'
              initialValues={initialValues}
              requiredMark={'optional'}
              onFinishFailed={() => {
                setValidateTrigger(['onChange']);
              }}
              validateTrigger={validateTrigger}
              disabled={isLoading.loading}
              onFinish={handleUpdate}
            >
              <QuestionInfoFormItems form={form} />
            </Form>
          </Spin>
        )}
      </Modal>
    </StyledUserModalWrapper>
  );
};
