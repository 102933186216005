import 'react-quill/dist/quill.snow.css';

import { Form, FormInstance, Input } from 'antd';
import {
  BadgeFormDataFields,
  BadgeFormFields,
} from 'features/user-level/components/interface/type';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import ReactQuill from 'react-quill';

import { FormSectionsSeparator } from './form-sections-separator';

export const BadgeInfoFormItems = ({
  form,
}: {
  form: FormInstance<BadgeFormDataFields>;
}) => {
  const { t } = useTranslation();
  useEffect(() => {
    form.getFieldValue('question');
  });

  return (
    <>
      <FormSectionsSeparator title={t('userLevel.Bedage.UpdateBadge')}>
        <Form.Item<BadgeFormDataFields>
          name={BadgeFormFields.title}
          label='Title'
          rules={[{ required: true, message: 'Please Enter Question' }]}
        >
          <Input placeholder='Enter your Question' />
        </Form.Item>
        <Form.Item<BadgeFormDataFields>
          name={BadgeFormFields.description}
          label='Description'
          rules={[{ required: true, message: 'Please Enter Description' }]}
        >
          <ReactQuill theme='snow' />
        </Form.Item>
      </FormSectionsSeparator>
    </>
  );
};
