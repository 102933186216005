import { PaginatedRequestParams } from 'api';

export enum OffensiveFilterFormField {
  Word = 'word',
  WordEnglish = 'word_en',
  WordArabic = 'word_ar',
}

export type OffensiveFilterFormValues = {
  [OffensiveFilterFormField.Word]: string | undefined;
  [OffensiveFilterFormField.WordArabic]: string | undefined;
  [OffensiveFilterFormField.WordEnglish]: string | undefined;
};
export type OffensiveFilterFormPaginated = OffensiveFilterFormValues &
  PaginatedRequestParams;
