import {
  QueryFunction,
  QueryKey,
  useQuery,
  UseQueryOptions,
} from '@tanstack/react-query';
import { App } from 'antd';
import { handleError } from 'api';
import { SocialApiService } from 'features/social-match/api/social-api.service';
import { userQueryKeys } from 'features/users/use-cases/query-keys';
import { User } from 'types';
const { getSocialdetails } = SocialApiService();
const getUserQuery = (
  id: string,
  options?: UseQueryOptions<User>,
): {
  queryKey: QueryKey;
  queryFn: QueryFunction<User>;
  options?: UseQueryOptions<User>;
} => ({
  queryKey: userQueryKeys.detail(id),
  queryFn: ({ signal }) => getSocialdetails(id, { config: { signal } }),
  ...options,
});

export const useGetSocialdetails = (
  id: string,
  options?: UseQueryOptions<any>,
) => {
  const { message } = App.useApp();

  return useQuery<any, Error>(
    getUserQuery(id, {
      onError: (error: unknown) => {
        const uiMessage = handleError(error);

        message.error(uiMessage);
      },
      ...options,
    }),
  );
};
