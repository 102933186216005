import {
  QueryFunction,
  QueryKey,
  useQuery,
  UseQueryOptions,
} from '@tanstack/react-query';
import { App } from 'antd';
import { handleError } from 'api';
import { CompaniesParams } from 'features/companies';
import { companiesQueryKeys } from 'features/companies/use-cases/query-keys';
import { SocialApiService } from 'features/social-match/api/social-api.service';
import { QuestionResponse } from 'features/user-level/api/questions-api.service';
const { getSocial } = SocialApiService();

const getCompaniesQuery = (
  params: CompaniesParams,
  options?: UseQueryOptions<QuestionResponse>,
): {
  queryKey: QueryKey;
  queryFn: QueryFunction<QuestionResponse>;
  options?: UseQueryOptions<QuestionResponse>;
} => ({
  queryKey: companiesQueryKeys.list(params),
  queryFn: ({ signal }) => getSocial(params, { config: { signal } }),
  ...options,
});

export const useGetSocial = (params: any, options?: UseQueryOptions<any>) => {
  const { message } = App.useApp();

  return useQuery<QuestionResponse, Error>(
    getCompaniesQuery(params, {
      onError: (error: unknown) => {
        const uiMessage = handleError(error);

        message.error(uiMessage);
      },
      ...options,
    }),
  );
};
