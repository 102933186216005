import { Layout as AntLayout } from 'antd';
import IconSiderTrigger from 'assets/icons/icon-sider-trigger.svg';
import { Button } from 'components';
import { usePermissions } from 'features/users';
import { useWindowSize } from 'hooks';
import { useSideBar } from 'providers/side-bar-provider';
import { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import { getMenuItems } from './menu-items';
import { SideBarWrapper, StyledMenu } from './side-bar.styles';
import { getActiveKeys } from './utils';

export const SideBar = () => {
  const { collapsed, toggleCollapsed } = useSideBar();
  const { isMobile } = useWindowSize();
  const { t } = useTranslation();
  const location = useLocation();
  const permissions = usePermissions();
  const { Sider } = AntLayout;

  useEffect(() => {
    const closeMobileMenu = () => {
      if (isMobile && !collapsed) {
        toggleCollapsed();
      }
    };

    closeMobileMenu();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  const activeKeys = useMemo(() => {
    return getActiveKeys(location.pathname);
  }, [location.pathname]);

  if (!permissions) return null;

  const items = getMenuItems(permissions);

  return (
    <SideBarWrapper className='sidebarl'>
      <Sider
        id='sideBar'
        data-testid='side-bar'
        breakpoint='lg'
        theme='light'
        collapsible
        collapsed={collapsed}
        collapsedWidth={isMobile ? '0' : '48'}
        width={isMobile ? 260 : 200}
        onCollapse={toggleCollapsed}
        trigger={
          <Button
            type='text'
            aria-controls='sideBar'
            aria-expanded={!collapsed}
            className='side-bar-trigger-button'
          >
            <img
              className={collapsed ? 'side-bar-trigger-collapsed' : undefined}
              src={IconSiderTrigger}
              alt={collapsed ? t('sideBar.expand') : t('sideBar.collapse')}
            />
          </Button>
        }
        className='side-bar-sider'
      >
        <StyledMenu
          selectedKeys={activeKeys}
          defaultOpenKeys={!collapsed ? activeKeys : undefined}
          mode='inline'
          items={items}
          className='side-bar-menu'
        />
      </Sider>
    </SideBarWrapper>
  );
};
