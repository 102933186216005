import { PlusOutlined } from '@ant-design/icons';
import { Grid } from 'antd';
import { Button, ContentTopBar } from 'components';
import { AddBadgeModal } from 'features/user-level/components/modals/add-badge-modal';
import { useGetBadge } from 'features/user-level/use-cases/get-badge';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CompanyType } from 'types';
const { useBreakpoint } = Grid;
export const BadgeTopBar = () => {
  useState<CompanyType>();
  const { t } = useTranslation();
  const [showAddUserModal, setShowAddUserModal] = useState(false);
  const screens = useBreakpoint();
  const { data } = useGetBadge('' as any);

  return (
    <>
      <ContentTopBar
        title={t('userLevel.headingbadge.badge')}
        renderButtons={
          <>
            {!data && (
              <Button
                icon={!screens.xs ? <PlusOutlined /> : undefined}
                onClick={() => setShowAddUserModal(true)}
              >
                {t('userLevel.buttons.addBedage')}
              </Button>
            )}
            {showAddUserModal && (
              <AddBadgeModal onClose={() => setShowAddUserModal(false)} />
            )}
          </>
        }
      />
    </>
  );
};
