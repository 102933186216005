import { WithdrawEditViewModal } from 'features/withdraw/components/modal/withdraw-detail';
import { useNavigate, useParams } from 'react-router-dom';
import { ROUTES } from 'routes/constants';
// ../components/modal/withdraw-detail
export const WithdrawView = () => {
  const navigate = useNavigate();
  const { id } = useParams();

  if (!id) return null;

  const closeModal = () => {
    navigate(ROUTES.WITHDRAW);
  };

  return (
    <WithdrawEditViewModal
      id={id}
      onClose={closeModal}
      onSuccess={closeModal}
    />
  );
};
