import { SocialViewModal } from 'features/social-match/components/modal/social-detail';
import { useNavigate, useParams } from 'react-router-dom';
import { ROUTES } from 'routes/constants';
export const SocialView = () => {
  const navigate = useNavigate();
  const { id } = useParams();

  if (!id) return null;

  const closeModal = () => {
    navigate(ROUTES.SOCIAL);
  };

  return (
    <SocialViewModal id={id} onClose={closeModal} onSuccess={closeModal} />
  );
};
