import { Button, Form, Grid, Input } from 'antd';
import { ContentFilters, Select } from 'components';
import { StandardItemsWrapper } from 'components/content-filters/content-filters.styles';
import { CompanySelect } from 'features/companies/components/company-select/company-select';
import { USER_ROLES } from 'features/users/permissions';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { RoleTranslationsMap } from 'utils/role';

import { UserFilterFormField, UserFilterFormValues } from './types';

const { useBreakpoint } = Grid;

type UsersFilterFormProps = {
  formId: string;
  handleFilter: (values: UserFilterFormValues) => void;
  initialValues: UserFilterFormValues;
  closeModal?: () => void;
};

export const UsersFilterForm = ({
  formId,
  handleFilter,
  initialValues,
  closeModal,
}: UsersFilterFormProps) => {
  const [validateTrigger, setValidateTrigger] = useState(['onSubmit']);
  const [form] = Form.useForm<UserFilterFormValues>();
  const { t } = useTranslation();
  const screens = useBreakpoint();

  const roleOptions = USER_ROLES.all.map((role) => ({
    value: role,
    label: RoleTranslationsMap[role],
  }));

  const onReset = () => {
    form.resetFields();
  };

  const onFinish = (formValues: UserFilterFormValues) => {
    handleFilter(formValues);
    closeModal?.();
  };

  return (
    <Form<UserFilterFormValues>
      id={formId}
      name={formId}
      className='filters-form'
      form={form}
      layout='vertical'
      onFinish={onFinish}
      initialValues={initialValues}
      onFinishFailed={() => {
        setValidateTrigger(['onChange']);
      }}
      validateTrigger={validateTrigger}
    >
      <ContentFilters.FormItemsWrapper>
        <StandardItemsWrapper>
          <Form.Item<UserFilterFormValues>
            label={t('adminUsers.formFields.userName.label')}
            name={UserFilterFormField.FullUserName}
          >
            <Input
              placeholder={t('adminUsers.formFields.userName.placeholder')}
            />
          </Form.Item>
          <Form.Item<UserFilterFormValues>
            label={t('adminUsers.formFields.phoneNumber.label')}
            name={UserFilterFormField.PhoneNumber}
          >
            <Input
              placeholder={t('adminUsers.formFields.phoneNumber.placeholder')}
            />
          </Form.Item>
          <Form.Item<UserFilterFormValues>
            label={t('adminUsers.formFields.email.label')}
            name={UserFilterFormField.Email}
          >
            <Input placeholder={t('adminUsers.formFields.email.placeholder')} />
          </Form.Item>
          <Form.Item<UserFilterFormValues>
            label={t('adminUsers.formFields.role.label')}
            name={UserFilterFormField.Role}
          >
            <Select
              options={roleOptions}
              placeholder={t('adminUsers.formFields.role.placeholder')}
              dropdownMatchSelectWidth={200}
              className='filter'
              allowClear
            />
          </Form.Item>
          <Form.Item<UserFilterFormValues>
            label={t('adminUsers.formFields.company.label')}
            name={UserFilterFormField.Company}
          >
            <CompanySelect
              placeholder={t('adminUsers.formFields.company.placeholder')}
              dropdownMatchSelectWidth={200}
              className='filter'
              allowClear
              predefinedOptions={[
                { value: 'li3ib', label: t('adminUsers.li3ibCompanies') },
              ]}
            />
          </Form.Item>
        </StandardItemsWrapper>
      </ContentFilters.FormItemsWrapper>

      {screens.lg && (
        <ContentFilters.ButtonsWrapper>
          <Button type='primary' htmlType='submit'>
            {t('common.buttons.filter', { count: 1 })}
          </Button>
          <Button onClick={onReset}>{t('common.buttons.reset')}</Button>
        </ContentFilters.ButtonsWrapper>
      )}
    </Form>
  );
};
