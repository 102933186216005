// upgrade code
export enum TermFormFields {
  id = 'id',
  content_english = 'content_english',
  content_arabic = 'content_arabic',
}

export type TermFormDataFields = {
  [TermFormFields.id]: string;
  [TermFormFields.content_english]: string;
  [TermFormFields.content_arabic]: string;
};
