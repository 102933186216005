import {
  QueryFunction,
  QueryKey,
  useQuery,
  UseQueryOptions,
} from '@tanstack/react-query';
import { App } from 'antd';
import { handleError } from 'api';
import {
  UsersWithWalletParams,
  UsersWithWalletResponse,
} from 'features/wallet';
import { WithdrawApiService } from 'features/withdraw/api/withdraw-api.service';

import { withdrawQueryKeys } from './query-keys';

const { getUsersWithWithdraw } = WithdrawApiService();

const getUsersWithWalletQuery = (
  params: UsersWithWalletParams,
  options?: UseQueryOptions<UsersWithWalletResponse>,
): {
  queryKey: QueryKey;
  queryFn: QueryFunction<UsersWithWalletResponse>;
  options?: UseQueryOptions<UsersWithWalletResponse>;
} => ({
  queryKey: withdrawQueryKeys.list(params),
  queryFn: ({ signal }) => getUsersWithWithdraw(params, { config: { signal } }),
  ...options,
});

export const useGetUsersWithWithdraw = (
  params: UsersWithWalletParams,
  options?: UseQueryOptions<UsersWithWalletResponse>,
) => {
  const { message } = App.useApp();

  return useQuery<UsersWithWalletResponse, Error>(
    getUsersWithWalletQuery(params, {
      onError: (error: unknown) => {
        const uiMessage = handleError(error);

        message.error(uiMessage);
      },
      ...options,
    }),
  );
};
