import { Form, FormInstance, Input } from 'antd';
import { OffensiveFilterFormField } from 'features/offensive/components/offensive-filter-form/type';
import { FormSectionsSeparator } from 'features/users/components/modals/form-sections-separator';
import { UserFormValues } from 'features/users/components/users-filter-form';
import { useEffect } from 'react';
export const OffensiveInfoFormItems = ({
  form,
}: {
  form: FormInstance<UserFormValues>;
}) => {
  useEffect(() => {
    form.getFieldValue('label');
  });
  
return (
    <FormSectionsSeparator title=''>
      <Form.Item<OffensiveFilterFormField>
        label={'Words in English'}
        name={OffensiveFilterFormField.WordEnglish}
        rules={[{ required: true, message: 'Please enter words in English' }]}
      >
        <Input placeholder='Please enter words in English' />
      </Form.Item>
      <Form.Item<OffensiveFilterFormField>
        label={'Words in Arabic'}
        name={OffensiveFilterFormField.WordArabic}
        rules={[{ required: true, message: 'Please enter words in arabic' }]}
      >
        <Input placeholder='Please enter words in arabic' dir='rtl' />
      </Form.Item>
    </FormSectionsSeparator>
  );
};
