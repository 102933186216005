import { PlusOutlined } from '@ant-design/icons';
import { Grid } from 'antd';
import { Button, ContentTopBar } from 'components';
import { GroupsTermViewTable } from 'features/community/components/groups-table/group-term-view';
import { AddTermModal } from 'features/community/components/modal/add-term-modal';
import { useGetGroupTermList } from 'features/community/use-cases/get-groupterm';
import { useState } from 'react';
import { Outlet } from 'react-router-dom';
// ../components/modal/add-term-modal
const { useBreakpoint } = Grid;
export const GroupTermView = () => {
  const { data, isFetching } = useGetGroupTermList('' as any);
  const [showAddUserModal, setShowAddUserModal] = useState(false);
  const screens = useBreakpoint();
  
return (
    <>
      <ContentTopBar
        title={'Term & Conditions'}
        renderButtons={
          <>
            {!data?.id && (
              <Button
                icon={!screens.xs ? <PlusOutlined /> : undefined}
                onClick={() => setShowAddUserModal(true)}
              >
                Add Group T&C
              </Button>
            )}
            {showAddUserModal && (
              <AddTermModal onClose={() => setShowAddUserModal(false)} />
            )}
          </>
        }
      />
      <GroupsTermViewTable isLoading={isFetching} data={data} />
      <Outlet />
    </>
  );
};
