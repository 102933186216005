import {
  apiClient,
  ApiClientConfig,
  PaginatedRequestParams,
  PaginatedResponse,
} from 'api';
import { CompanyType } from 'types';
import { Question } from 'types/question';
export type QuestionResponse = PaginatedResponse<Question>;
export type CompaniesParams = {
  is_active?: boolean;
  company_type?: CompanyType;
  phone_number?: string;
  company_name?: string;
} & PaginatedRequestParams;

export type CreateQuestionDTO = {
  question?: string;
  OptionList?: any;
};
export type CreateBadgeDTO = {
  id?: string;
  title?: string;
  description?: string;
};
export type UpdateQuestionDTO = {
  question_id?: string;
  question?: string;
  OptionList?: string;
  question_arabic?: string;
};
export type UpdateBadgeDTO = {
  id?: string;
  title?: string;
  description?: string;
  title_arabic?: string;
  description_arabic?: any;
};
export type updateStatus = {
  id?: string | undefined;
};

export type UpdateCompanyDTO = {
  id: string;
} & CreateQuestionDTO;
// const baseURL='https://itplapiv2.li3ib.com/sports/api/v2'
const baseURL = process.env.REACT_APP_BASE_V2_URL;
export const QuestionApiService = () => {
  const getQuestions = async (
    params: CompaniesParams,
    callConfig?: Partial<ApiClientConfig>,
  ) => {
    const response = await apiClient<QuestionResponse>({
      ...callConfig,
      endpoint: `${baseURL}/admin/SocialMatchQuestions`,
      params,
    });

    return response.data;
  };
  const getBadge = async (
    params: CompaniesParams,
    callConfig?: Partial<ApiClientConfig>,
  ) => {
    const response = await apiClient<any>({
      ...callConfig,
      endpoint: `${baseURL}/admin/Badge`,
      params,
    });

    return response.data;
  };
  const createQuestion = async (payload: CreateQuestionDTO) => {
    const response = await apiClient({
      endpoint: `${baseURL}/admin/SocialMatchQuestions/CreateQuestion`,
      method: 'POST',
      data: payload,
    });

    return response.data;
  };
  const getUser = async (id: string, callConfig?: Partial<ApiClientConfig>) => {
    const response = await apiClient<any>({
      ...callConfig,
      endpoint: `${baseURL}/admin/SocialMatchQuestions?${id}`,
    });

    return response.data;
  };
  const updateQuestion = async (
    payload: UpdateQuestionDTO,
    callConfig?: Partial<ApiClientConfig>,
  ) => {
    const { question_id, ...data } = payload;

    const response = await apiClient<any>({
      ...callConfig,
      endpoint: `${baseURL}/admin/SocialMatchQuestions/CreateQuestion`,
      method: 'POST',
      data: {
        question_id,
        ...data, // Include other data from the payload
      },
    });

    return response.data;
  };
  const updateBadge = async (
    payload: UpdateBadgeDTO,
    callConfig?: Partial<ApiClientConfig>,
  ) => {
    const { id, ...data } = payload;

    const response = await apiClient<any>({
      ...callConfig,
      endpoint: `${baseURL}/admin/Badge`,
      method: 'POST',
      data: {
        id,
        ...data, // Include other data from the payload
      },
    });

    return response.data;
  };
  const updateStatusQuestion = async (
    payload: updateStatus,
    callConfig?: Partial<ApiClientConfig>,
  ) => {
    const { id, ...data } = payload;

    const response = await apiClient<any>({
      ...callConfig,
      endpoint: `${baseURL}/admin/SocialMatchQuestions/UpdateStatus`,
      method: 'POST',
      data,
    });

    return response.data;
  };

  return {
    getQuestions,
    createQuestion,
    getUser,
    updateBadge,
    getBadge,
    updateStatusQuestion,
    updateQuestion,
  };
};
