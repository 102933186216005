import { DownloadOutlined, UploadOutlined } from '@ant-design/icons';
import { Button, Form, Grid, message } from 'antd';
import { Modal } from 'components';
import { useCreateOffensive } from 'features/offensive/use-cases/create-offensive';
import { FormSectionsSeparator } from 'features/users/components/modals/form-sections-separator';
import { StyledUserModalWrapper } from 'features/users/components/modals/user-modal.styles';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { downloadExcel } from 'utils/offensive-download';
import * as XLSX from 'xlsx';
const { useBreakpoint } = Grid;
export const AddOffensiveImportModal = ({
  onClose,
  refetch,
}: {
  onClose: () => void;
  refetch: () => void;
}) => {
  const formName = 'add-user-form';
  const screens = useBreakpoint();
  const [validateTrigger] = useState(['onSubmit']);
  const [form] = Form.useForm();
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [fileName, setFileName] = useState('');
  const [parsedData, setParsedData] = useState<any[]>([]);
  const { mutate } = useCreateOffensive();
  const handleSubmit = () => {
    if (!fileName) {
      message.warning('Please select a file.');

      return;
    }
    setIsLoading(true);
    const payload: any = { word_list: parsedData };
    mutate(payload, {
      onSuccess: () => {
        setIsLoading(false);
        onClose();
        refetch();
      },
      onError: () => {
        setIsLoading(false);
      },
    });
  };
  const handleFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const fileInput = event.target;
    const file = fileInput.files?.[0];

    if (
      !file ||
      ![
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        'application/vnd.ms-excel',
        'text/csv', // Added support for CSV files
      ].includes(file.type)
    ) {
      message.warning('Please upload a valid Excel or CSV file.');
      fileInput.value = ''; // Reset the input value to ensure the same file triggers the event again

      return;
    }

    setFileName(file.name);
    const reader = new FileReader();
    reader.onload = (e: ProgressEvent<FileReader>) => {
      const data = new Uint8Array(e.target?.result as ArrayBuffer);
      const workbook = XLSX.read(data, { type: 'array' });
      const sheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[sheetName];
      const json = XLSX.utils.sheet_to_json(worksheet, { defval: '' });
      if (
        json.length === 0 ||
        json.every((row: any) => Object.values(row).every((val) => val === ''))
      ) {
        message.warning(
          'The uploaded file is blank or contains only empty rows. Please upload a file with data.',
        );
        setParsedData([]); // Reset parsed data
        setFileName(''); // Reset file name
        fileInput.value = ''; // Reset the input value to ensure the same file triggers the event again

        return;
      }

      const filteredData = json
        .map((row: any) => ({
          word_en:
            row['word_english'] !== undefined && row['word_english'] !== null
              ? String(row['word_english']).trim()
              : null,
          word_ar:
            row['word_arabic'] !== undefined && row['word_arabic'] !== null
              ? String(row['word_arabic']).trim()
              : null,
        }))
        .map((item: any) => ({
          ...item,
          word_ar: item.word_ar || item.word_en, // Fallback to word_en if word_ar is not provided
        }))
        .filter((item: any) => item.word_en !== null); // Keep rows where word_en is not null

      const isValid = filteredData.length > 0;

      if (!isValid) {
        message.warning(
          'Please upload a file with valid data. Ensure that the file contains "word_english" and "word_arabic" columns with valid entries.',
        );
        setParsedData([]); // Reset parsed data
        setFileName(''); // Reset file name
        fileInput.value = ''; // Reset the input value to ensure the same file triggers the event again

        return;
      }

      setParsedData(filteredData);
    };
    reader.readAsArrayBuffer(file);
  };

  return (
    <StyledUserModalWrapper>
      <Modal
        open
        centered
        className='add_wrds_mdl'
        title={'Add Words'}
        onCancel={onClose}
        getContainer={false}
        onOk={handleSubmit}
        okText={t('social.formfield.addnewlabel')}
        cancelButtonProps={{ style: { display: 'none' } }}
        width={screens.lg ? '520px' : '100%'}
        okButtonProps={{
          style: { display: 'none' }, // Hide the OK button
        }}
      >
        <Form
          id={formName}
          name={formName}
          form={form}
          layout='vertical'
          validateTrigger={validateTrigger}
          disabled={isLoading}
        >
          <div className='hd_btn'>
            <Button
              className='sam_btn'
              icon={<DownloadOutlined />}
              onClick={downloadExcel}
            >
              Sample Format
            </Button>
          </div>
          <div className='add_wrd_bx'>
            <FormSectionsSeparator title=''>
              <div className='btn_wrap'>
                <Button
                  onClick={() => document.getElementById('file-input')?.click()}
                  icon={<UploadOutlined />}
                >
                  Select Files
                </Button>
                <input
                  type='file'
                  id='file-input'
                  accept='.xlsx, .xls'
                  style={{ display: 'none' }}
                  onChange={handleFileUpload}
                />
                {fileName ? (
                  <p>Selected file: {fileName}</p>
                ) : (
                  <p> Select only xlsx,xls,.csv</p>
                )}
              </div>
            </FormSectionsSeparator>
          </div>
          <div className='foot_btn'>
            <Button
              onClick={handleSubmit}
              loading={isLoading}
              className='add_btn'
            >
              Submit
            </Button>
            <Button danger className='btn_danger' onClick={onClose}>
              Cancel
            </Button>
          </div>
        </Form>
      </Modal>
    </StyledUserModalWrapper>
  );
};
