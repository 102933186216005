import { useMutation, useQueryClient } from '@tanstack/react-query';
import { App } from 'antd';
import { handleError } from 'api';
import {
  CreateApprovedDTO,
  WithdrawApiService,
} from 'features/withdraw/api/withdraw-api.service';

import { withdrawQueryKeys } from './query-keys';
const { createApproved } = WithdrawApiService();
// ../api/withdraw-api.service
export const useCreateApproved = () => {
  const { message } = App.useApp();
  const queryClient = useQueryClient();

  return useMutation(
    (payload: CreateApprovedDTO) => {
      return createApproved(payload);
    },
    {
      onError: (error) => {
        const uiMessage = handleError(error);

        message.error(uiMessage);
      },
      onSuccess: () => {
        // TODO: invalidate week view endpoint as well, when ready
        queryClient.invalidateQueries({
          queryKey: withdrawQueryKeys.all,
        });

        message.success('Apporved successfully');
      },
    },
  );
};
