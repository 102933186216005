import { Button, DatePicker, Form, Grid, Input, message, Select } from 'antd';
import { ContentFilters } from 'components';
import { StandardItemsWrapper } from 'components/content-filters/content-filters.styles';
import dayjs from 'dayjs';
import { SocialApiService } from 'features/social-match/api/social-api.service';
import { UserFilterFormValues } from 'features/users/components/users-filter-form';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DATE_FORMATS } from 'utils/date';

import { SocialFilterFormField, SocialFilterFormValues } from './type';
const { RangePicker } = DatePicker;

const { useBreakpoint } = Grid;
type UsersFilterFormProps = {
  formId: string;
  handleFilter: (values: UserFilterFormValues) => void;
  initialValues: UserFilterFormValues;
  closeModal?: () => void;
};

export const SocailFilterForm = ({
  formId,
  handleFilter,
  initialValues,
  closeModal,
}: UsersFilterFormProps) => {
  const [validateTrigger, setValidateTrigger] = useState(['onSubmit']);
  const [form] = Form.useForm<SocialFilterFormValues>();
  const { t } = useTranslation();
  const screens = useBreakpoint();
  const [StatusValue, setStatusValue] = useState<any>('');
  const onReset = () => {
    form.resetFields();
  };

  const onFinish = (formValues: SocialFilterFormValues) => {
    handleFilter(formValues as any);
    closeModal?.();
  };
  const initialMatch = async () => {
    try {
      const apiRes = await SocialApiService().getSocialMatchStatus('');
      setStatusValue(apiRes);
    } catch (error) {
      message.error(error as any);
    }
  };
  const matchValue = [
    {
      label: 1,
      name: 'Private',
    },
    {
      label: 2,
      name: 'Public',
    },
  ];
  const match_type = matchValue.map((res) => ({
    value: res.label,
    label: res.name,
  }));
  useEffect(() => {
    initialMatch();
  }, []);

  return (
    <Form<SocialFilterFormValues>
      id={formId}
      name={formId}
      className='filters-form'
      form={form}
      layout='vertical'
      onFinish={onFinish}
      initialValues={initialValues}
      onFinishFailed={() => {
        setValidateTrigger(['onChange']);
      }}
      validateTrigger={validateTrigger}
    >
      <ContentFilters.FormItemsWrapper>
        <StandardItemsWrapper>
          <Form.Item<SocialFilterFormValues>
            label={t('social.formfield.fieldName.label')}
            name={SocialFilterFormField.FIELDNAME}
          >
            <Input placeholder={t('social.formfield.fieldName.placeholder')} />
          </Form.Item>
          {/* <Form.Item<SocialFilterFormValues>
            label={t('social.formfield.sportName.sportName')}
            name={SocialFilterFormField.SPORTNAME}
          >
            <Input placeholder={t('social.formfield.sportName.placeholder')} />
          </Form.Item> */}
          <Form.Item<SocialFilterFormValues>
            label={t('social.formfield.date.label')}
            name={SocialFilterFormField.Date}
          >
            <RangePicker
              allowEmpty={[true, true]}
              format={DATE_FORMATS.date}
              presets={[
                {
                  label: t('form.formFields.date.presets.today'),
                  value: [dayjs(), dayjs()],
                },
                {
                  label: t('form.formFields.date.presets.fromTomorrow'),
                  value: [dayjs().add(1, 'day'), null],
                },
                {
                  label: t('form.formFields.date.presets.untilYesterday'),
                  value: [null, dayjs().add(-1, 'day')],
                },
              ]}
            />
          </Form.Item>
          <Form.Item<SocialFilterFormValues>
            label={t('social.formfield.matchstatus.label')}
            name={SocialFilterFormField.MATCH}
            style={{ minWidth: '110px' }}
          >
            <Select
              options={StatusValue?.items?.map((res: any) => ({
                label: res.status_name,
                value: res.status_id,
              }))}
              placeholder={t('social.formfield.matchstatus.placeholder')}
              className='filter'
              allowClear
            />
          </Form.Item>
          <Form.Item<SocialFilterFormValues>
            label={t('social.formfield.matchtype.label')}
            name={SocialFilterFormField.MATCHTYPE}
          >
            <Select
              options={match_type}
              placeholder={t('social.formfield.matchtype.placeholder')}
              dropdownMatchSelectWidth={200}
              className='filter'
              allowClear
            />
          </Form.Item>
        </StandardItemsWrapper>
      </ContentFilters.FormItemsWrapper>
      {screens.lg && (
        <ContentFilters.ButtonsWrapper>
          <Button type='primary' htmlType='submit'>
            {t('common.buttons.filter', { count: 1 })}
          </Button>
          <Button onClick={onReset}>{t('common.buttons.reset')}</Button>
        </ContentFilters.ButtonsWrapper>
      )}
    </Form>
  );
};
