import { ContentTopBar } from 'components';
import { ReportPostViewTable } from 'features/community/components/groups-table/report-post';
import { useGetReportList } from 'features/community/use-cases/get-report';
import {
  PackageFilterFormPaginated,
  PackageFilterFormValues,
} from 'features/package/components/package-filter-form/type';
import { usePaginationParams } from 'hooks';
import { Outlet } from 'react-router-dom';
// ../components/groups-table/report-post
const initialFilterValues: PackageFilterFormValues = {
  type_id: undefined,
  name: undefined,
  company_id: undefined,
};

export const ReportPostView = () => {
  const { params, updateParams } =
    usePaginationParams<PackageFilterFormPaginated>({
      page: 1,
      size: 10,
      ...initialFilterValues,
    });
  const { data, isFetching, refetch } = useGetReportList(params, {
    retry: false,
  });

  return (
    <>
      <ContentTopBar title={'Post'} />

      {/* <ContentFilters formId={formId}>
        <GroupsFilterForm
          formId={formId}
          handleFilter={handleFiltersChange}
          initialValues={initialFilterValues}
        />
      </ContentFilters> */}
      <ReportPostViewTable
        isLoading={isFetching}
        data={data}
        refetch={refetch}
        tableParams={params}
        updateParams={updateParams}
      />

      <Outlet />
    </>
  );
};
