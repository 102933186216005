import {
  QueryFunction,
  QueryKey,
  useQuery,
  UseQueryOptions,
} from '@tanstack/react-query';
import { App } from 'antd';
import { handleError } from 'api';
import { PackageApiService } from 'features/package/api/package-api.service';
import { Field } from 'types';

import { packageQueryKeys } from './query.keys';
const { getPackageId } = PackageApiService();
const getFieldQuery = (
  id: string,
  options?: UseQueryOptions<Field>,
): {
  queryKey: QueryKey;
  queryFn: QueryFunction<Field>;
  options?: UseQueryOptions<Field>;
} => ({
  queryKey: packageQueryKeys.detail(id),
  queryFn: ({ signal }) => getPackageId(id, { config: { signal } }),
  ...options,
});

export const useGetPackage = (id: string, options?: UseQueryOptions<Field>) => {
  const { message } = App.useApp();

  return useQuery<Field, Error>(
    getFieldQuery(id, {
      onError: (error: unknown) => {
        const uiMessage = handleError(error);

        message.error(uiMessage);
      },
      ...options,
    }),
  );
};
