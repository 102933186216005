import { PlusOutlined } from '@ant-design/icons';
import { Grid } from 'antd';
import { Button, ContentFilters, ContentTopBar } from 'components';
import {
  MainFilters,
  MainFiltersParams,
} from 'components/main-filters/main-filters';
import {
  CompaniesFilterForm,
  CompaniesFilterFormPaginated,
  CompaniesFilterFormValues,
  CompaniesTable,
  useGetCompanies,
} from 'features/companies';
import { usePaginationParams } from 'hooks';
import { t } from 'i18next';
import { Outlet } from 'react-router-dom';
import { ROUTES } from 'routes/constants';

const { useBreakpoint } = Grid;

const initialFilterValues: CompaniesFilterFormValues = {
  company_name: undefined,
  phone_number: undefined,
};

export const CompaniesView = () => {
  const screens = useBreakpoint();
  const { params, updateParams, resetPage } =
    usePaginationParams<CompaniesFilterFormPaginated>({
      page: 1,
      size: 10,
      order_by: 'name',
      ...initialFilterValues,
    });

  const { data, isFetching } = useGetCompanies(params, {
    retry: false,
  });

  const handleFiltersChange = (values: CompaniesFilterFormValues) => {
    updateParams(values);
    resetPage();
  };

  const handleMainFiltersChange = (mainParams: MainFiltersParams) => {
    updateParams(mainParams);
    resetPage();
  };

  const formId = 'companies-filters-form';

  return (
    <>
      <ContentTopBar
        title={t('companies.heading')}
        renderHeadingContent={
          <MainFilters
            filterTypes={['companyType']}
            onChange={handleMainFiltersChange}
          />
        }
        renderButtons={
          <Button
            icon={!screens.xs ? <PlusOutlined /> : undefined}
            href={ROUTES.COMPANIES_CREATE}
          >
            {t('companies.buttons.addCompany')}
          </Button>
        }
      />

      <ContentFilters formId={formId}>
        <CompaniesFilterForm
          formId={formId}
          handleFilter={handleFiltersChange}
          initialValues={initialFilterValues}
        />
      </ContentFilters>

      <CompaniesTable
        isLoading={isFetching}
        data={data}
        tableParams={params}
        updateParams={updateParams}
      />
      <Outlet />
    </>
  );
};
