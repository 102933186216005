import { SearchOutlined } from '@ant-design/icons';
import { Button, Input, Space } from 'antd';
import { StyledTableWrapper } from 'components/table/table.styled';
export const DashSideView = () => {
  // const permissions = usePermissions();
  // if (!permissions) return null;
  const role = [
    'Li3ibAccountant',
    'ClientAdmin',
    'SuperAdmin',
    'Accountant',
    'Coordinator',
    'User',
    'CallCenterOperative',
    'Li3ibAdmin',
  ];

  return (
    <>
      <StyledTableWrapper className='side_bx'>
        <div className='search_bx'>
          <Space.Compact size='large'>
            <Input addonBefore={<SearchOutlined />} placeholder='Search Role' />
          </Space.Compact>
        </div>
        <div className='rle_mnu_itm'>
          <Button className='add_lnk'>+ Add New Role</Button>
          <ul className='rol_menu'>
            {role.map((res: string, index: number) => (
              <li key={index}>
                <Button className='rol_lnk'>{res}</Button>
              </li>
            ))}
          </ul>
        </div>
      </StyledTableWrapper>
    </>
  );
};
