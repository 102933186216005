import { EditBadgeModal } from 'features/user-level/components/modals/edit-badge-modal';
import { useNavigate, useParams } from 'react-router-dom';
import { ROUTES } from 'routes/constants';
export const BadgeUpdateView = () => {
  const navigate = useNavigate();
  const { id } = useParams();

  if (!id) return null;

  const closeModal = () => {
    navigate(ROUTES.BADGE);
  };

  return <EditBadgeModal id={id} onClose={closeModal} onSuccess={closeModal} />;
};
