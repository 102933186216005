import {
  useQuery,
  UseQueryOptions,
  UseQueryResult,
} from '@tanstack/react-query';
import { App } from 'antd';
import { handleError } from 'api';
import {
  GetDayViewStatsParams,
  ReportApiService,
  reportsQueryKeys,
} from 'features/reports';
// import { canViewDayViewLi3ibStats, useUserContext } from 'features/users';
import React from 'react';
import { DayViewStatsResponse } from 'types/dayviewstats';

// interface Label {
//   id: number;
//   name: string;
// }

interface DayViewStatsProps {
  date?: any;
  company_id?: any;
  facility_id?: any;
}

const DayViewStatsComponent: React.FC<DayViewStatsProps> = ({
  date,
  company_id,
  facility_id,
}) => {
  const { getDayViewStats } = ReportApiService();

  const useGetDayViewStats = (
    params?: GetDayViewStatsParams | undefined,
    options?: UseQueryOptions<DayViewStatsResponse, Error>,
  ) => {
    const { message } = App.useApp();

    return useQuery<DayViewStatsResponse, Error>(
      reportsQueryKeys.list(params || {}),
      ({ signal }) => getDayViewStats(params || {}, { config: { signal } }),
      {
        onError: (error: unknown) => {
          const uiMessage = handleError(error);
          message.error(uiMessage);
        },
        ...options,
      },
    );
  };

  // Call the useGetDayViewStats hook directly within the component
  //const { data, isLoading, isError, error }: UseQueryResult<DayViewStatsResponse, Error> = useGetDayViewStats({

  const { data }: UseQueryResult<DayViewStatsResponse, Error> =
    useGetDayViewStats({
      // Pass your params here
      date: date,
      company_id: company_id === undefined ? null : company_id,
      facility_id: facility_id === undefined ? null : facility_id,
    });

  // const { user } = useUserContext();

  return (
    <div className='stats_detls'>
      <div className='row'>
        <div className='col'>
          <div className='stats_bx'>
            <span>Total Daily</span>
            {data === null || data === undefined ? (
              <div className='loader'></div>
            ) : (
              <h4>KD {data?.totalTodaysBooking}</h4>
            )}
          </div>
        </div>
        <div className='col'>
          <div className='stats_bx'>
            <span>Total Monthly</span>
            {data === null || data === undefined ? (
              <div className='loader'></div>
            ) : (
              <h4>KD {data?.totalMonthlyBooking}</h4>
            )}
          </div>
        </div>
        {/* {canViewDayViewLi3ibStats(user) && (
          <> */}
        <div className='col'>
          <div className='stats_bx'>
            <span>LI3IB Daily</span>
            {data === null || data === undefined ? (
              <div className='loader'></div>
            ) : (
              <h4>KD {data?.totalTodaysLi3ibBooking}</h4>
            )}
          </div>
        </div>
        <div className='col'>
          <div className='stats_bx'>
            <span>LI3IB Monthly</span>
            {data === null || data === undefined ? (
              <div className='loader'></div>
            ) : (
              <h4>KD {data?.totalMonthlyLi3ibBooking}</h4>
            )}
          </div>
        </div>
        {/* </>
        )} */}
      </div>
    </div>
  );
};

export default DayViewStatsComponent;
