import {
  QueryFunction,
  QueryKey,
  useQuery,
  UseQueryOptions,
} from '@tanstack/react-query';
import { App } from 'antd';
import { handleError } from 'api';
import { PackageApiService } from 'features/package/api/package-api.service';
import { UserListParams, UserResponse } from 'features/users';
import { userQueryKeys } from 'features/users/use-cases/query-keys';
import qs from 'qs';
const { getPackage } = PackageApiService();

const getUsersQuery = (
  params: UserListParams,
  options?: UseQueryOptions<UserResponse>,
): {
  queryKey: QueryKey;
  queryFn: QueryFunction<UserResponse>;
  options?: UseQueryOptions<UserResponse>;
} => ({
  queryKey: userQueryKeys.list(params),
  queryFn: ({ signal }) =>
    getPackage(params, {
      config: {
        signal,
        paramsSerializer: {
          serialize: (queryParams) =>
            qs.stringify(queryParams, {
              arrayFormat: 'repeat',
              encode: true,
            }),
        },
      },
    }),
  ...options,
});

export const useGetPackageList = (
  params: UserListParams,
  options?: UseQueryOptions<UserResponse>,
) => {
  const { message } = App.useApp();

  return useQuery<UserResponse, Error>(
    getUsersQuery(params, {
      onError: (error: unknown) => {
        const uiMessage = handleError(error);

        message.error(uiMessage);
      },
      ...options,
    }),
  );
};
