import { useMutation, useQueryClient } from '@tanstack/react-query';
import { App } from 'antd';
import { handleError } from 'api';
import {
  CreateBadgeDTO,
  QuestionApiService,
} from 'features/user-level/api/questions-api.service';
import i18next from 'i18next';

import { questionQueryKeys } from './query-keys';
const { updateBadge } = QuestionApiService();

export const useCreateBadge = () => {
  const { message } = App.useApp();
  const queryClient = useQueryClient();

  return useMutation(
    (payload: CreateBadgeDTO) => {
      return updateBadge(payload);
    },
    {
      onError: (error) => {
        const uiMessage = handleError(error);

        message.error(uiMessage);
      },
      onSuccess: () => {
        // TODO: invalidate week view endpoint as well, when ready
        queryClient.invalidateQueries({
          queryKey: questionQueryKeys.all,
        });

        message.success(i18next.t('userLevel.create.successfulCreate'));
      },
    },
  );
};
