import {
  QueryFunction,
  QueryKey,
  useQuery,
  UseQueryOptions,
} from '@tanstack/react-query';
import { App } from 'antd';
import { handleError } from 'api';
import { PackageApiService } from 'features/package/api/package-api.service';
import { UserListParams } from 'features/users';
import qs from 'qs';

import { packageQueryKeys } from './query.keys';

const { getCancellationPolicy } = PackageApiService();

const getUsersQuery = (
  params: UserListParams,
  options?: UseQueryOptions<any>,
): {
  queryKey: QueryKey;
  queryFn: QueryFunction<any>;
  options?: UseQueryOptions<any>;
} => ({
  queryKey: packageQueryKeys.list(params),
  queryFn: ({ signal }) =>
    getCancellationPolicy(params, {
      config: {
        signal,
        paramsSerializer: {
          serialize: (queryParams) =>
            qs.stringify(queryParams, {
              arrayFormat: 'repeat',
              encode: true,
            }),
        },
      },
    }),
  ...options,
});

export const useGetCancellationList = (
  params: UserListParams,
  options?: UseQueryOptions<any>,
) => {
  const { message } = App.useApp();

  return useQuery<any, Error>(
    getUsersQuery(params, {
      onError: (error: unknown) => {
        const uiMessage = handleError(error);

        message.error(uiMessage);
      },
      ...options,
    }),
  );
};
