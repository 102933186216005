import { RcFile } from 'antd/es/upload/interface';
import { ContentTopBar, Loader } from 'components';
import { StyledContentBackground } from 'components/content-full-screen/content-full-screen';
import { Result404 } from 'components/errors';
import {
  CompanyDetailsForm,
  CompanyDetailsFormValues,
} from 'features/companies/components/company-details-form';
import {
  useGetCompany,
  useUpdateCompany,
  useUploadCompanyImage,
} from 'features/companies/use-cases';
import { useDeleteCompanyImage } from 'features/companies/use-cases/delete-company-image';
import { useState } from 'react';
import { ROUTES } from 'routes/constants';
import { Company } from 'types';

type CompanyDetailsProps = {
  companyId: string;
  onSuccess: () => void;
};

const getPayload = (formValues: CompanyDetailsFormValues) => {
  const {
    name,
    name_arabic,
    address,
    country,
    phone_number,
    email,
    geolocation,
    type,
  } = formValues;

  const getGeolocationArray = geolocation.split(',');

  return {
    name,
    name_arabic,
    address,
    country,
    phone_number,
    email,
    lat: getGeolocationArray[0].trim(),
    lon: getGeolocationArray[1].trim(),
    type,
  };
};

export const CompanyDetails = ({
  companyId,
  onSuccess,
}: CompanyDetailsProps) => {
  const [isLoadingUploadImage, setIsLoadingUploadImage] = useState(false);
  const { mutate: mutateUpdateCompany, isLoading: isLoadingUpdateCompany } =
    useUpdateCompany();
  const { uploadImage } = useUploadCompanyImage();
  const { mutate: mutateDeleteCompanyImage } = useDeleteCompanyImage();

  const companyQueryResult = useGetCompany(companyId, {
    cacheTime: 0, //workaround to invalidate initialValues of client data form
  });

  const {
    isLoading: isLoadingCompany,
    isError: isErrorCompany,
    isFetching: isFetchingCompany,
    data: companyData,
  } = companyQueryResult;

  const handleUpdate = async (
    formValues: CompanyDetailsFormValues,
    fileList: RcFile[],
  ) => {
    if (!companyData) return;
    const payload = { id: companyData.id, ...getPayload(formValues) };

    mutateUpdateCompany(payload, {
      onSuccess(data) {
        const { id } = data as Company;

        if (fileList.length) {
          const formData = new FormData();
          formData.append('file', fileList[0] as RcFile);

          setIsLoadingUploadImage(true);

          uploadImage(id, formData).finally(() => {
            setIsLoadingUploadImage(false);

            return onSuccess();
          });
        } else {
          onSuccess();
        }
      },
    });
  };

  const handleDeleteImage = () => {
    mutateDeleteCompanyImage(companyId);
  };

  return (
    <>
      {isLoadingCompany ? (
        <Loader />
      ) : isErrorCompany ? (
        <Result404 />
      ) : (
        <>
          <ContentTopBar
            title={companyData.name}
            hasBackButton={ROUTES.COMPANIES}
          />
          {/* className='main_bx' */}
          <StyledContentBackground>
            <CompanyDetailsForm
              companyData={companyData}
              handleUpdate={handleUpdate}
              handleDeleteImage={handleDeleteImage}
              isLoading={
                isLoadingUpdateCompany ||
                isFetchingCompany ||
                isLoadingUploadImage
              }
            />
          </StyledContentBackground>
        </>
      )}
    </>
  );
};
