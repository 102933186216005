import { apiClient, ApiClientConfig, PaginatedRequestParams } from 'api';
export type CreateLabelDTO = {
  title?: string;
  description?: string | null;
};
export type UpdateLabelDTO = {
  id?: string;
  title?: string;
  description?: string;
  title_arabic?: string;
  description_arabic?: string;
};
export type CreateOffensiveDTO = {
  word_ar: string;
  word_en: string;
};

export const OffensiveApiService = () => {
  const baseURL = process.env.REACT_APP_BASE_V2_URL;

  const getOffeinsive = async (
    params: PaginatedRequestParams,
    callConfig?: Partial<ApiClientConfig>,
  ) => {
    const response = await apiClient<any>({
      ...callConfig,
      endpoint: `${baseURL}/admin/Offensive/GetWords`,
      params,
    });

    return response.data;
  };

  const createOffensive = async (payload: any) => {
    const response = await apiClient({
      endpoint: `${baseURL}/admin/Offensive/AddWords`,
      method: 'POST',
      data: payload,
    });

    return response.data;
  };
  const offensiveDelete = async (payload: { id: string }) => {
    const response = await apiClient({
      endpoint: `${baseURL}/admin/Offensive/DeleteWord`,
      method: 'DELETE',
      data: payload, // The payload with the id is sent here
    });

    return response.data;
  };
  //   const offensiveDelete = async (
  //     payload: any,
  //     callConfig?: Partial<ApiClientConfig>,
  //   ) => {
  //     const response = await apiClient({
  //         endpoint: `${baseURL}/admin/Offensive/DeleteWord`,
  //         method: 'DELETE',
  //         data: payload,
  //       });
  //     return response.data;
  //   };

  return {
    getOffeinsive,
    createOffensive,
    offensiveDelete,
  };
};
