import { Form, Spin } from 'antd';
import { Loader, Modal, Result404 } from 'components';
import dayjs from 'dayjs';
import { useGetBookingdetails } from 'features/package/use-cases/get-packagedetail';
import { StyledUserModalWrapper } from 'features/users/components/modals/user-modal.styles';
import { useTranslation } from 'react-i18next';
export const PackageViewModal = ({
  onClose,
  id,
}: {
  onClose: () => void;
  id: string;
}) => {
  const { t } = useTranslation();
  dayjs.tz.setDefault('Asia/Kuwait');
  const userQueryResult = useGetBookingdetails(`booking_id=${id}`, {
    cacheTime: 0,
  });
  const formName = 'edit-user-form';
  const [form] = Form.useForm();
  const {
    isLoading: isLoadingGetSocial,
    isFetching: isFetchingGetSocial,
    data: BookingData,
    isError: isErrorLabel,
  } = userQueryResult;
  const indiaTime = dayjs.tz(
    BookingData?.items[0]?.booking_date,
    'Asia/Kolkata',
  );
  const adjustedTime = indiaTime.add(2, 'hour').add(30, 'minute');
  const isLoading = {
    loading: isLoadingGetSocial || isFetchingGetSocial,
    isLoadingGetSocial,
  };
  // useEffect(() => {
  //   onSuccess();
  // }, []);

  return (
    <StyledUserModalWrapper>
      <Modal
        className='modal_social right-modal mtch_dtls_modal'
        open
        centered
        title={'Booking Detail'}
        onCancel={() => {
          document.body.style.overflow = 'none';
          onClose();
        }}
        getContainer={false}
        onOk={form.submit}
        // okText={t('social.update.updatescore')}
        okText={
          BookingData?.match_status === 'Live' ||
          BookingData?.match_status === 'Waiting Result'
            ? t('social.update.updatescore')
            : undefined
        }
        cancelButtonProps={{ style: { display: 'none' } }}
        okButtonProps={{
          form: formName,
          style: {
            display:
              BookingData?.match_status === 'Live' ||
              BookingData?.match_status === 'Waiting Result'
                ? 'block'
                : 'none',
          }, // Hide or display based on condition
        }}
      >
        {isLoadingGetSocial ? (
          <Loader />
        ) : isErrorLabel ? (
          <Result404 />
        ) : (
          <Spin spinning={isLoading.loading}>
            <div className='card_pk_details'>
              <h3>Customer Information</h3>
              <div className='bk_inner'>
                <p>
                  <span>Name:</span>{' '}
                  <span>{BookingData?.items[0]?.customer_name}</span>{' '}
                </p>
                <p>
                  <span>Phone Number:</span>{' '}
                  <span>{BookingData?.items[0]?.customer_phone_number}</span>
                </p>
              </div>
            </div>
            <div className='card_pk_details'>
              <h3>Package Information</h3>
              <div className='bk_inner'>
                <p>
                  <span>Package Name:</span>
                  <span>{BookingData?.items[0]?.package_name}</span>
                </p>
                <p>
                  <span>Facility Name:</span>{' '}
                  <span>{BookingData?.items[0]?.facility_name}</span>
                </p>
                <p>
                  <span>Company Name:</span>{' '}
                  <span>{BookingData?.items[0]?.company_name}</span>
                </p>
                <p>
                  <span>Type:</span>{' '}
                  <span>{BookingData?.items[0]?.package_type}</span>
                </p>
                <p>
                  <span>Package Start Date:</span>{' '}
                  <span>
                    {dayjs(BookingData?.items[0]?.package_time_start).format(
                      'DD-MMM-YYYY hh:mm A',
                    )}
                  </span>
                </p>
                <p>
                  <span>Package End Date:</span>{' '}
                  <span>
                    {dayjs(BookingData?.items[0]?.package_time_end).format(
                      'DD-MMM-YYYY hh:mm A',
                    )}
                  </span>
                </p>
              </div>
            </div>
            <div className='card_pk_details'>
              <h3>Booking Information</h3>
              <div className='bk_inner'>
                <p>
                  <span>Booking Date:</span>
                  <span>
                    {dayjs(BookingData?.items[0]?.booking_date).format(
                      'DD-MMM-YYYY',
                    )}
                  </span>
                </p>
                <p>
                  <span>Booking Amount:</span>
                  <span>{BookingData?.items[0]?.paid_amount} KD</span>
                </p>
                <p>
                  <span>Booking Status:</span>
                  {/* <span className={BookingData?.items[0]?.booking_status_name=="Cancelled" ? '#FF0000' :'#FF5733' }>{BookingData?.items[0]?.booking_status_name}</span> */}
                  <span
                    className={
                      BookingData?.items[0]?.booking_status_name === 'Cancelled'
                        ? 'cancelled'
                        : BookingData?.items[0]?.booking_status_name ===
                          'Booked'
                        ? 'booked'
                        : ''
                    }
                  >
                    {BookingData?.items[0]?.booking_status_name}
                  </span>
                </p>
                {/* <p>
                  <span>Booking Time:</span>
                  <span>
                    {dayjs(BookingData?.items[0]?.booking_date).format(
                      'hh:mm A',
                    )}
                  </span>
                </p> */}
                <p>
                  <span>Booking Time:</span>
                  <span>{adjustedTime.format('hh:mm A')}</span>
                </p>
              </div>
            </div>

            <div className='card_pk_details'>
              <h3>Transaction Information</h3>
              <div className='bk_inner'>
                <p>
                  <span>Transaction Date:</span>
                  <span>
                    {dayjs(BookingData?.items[0]?.transaction_date).format(
                      'DD-MMM-YYYY',
                    )}
                  </span>
                </p>
                <p>
                  <span>Booking Id:</span>
                  <span>{BookingData?.items[0]?.booking_id}</span>
                </p>
                <p>
                  <span>Payment Method:</span>
                  <span>{BookingData?.items[0]?.payment_method}</span>
                </p>
                <p>
                  <span>Tranaction Id:</span>
                  <span>{BookingData?.items[0]?.order_no}</span>
                </p>
              </div>
            </div>

            <Form
              id={formName}
              name={formName}
              form={form}
              layout='vertical'
              // initialValues={initialValues}
              disabled={isLoading.loading}
            >
              {/* <SocialInfoEditDetails SocailData={SocailData} form={form} /> */}
            </Form>
          </Spin>
        )}
      </Modal>
    </StyledUserModalWrapper>
  );
};
