import { PlusOutlined } from '@ant-design/icons';
import { Grid } from 'antd';
import { Button, ContentTopBar } from 'components';
import { AddSocialLabelModal } from 'features/social-label/components/modal/add-social-label-modal';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CompanyType } from 'types';

const { useBreakpoint } = Grid;

export const LabelListTopBar = () => {
  useState<CompanyType>();
  const { t } = useTranslation();
  const screens = useBreakpoint();

  const [showAddUserModal, setShowAddUserModal] = useState(false);

  return (
    <>
      <ContentTopBar
        title={t('social.label.heading')}
        renderButtons={
          <>
            <Button
              icon={!screens.xs ? <PlusOutlined /> : undefined}
              onClick={() => setShowAddUserModal(true)}
            >
              {t('social.button')}
            </Button>
            {showAddUserModal && (
              <AddSocialLabelModal onClose={() => setShowAddUserModal(false)} />
            )}
          </>
        }
      />
      {/* <ContentFilters formId={formId}>
        <LabelFilterForm
          formId={formId}
          handleFilter={handleFilter}
          initialValues={getInitialFilterValues}
        />
      </ContentFilters> */}
    </>
  );
};
