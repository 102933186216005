import i18n from 'locales/i18n';
import { Source, WalletTransactionTypeEnum } from 'types';
import { formatPrice } from 'utils/currency';
import { exhaustiveCheck } from 'utils/lib';

import { WalletTransactionAmount } from './components/wallet-transaction-history-table/wallet-transaction-history-table.styles';

export type AmountColor = 'black' | 'red' | 'green';

export const getAmountColor = (
  transactionType: WalletTransactionTypeEnum,
): AmountColor => {
  switch (transactionType) {
    case WalletTransactionTypeEnum.Booking:
    case WalletTransactionTypeEnum.Refund:
    case WalletTransactionTypeEnum.Withdraw:
      return 'red';
    case WalletTransactionTypeEnum.Cancellation:
    case WalletTransactionTypeEnum.Topup:
      return 'green';
    default:
      exhaustiveCheck(transactionType, 'TransactionType');
  }
};

const getSign = (transactionType: WalletTransactionTypeEnum) => {
  switch (transactionType) {
    case WalletTransactionTypeEnum.Booking:
    case WalletTransactionTypeEnum.Refund:
    case WalletTransactionTypeEnum.Withdraw:
      return '-';
    case WalletTransactionTypeEnum.Cancellation:
    case WalletTransactionTypeEnum.Topup:
      return '+';
    default:
      exhaustiveCheck(transactionType, 'TransactionType');
  }
};

type FormatWalletTransactionAmountProps = {
  value: number;
  currency: string;
  transactionType: WalletTransactionTypeEnum;
  addColor?: boolean;
};

export const formatWalletTransactionAmount = ({
  value,
  currency,
  transactionType,
  addColor = false,
}: FormatWalletTransactionAmountProps) => {
  return (
    <WalletTransactionAmount
      $color={addColor ? getAmountColor(transactionType) : undefined}
    >
      {`${getSign(transactionType)} ${formatPrice({ value, currency })}`}
    </WalletTransactionAmount>
  );
};

type mapWalletTransactionCommentProps = {
  source: Source;
  transactionType: WalletTransactionTypeEnum;
};

export const mapWalletTransactionComment = ({
  source,
  transactionType,
}: mapWalletTransactionCommentProps) => {
  switch (source) {
    case Source.ExternalAdminPortal:
    case Source.InternalAdminPortal:
      if (transactionType === WalletTransactionTypeEnum.Booking)
        return i18n.t('wallet.transactionHistory.table.value.bookedByAdmin');
      if (transactionType === WalletTransactionTypeEnum.Cancellation)
        return i18n.t('wallet.transactionHistory.table.value.cancelledByAdmin');

      return i18n.t('wallet.transactionHistory.table.value.updatedByAdmin');
    case Source.MobileApp:
      if (transactionType === WalletTransactionTypeEnum.Booking)
        return i18n.t('wallet.transactionHistory.table.value.bookedByMobile');
      if (transactionType === WalletTransactionTypeEnum.Cancellation)
        return i18n.t(
          'wallet.transactionHistory.table.value.cancelledByMobile',
        );

      return i18n.t('wallet.transactionHistory.table.value.updatedByMobile');
    default:
    // exhaustiveCheck(source, 'source');
  }
};
