import {
  apiClient,
  ApiClientConfig,
  PaginatedRequestParams,
  PaginatedResponse,
} from 'api';
import { axiosInstance } from 'api/axios-instance';
import { CONFIG } from 'config';
import { Facility } from 'types';

export type FacilitiesResponse = PaginatedResponse<Facility>;

export interface FacilitiesParams extends PaginatedRequestParams {
  is_active?: boolean;
  company_id?: string;
}

export type UpdateFacilityDTO = {
  id: string;
  is_active?: boolean;
  area_id?: string;
  name?: string;
  address?: string;
  open_from?: string;
  open_to?: string;
  payment_methods?: string[];
  lat?: number;
  lon?: number;
  name_arabic?: string;
  rank?: number;
  is_split_payment: boolean;
};

export type CreateFacilityDTO = {
  name: string;
  address: string;
  payment_methods: string[];
  lat: number;
  lon: number;
  name_arabic: string;
  company_id: string;
  area_id: string;
  is_split_payment: any;
};

export type GetFacilitiesParams = {
  is_active?: boolean;
  facility_name?: string;
  sport_name?: string;
} & PaginatedRequestParams;

export const FacilitiesApiService = () => {
  const getFacilities = async (
    params: FacilitiesParams,
    callConfig?: Partial<ApiClientConfig>,
  ) => {
    const response = await apiClient<FacilitiesResponse>({
      ...callConfig,
      endpoint: `/admin/facilities`,
      params,
    });

    return response.data;
  };

  const updateFacility = async (
    payload: UpdateFacilityDTO,
    callConfig?: Partial<ApiClientConfig>,
  ) => {
    const { id, ...data } = payload;

    const response = await apiClient<Facility>({
      ...callConfig,
      endpoint: `/admin/facilities/${id}`,
      method: 'PATCH',
      data,
    });

    return response.data;
  };

  const createFacility = async (payload: CreateFacilityDTO) => {
    const response = await apiClient({
      endpoint: '/admin/facilities',
      method: 'POST',
      data: payload,
    });

    return response.data;
  };

  const uploadFacilityImage = async (id: string, formData: FormData) => {
    // Replace baseUrl for Sports API if the request is for Sports APIs..
    const sportsService = replaceSubstring(
      CONFIG.LI3IB_API_BASE_URL,
      '/identity/',
      '/sports/',
    );

    const response = await axiosInstance.post(
      `${sportsService}/v1/admin/facilities/${id}/image`,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      },
    );

    return response.data;
  };

  const deleteFacilityImage = async (
    id: string,
    callConfig?: Partial<ApiClientConfig>,
  ) => {
    const response = await apiClient({
      ...callConfig,
      endpoint: `/admin/facilities/${id}/image`,
      method: 'DELETE',
    });

    return response.data;
  };

  const getFacility = async (
    id: string,
    callConfig?: Partial<ApiClientConfig>,
  ) => {
    const response = await apiClient<Facility>({
      ...callConfig,
      endpoint: `/admin/facilities/${id}`,
    });

    return response.data;
  };

  const replaceSubstring = (
    input: string,
    target: string,
    replacement: string,
  ): string => {
    const startIndex = input.indexOf(target);
    const endIndex = startIndex + target.length;
    const replacedStr =
      input.slice(0, startIndex) + replacement + input.slice(endIndex);

    return replacedStr;
  };

  return {
    createFacility,
    getFacilities,
    getFacility,
    updateFacility,
    deleteFacilityImage,
    uploadFacilityImage,
  };
};
