import { Form, Grid } from 'antd';
import { Modal } from 'components';
import { OffensiveFilterFormField } from 'features/offensive/components/offensive-filter-form/type';
import { useCreateOffensive } from 'features/offensive/use-cases/create-offensive';
import { StyledUserModalWrapper } from 'features/users/components/modals/user-modal.styles';
import { UserFormValues } from 'features/users/components/users-filter-form';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { OffensiveInfoFormItems } from './offensive-info-form-items';

const { useBreakpoint } = Grid;
export const AddOffensiveModal = ({
  onClose,
  refetch,
}: {
  onClose: () => void;
  refetch: any;
}) => {
  const formName = 'add-user-form';
  const screens = useBreakpoint();
  const [validateTrigger, setValidateTrigger] = useState(['onSubmit']);
  const [form] = Form.useForm<any>();
  const { t } = useTranslation();
  const { isLoading, mutate } = useCreateOffensive();
  const getInitialValues = {
    [OffensiveFilterFormField.WordEnglish]: undefined,
    [OffensiveFilterFormField.WordArabic]: undefined,
  };
  const handleUpdate = (formData: any) => {
    const payload: any = {
      word_list: [
        {
          word_ar: formData.word_ar,
          word_en: formData.word_en,
        },
      ],
    };
    mutate(payload, {
      onSuccess: () => {
        onClose();
        refetch();
      },
    });
  };
  
return (
    <StyledUserModalWrapper>
      <Modal
        open
        centered
        className='right-modal'
        title={'Add New Words'}
        onCancel={onClose}
        getContainer={false}
        onOk={form.submit}
        okText={t('social.formfield.addnewlabel')}
        cancelButtonProps={{ style: { display: 'none' } }}
        width={screens.lg ? '520px' : '100%'}
        okButtonProps={{
          form: formName,
        }}
      >
        <Form<UserFormValues>
          id={formName}
          name={formName}
          form={form}
          layout='vertical'
          initialValues={getInitialValues}
          onFinish={handleUpdate}
          onFinishFailed={() => {
            setValidateTrigger(['onChange']);
          }}
          validateTrigger={validateTrigger}
          disabled={isLoading}
        >
          <OffensiveInfoFormItems form={form} />
        </Form>
      </Modal>
    </StyledUserModalWrapper>
  );
};
