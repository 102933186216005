import { useMutation } from '@tanstack/react-query';
import { App } from 'antd';
import { handleError } from 'api';
import {
  QuestionApiService,
  UpdateBadgeDTO,
} from 'features/user-level/api/questions-api.service';
import i18n from 'locales/i18n';
import { queryClient } from 'providers';

import { questionQueryKeys } from './query-keys';
const { updateBadge } = QuestionApiService();
export const useUpdateBadge = () => {
  const { message } = App.useApp();

  return useMutation(
    (payload: UpdateBadgeDTO) => {
      return updateBadge(payload as any);
    },
    {
      onError: (error) => {
        const uiMessage = handleError(error);

        message.error(uiMessage);
      },
      onSuccess: (data, variables) => {
        Promise.all([
          queryClient.invalidateQueries({
            queryKey: questionQueryKeys.lists(),
          }),
          queryClient.invalidateQueries({
            queryKey: questionQueryKeys.detail(variables.id as any),
          }),
        ]);
        message.success(i18n.t('userLevel.update.successfulUpdateBadge'));
      },
    },
  );
};
