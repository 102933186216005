import { Weekdays } from 'types/days';

export enum FieldDetailsPackage {
  Company = 'company_id',
  Facility = 'facility_id',
  Area = 'area',
  CategoriesType = 'categories_type',
  PackageName = 'package_name',
  FieldName = 'field_name',
  Days = 'days',
  FieldNameArabic = 'field_name_arabic',
  Packagenamearabic = 'package_name_arabic',
  Monthsessioncount = 'month_session_count',
  Packagetypeid = 'package_type_id',
  Image = 'image',
  Price = 'price',
  PackageDay = 'PackageDay',
  PackageTime = 'PackageTime',
  start_date = 'start_date',
  expire_date = 'expire_date',
  Sports = 'sport_ids',
  description = 'description',
  Descriptionarabic = 'description_arabic',
  lat = 'lat',
  lon = 'lon',
  age = 'age',
  gender = 'gender',
  Totalseat = 'total_seat',
}
export type PackageDetailsFormValues = {
  [FieldDetailsPackage.Company]: string;
  [FieldDetailsPackage.Facility]: string;
  [FieldDetailsPackage.PackageName]: string;
  [FieldDetailsPackage.Packagetypeid]: string;
  [FieldDetailsPackage.CategoriesType]: string;
  [FieldDetailsPackage.Packagenamearabic]: string;
  [FieldDetailsPackage.FieldName]: string;
  [FieldDetailsPackage.FieldNameArabic]: string;
  [FieldDetailsPackage.Days]: Weekdays;
  [FieldDetailsPackage.Monthsessioncount]: string;
  [FieldDetailsPackage.Image]: any;
  [FieldDetailsPackage.Price]: string;
  [FieldDetailsPackage.PackageDay]: string;
  [FieldDetailsPackage.PackageTime]: string;
  [FieldDetailsPackage.start_date]: string;
  [FieldDetailsPackage.expire_date]: string;
  [FieldDetailsPackage.description]: string;
  [FieldDetailsPackage.Descriptionarabic]: string;
  [FieldDetailsPackage.lat]: string;
  [FieldDetailsPackage.lon]: string;
  [FieldDetailsPackage.age]: string;
  [FieldDetailsPackage.gender]: string;
  [FieldDetailsPackage.Totalseat]: string;
};

export const week = [
  { label: 'Sunday', value: 0 },
  { label: 'Monday', value: 1 },
  { label: 'Tuesday', value: 2 },
  { label: 'Wednesday', value: 3 },
  { label: 'Thursday', value: 4 },
  { label: 'Friday', value: 5 },
  { label: 'Saturday', value: 6 },
];
export const age = ['5-10', '10-15', '15-20', '20-25'];
