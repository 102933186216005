import { CloseOutlined } from '@ant-design/icons';
import { Button, Form, FormInstance, Input, Row } from 'antd';
import {
  QuestionFormFields,
  QuestionrFormFields,
} from 'features/user-level/components/interface/type';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { FormSectionsSeparator } from './form-sections-separator';

export const QuestionInfoFormItems = ({
  form,
}: {
  form: FormInstance<QuestionrFormFields>;
}) => {
  const { t } = useTranslation();
  useEffect(() => {
    form.getFieldValue('question');
  });

  return (
    <FormSectionsSeparator title={t('userLevel.UpdateQuestion')}>
      <Form.Item<QuestionrFormFields>
        name={QuestionFormFields.question}
        label='Question'
        rules={[{ required: true, message: 'Please Enter Question' }]}
      >
        <Input placeholder='Enter your Question' />
      </Form.Item>
      <Form.Item<QuestionrFormFields>
        name={QuestionFormFields.question_arabic}
        label='Question in Arabic'
        rules={[{ required: true, message: 'Please Enter Question' }]}
      >
        <Input placeholder='Enter your Question' dir='rtl' />
      </Form.Item>
      <Form.List name={QuestionFormFields.OptionList}>
        {(fields, { add, remove }) => (
          <>
            {fields.map(({ key, name, ...restField }, index) => (
              <div key={key} className='ques_card'>
                <div className='head_bx'>
                  {index > 0 && (
                    <Row className='icon'>
                      <CloseOutlined onClick={() => remove(name)} />
                    </Row>
                  )}
                </div>
                <div className='option_ques_edit'>
                  <Row className='option_item '>
                    <Form.Item
                      {...restField}
                      name={[name, QuestionFormFields.option]}
                      // name={[name, 'option']}
                      style={{ width: '100%' }}
                      label={`Option ${index + 1}`}
                      rules={[{ required: true, message: 'Enter your option' }]}
                    >
                      <Input placeholder={`Enter your option`} />
                    </Form.Item>
                    <Form.Item
                      {...restField}
                      name={[name, QuestionFormFields.option_arabic]}
                      // name={[name, 'option']}
                      style={{ width: '100%' }}
                      label={`Option in Arabic ${index + 1}`}
                      rules={[
                        {
                          required: true,
                          message: 'Enter your option in Arabic',
                        },
                      ]}
                    >
                      <Input placeholder={`Enter your option`} dir='rtl' />
                    </Form.Item>
                    <Form.Item
                      {...restField}
                      name={[name, QuestionFormFields.weightage]}
                      // name={[name, 'weightage']}
                      style={{ width: '100%' }}
                      rules={[
                        { required: true, message: 'Enter Your Weightage' },
                      ]}
                      label={'Weightage'}
                    >
                      <Input
                        maxLength={17}
                        placeholder='Enter your weightage'
                        onKeyPress={(e) => {
                          const isDigit = /^\d$/.test(e.key);
                          const isBackspace = e.key === 'Backspace';
                          const isPoint = e.key === '.';
                          const inputValue = (e.target as HTMLInputElement)
                            .value;
                          if (
                            (!isDigit && !isBackspace && !isPoint) ||
                            (inputValue.replace(/\D/g, '').length >= 4 &&
                              !isBackspace &&
                              !isPoint)
                          ) {
                            e.preventDefault();
                          }
                        }}
                      />
                    </Form.Item>
                  </Row>
                </div>
              </div>
            ))}
            <Form.Item>
              <Button type='dashed' onClick={() => add()} block>
                {t('userLevel.buttons.addUserLevel')}
              </Button>
            </Form.Item>
          </>
        )}
      </Form.List>
    </FormSectionsSeparator>
  );
};
