import dayjs from 'dayjs';
import { FieldWithBookings } from 'features/fields';
import { canAddOrCancelPastBookings } from 'features/users';
import React from 'react';
import styled, { css } from 'styled-components';
import { BookingType, User } from 'types';
import { exhaustiveCheck } from 'utils/lib';

import {
  AvailableSlotData,
  MemoizedAvailableTimeSlot,
} from './available-time-slot';
import { MemoizedBookingTimeSlot } from './booking-time-slot';
import { MemoizedClosedTimeSlot } from './closed-time-slot';
import { MemoizedEmptyTimeSlot } from './empty-time-slot';
import { SelectedSlots, SelectSlot } from './timetable-content';
import { generateSlots, getSortedSlots, TimelineHours } from './utils';

type FieldScheduleProps = {
  field: FieldWithBookings;
  date: string;
  user: User;
  timelineHours: TimelineHours;
  selectedSlots: SelectedSlots;
  selectSlot: SelectSlot;
  highlightColumn?: boolean;
};

const ColumnStyle = styled.div<{ isHighlighted: boolean }>`
  ${({ isHighlighted }) =>
    isHighlighted &&
    css`
      && > div {
        border-left: 1px solid var(--color-primary-base, #53a869);
        border-right: 1px solid var(--color-primary-base, #53a869);

        :last-child {
          border-bottom: 1px solid var(--color-primary-base, #53a869);
        }
      }
    `};
`;

export const FieldSchedule = ({
  field,
  date: chosenDate,
  user,
  timelineHours,
  selectSlot,
  selectedSlots,
  highlightColumn = false,
}: FieldScheduleProps) => {
  const slots = React.useMemo(
    () => generateSlots(field, chosenDate, timelineHours),
    [chosenDate, field, timelineHours],
  );
  const sortedSelectedSlots = getSortedSlots(selectedSlots);
  const isFirstLastOrAdjacentSlot = (index: number) => {
    const selectedSlotsCount = sortedSelectedSlots.length;

    if (selectedSlotsCount === 0) return true;

    const firstSelected = sortedSelectedSlots[0].index;
    const lastSelected = sortedSelectedSlots[selectedSlotsCount - 1].index;
    const beforeFirstSelected = firstSelected - 1;
    const afterLastSelected = lastSelected + 1;

    return [
      firstSelected,
      lastSelected,
      beforeFirstSelected,
      afterLastSelected,
    ].includes(index);
  };

  const belongsToSameField = (fieldId: string) =>
    selectedSlots.size > 0 ? sortedSelectedSlots[0].fieldId === fieldId : true;

  const isSelectable = (slot: AvailableSlotData, index: number) =>
    (dayjs(slot.startDate).isAfter(new Date()) ||
      canAddOrCancelPastBookings(user, slot.startDate)) &&
    (belongsToSameField(field.id) ? isFirstLastOrAdjacentSlot(index) : true);

  const isSelected = (fieldId: string, index: number) =>
    selectedSlots.has(`${fieldId}_${index}`);

  return (
    <ColumnStyle isHighlighted={highlightColumn} className='slot_mbl'>
      {slots.map((slot, index) => {
        switch (slot.type) {
          case 'empty':
            return <MemoizedEmptyTimeSlot key={index} data={slot} />;
          case 'available':
            return (
              <MemoizedAvailableTimeSlot
                key={`${field.id}_${index}`}
                fieldId={field.id}
                data={slot}
                index={index}
                selectSlot={selectSlot}
                isSelectable={isSelectable(slot, index)}
                isSelected={isSelected(field.id, index)}
              />
            );
          case BookingType.Closed:
            return <MemoizedClosedTimeSlot key={slot.id} data={slot} />;
          case BookingType.Maintenance:
            return <></>;
          case BookingType.Standard:
          case BookingType.Fixed:
            return (
              <MemoizedBookingTimeSlot
                key={slot.id}
                bookingData={slot}
                userRole={user.role}
              />
            );
          default:
            return exhaustiveCheck(slot, 'Slot');
        }
      })}
    </ColumnStyle>
  );
};
