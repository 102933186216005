import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import {
  Button,
  message,
  Popconfirm,
  Space,
  Switch,
  TablePaginationConfig,
} from 'antd';
import { SorterResult } from 'antd/es/table/interface';
import { Column, Table } from 'components';
import { StyledTableWrapper } from 'components/table/table.styled';
import { LabelApiService } from 'features/social-label/api/label-api.service';
import { useUpdateLabelStatus } from 'features/social-label/use-cases/label-update-status';
import { usePermissions } from 'features/users';
import { EndUserFilterFormValuesPaginated } from 'features/users/components/users-filter-form';
import { Dispatch, SetStateAction, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from 'routes/constants';
import { getRoute } from 'routes/utils';
import { User } from 'types';
type UsersTableProps = {
  isUsersListLoading: boolean;
  usersList: User[];
  itemsCount: number;
  tableParams: EndUserFilterFormValuesPaginated;
  updateParams: Dispatch<SetStateAction<EndUserFilterFormValuesPaginated>>;
};

export const LabelListTable = ({
  usersList,
  isUsersListLoading,
  itemsCount,
  tableParams,
  updateParams,
}: UsersTableProps) => {
  // const { t } = useTranslation();
  const permissions = usePermissions();
  const navigate = useNavigate();
  const [isUpdating, setIsUpdating] = useState(false);
  const { mutate: mutateUpdateQuestion, isLoading: isLoadingUpdateQuestion } =
    useUpdateLabelStatus();
  if (!permissions) return null;

  const onChangeDelete = async (value: any, id: any) => {
    if (isUpdating) return; // If already updating, prevent further updates
    setIsUpdating(true); // Set updating state to true
    try {
      const data = {
        level_id: id,
        status_id: value,
      };
      await LabelApiService().updateStatusSocial(data as any);
      setIsUpdating(false);
      message.success('Delete Level successfully');
    } catch (error) {
      message.error(error as any);
    }
    setTimeout(() => {
      window.location.reload();
    }, 1000);
    setIsUpdating(false); // Reset updating state after successful update
  };
  const handleTableChange = (
    pagination: TablePaginationConfig,
    sorter: SorterResult<User> | SorterResult<User>[],
  ) => {
    updateParams({
      ...tableParams,
      page: pagination.current,
      size: pagination.pageSize,
      order_by: Array.isArray(sorter)
        ? undefined
        : sorter.order === 'ascend'
        ? 'name'
        : '-name',
    });
  };

  return (
    <StyledTableWrapper className='cstmtablecs'>
      {/* <StyledTableHeader>
        <RecordCountBadge text={t('social.foundSocial')} count={itemsCount} />
      </StyledTableHeader> */}
      <Table<User>
        loading={isUsersListLoading}
        dataSource={usersList}
        rowKey='id'
        onChange={handleTableChange}
        pagination={{
          current: tableParams.page,
          defaultPageSize: tableParams.size,
          total: itemsCount,
        }}
        // scroll={{ x: 1440 }}
        // onRow={(record) => {
        //   return {
        //     onClick: () => {
        //       const selection = window?.getSelection()?.toString();

        //       if (!selection?.length) {
        //         navigate(getRoute(ROUTES.LABEL_EDIT, record.id));
        //       }
        //     },
        //   };
        // }}
      >
        <Column<User> title={'Title in English'} dataIndex={'title'} />
        <Column<User> title={'Title in Arabic'} dataIndex={'title_arabic'} />

        <Column<User>
          title={'Description in English'}
          dataIndex={'description'}
        />
        <Column<User>
          title={'Description in Arabic'}
          dataIndex={'description_arabic'}
        />
        <Column<User>
          title={'Enable/Disable'}
          render={(_, record: any) => (
            <Space>
              <Switch
                defaultChecked={record.is_active}
                checked={record.is_active}
                loading={isLoadingUpdateQuestion}
                onChange={(isChecked) => {
                  mutateUpdateQuestion({
                    level_id: record.id,
                    status_id: isChecked == false ? 2 : 1,
                  } as any);
                  // Update the is_active status locally
                  record.is_active = isChecked;
                }}
                onClick={(checked, event) => {
                  event.stopPropagation();
                  event.preventDefault();
                }}
              />
            </Space>
          )}
        />
        <Column<User>
          title={'Action'}
          render={(_, record: any) => (
            <Space>
              <Popconfirm
                title='Delete the Label'
                description='Are you sure to delete this Label?'
                onConfirm={() => onChangeDelete(3, record.id)}
                // onCancel={cancel as any}
                okText='Yes'
                cancelText='No'
              >
                <Button>
                  <DeleteOutlined />
                </Button>
              </Popconfirm>

              <Button
                type='primary'
                onClick={() => navigate(getRoute(ROUTES.LABEL_EDIT, record.id))}
              >
                <EditOutlined />
              </Button>
            </Space>
          )}
        />
      </Table>
    </StyledTableWrapper>
  );
};
