import { EditOutlined } from '@ant-design/icons';
import { Button, Table } from 'components';
import { StyledTableWrapper } from 'components/table/table.styled';
import { usePermissions } from 'features/users';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from 'routes/constants';
import { getRoute } from 'routes/utils';

type BadgeTableProps = {
  isLoading: boolean;
  data?: any;
};
export const BadgeTable = ({ isLoading, data }: BadgeTableProps) => {
  const permissions = usePermissions();
  const navigate = useNavigate();
  if (!permissions) return null;
  const columns = [
    // {
    //   title: 'ID',
    //   dataIndex: 'id',
    //   key: 'id',
    // },
    {
      title: 'Title in English',
      dataIndex: 'title',
      key: 'title',
    },
    {
      title: 'Title in Arabic',
      dataIndex: 'title_arabic',
      key: 'title',
    },
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
      render: (text: any) => {
        const stripHtmlTags = (html: any) => {
          if (!html) return '';

          return html.replace(/<[^>]*>?/gm, '');
        };

        const strippedText = stripHtmlTags(text);
        
return (
          <span>
            {strippedText.length > 20
              ? strippedText.slice(0, 20) + '...'
              : strippedText}
          </span>
        );
      },
    },
    {
      title: 'Description in Arabic',
      dataIndex: 'description_arabic',
      key: 'description',
      render: (text: any) => {
        const stripHtmlTags = (html: any) => {
          if (!html) return '';

          return html.replace(/<[^>]*>?/gm, '');
        };

        const strippedText = stripHtmlTags(text);
        
return (
          <span>
            {strippedText.length > 20
              ? strippedText.slice(0, 20) + '...'
              : strippedText}
          </span>
        );
      },
    },
    {
      title: 'Action',
      dataIndex: 'Action',
      key: 'description',
      render: (value: any) => (
        <Button
          type='primary'
          onClick={() => navigate(getRoute(ROUTES.BADGE_EDIT, value.id))}
        >
          <EditOutlined />
        </Button>
      ),
    },
  ];

  return (
    <StyledTableWrapper>
      <Table
        className='badge-page'
        dataSource={[data]}
        onRow={(record) => {
          return {
            onClick: () => {
              const selection = window?.getSelection()?.toString();

              if (!selection?.length) {
                navigate(getRoute(ROUTES.BADGE_EDIT, record.id));
              }
            },
          };
        }}
        loading={isLoading}
        columns={columns}
      />
    </StyledTableWrapper>
  );
};
