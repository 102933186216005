import { DashBoardView } from 'features/role-management/components/Dashboard/Dashboard-view';
import { DashBoardTopBar } from 'features/role-management/components/top-bar/Dashboard-top';
import { Outlet } from 'react-router-dom';
// ../components/Dashboard/Dashboard-view
// ../components/top-bar/Dashboard-top
// const preparePayload = (params: EndUserFilterFormValuesPaginated) => {
//   const createdAtFrom = params.date?.[0];
//   const createdAtTo = params.date?.[1];

//   return {
//     ...omit(params, ['date']),
//     created_at_from: createdAtFrom
//       ? formatDate({
//           date: dayjs(createdAtFrom).startOf('day'),
//           format: 'dbDate',
//         })
//       : undefined,
//     created_at_to: createdAtTo
//       ? formatDate({
//           date: dayjs(createdAtTo).endOf('day'),
//           format: 'dbDate',
//         })
//       : undefined,
//   };
// };

export const RoleView = () => {
  // const { params } =
  //   usePaginationParams<UserFilterFormValuesPaginated>({
  //     page: 1,
  //     size: 10,
  //     ...getInitialFilterValues,
  //   });

  return (
    <>
      <DashBoardTopBar
      // handleFilter={handleFiltersChange}
      // getInitialFilterValues={getInitialFilterValues}
      />
      <DashBoardView
      // isUsersListLoading={
      //   getUsersListQueryResponse.isLoading &&
      //   getUsersListQueryResponse.isFetching
      // }
      // usersList={getUsersListQueryResponse.data?.items || []}
      // itemsCount={getUsersListQueryResponse.data?.total || 0}
      // tableParams={params as any}
      // updateParams={updateParams}
      />
      <Outlet />
    </>
  );
};
