import {
  QueryFunction,
  QueryKey,
  useQuery,
  UseQueryOptions,
} from '@tanstack/react-query';
import { App } from 'antd';
import { handleError } from 'api';
import { CompaniesParams } from 'features/companies';
import { companiesQueryKeys } from 'features/companies/use-cases/query-keys';
import {
  QuestionApiService,
  QuestionResponse,
} from 'features/user-level/api/questions-api.service';

const { getBadge } = QuestionApiService();

const getCompaniesQuery = (
  params: CompaniesParams,
  options?: UseQueryOptions<QuestionResponse>,
): {
  queryKey: QueryKey;
  queryFn: QueryFunction<QuestionResponse>;
  options?: UseQueryOptions<QuestionResponse>;
} => ({
  queryKey: companiesQueryKeys.list(params),
  queryFn: ({ signal }) => getBadge(params, { config: { signal } }),
  ...options,
});

export const useGetBadge = (
  params: CompaniesParams,
  options?: UseQueryOptions<any>,
) => {
  const { message } = App.useApp();

  return useQuery<any, Error>(
    getCompaniesQuery(params, {
      onError: (error: unknown) => {
        const uiMessage = handleError(error);

        message.error(uiMessage);
      },
      ...options,
    }),
  );
};
