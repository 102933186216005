import * as XLSX from 'xlsx';
export const downloadExcel = () => {
  // Create a new workbook and a worksheet
  const workbook = XLSX.utils.book_new();
  const worksheet = XLSX.utils.json_to_sheet([
    { word_english: '', word_arabic: '' }, // Example header; adjust as needed
  ]);
  // Add the worksheet to the workbook
  XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
  // Generate a binary string representation of the workbook
  const excelBuffer = XLSX.write(workbook, {
    bookType: 'xlsx',
    type: 'array',
  });
  // Create a Blob from the binary string
  const data1 = new Blob([excelBuffer], {
    type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8',
  });
  // Create a link element and trigger the download
  const link = document.createElement('a');
  link.href = URL.createObjectURL(data1);
  link.download = 'Excelformat.xlsx';
  link.click();
};
