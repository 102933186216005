import { Source } from './bookings';
import { Currency } from './transaction';

export enum WalletTransactionTypeEnum {
  Booking = 'BOOKING',
  Cancellation = 'CANCELLATION',
  Refund = 'REFUND',
  Topup = 'TOPUP',
  Withdraw = 'WITHDRAW',
}

export type UserWithWallet = {
  id: string;
  balance: number;
  currency: string;
  last_transaction_date: string;
  user: {
    id: string;
    first_name: string;
    last_name: string;
    phone_number: string;
  };
};

type WalletTransaction = {
  id: string;
  currency: Currency;
  booking: {
    id: string;
    start_date: string;
    field: {
      id: string;
      name: string;
    };
  };
};

export type WalletTransactionsHistoryItem = {
  id: string;
  amount: number;
  balance_after: number;
  type: WalletTransactionTypeEnum;
  source: Source;
  created_at: string;
  created_by: {
    first_name: string;
    id: string;
    last_name: string;
  };
  transaction: WalletTransaction[];
};

export type WalletTransactionsHistory = {
  items: WalletTransactionsHistoryItem[];
  wallet_owner: string;
};

type WalletTransactionWithOttu = WalletTransaction & {
  ottu_transaction: {
    id: string;
    tranid: string;
    ref: string;
    auth: string;
  };
};

export type WalletTransactionDetails = {
  id: string;
  amount: number;
  type: WalletTransactionTypeEnum;
  balance_after: number;
  created_at: string;
  source: Source;
  created_by: {
    id: string;
    email: string;
    first_name: string;
    last_name: string;
  };
  transaction: WalletTransactionWithOttu[];
};
