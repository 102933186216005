import dayjs from 'dayjs';
import { LabelListTable } from 'features/social-label/components/list/label-table';
import { LabelListTopBar } from 'features/social-label/components/top-bar/label-top-bar';
import { useGetLabelList } from 'features/social-label/use-cases/get-label';
import {
  EndUserFilterFormValuesPaginated,
  UserFilterFormValues,
  UserFilterFormValuesPaginated,
} from 'features/users/components/users-filter-form/types';
import { usePaginationParams } from 'hooks';
import { omit } from 'lodash';
import { Outlet } from 'react-router-dom';
import { formatDate } from 'utils/date';

const getInitialFilterValues: UserFilterFormValues = {
  full_user_name: undefined,
  phone_number: undefined,
  email: undefined,
  role: undefined,
  company_id: undefined,
};
const preparePayload = (params: EndUserFilterFormValuesPaginated) => {
  const createdAtFrom = params.date?.[0];
  const createdAtTo = params.date?.[1];

  return {
    ...omit(params, ['date']),
    created_at_from: createdAtFrom
      ? formatDate({
          date: dayjs(createdAtFrom).startOf('day'),
          format: 'dbDate',
        })
      : undefined,
    created_at_to: createdAtTo
      ? formatDate({
          date: dayjs(createdAtTo).endOf('day'),
          format: 'dbDate',
        })
      : undefined,
  };
};

export const LabelView = () => {
  const { params, updateParams } =
    usePaginationParams<UserFilterFormValuesPaginated>({
      page: 1,
      size: 10,
      ...getInitialFilterValues,
    });

  const getUsersListQueryResponse = useGetLabelList(
    preparePayload(params as any),
    {
      retry: false,
    },
  );

  //   const handleFiltersChange = (values: UserFilterFormValuesPaginated) => {
  //     updateParams(values);
  //     resetPage();
  //   };

  return (
    <>
      <LabelListTopBar
      // handleFilter={handleFiltersChange}
      // getInitialFilterValues={getInitialFilterValues}
      />
      <LabelListTable
        isUsersListLoading={
          getUsersListQueryResponse.isLoading &&
          getUsersListQueryResponse.isFetching
        }
        usersList={getUsersListQueryResponse.data?.items || []}
        itemsCount={getUsersListQueryResponse.data?.total || 0}
        tableParams={params as any}
        updateParams={updateParams}
      />
      <Outlet />
    </>
  );
};
