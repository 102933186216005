import { App } from 'antd';
import { handleError } from 'api';
import { PackageApiService } from 'features/package/api/package-api.service';
import { queryClient } from 'providers';

import { packageQueryKeys } from './query.keys';

const { uploadPackageImage } = PackageApiService();

export const useUploadPackageImage = () => {
  const { message } = App.useApp();

  const uploadImage = async (id: string, formData: FormData) => {
    await uploadPackageImage(id, formData)
      .then(() => {
        Promise.all([
          queryClient.invalidateQueries({
            queryKey: packageQueryKeys.lists(),
          }),
          queryClient.invalidateQueries({
            queryKey: packageQueryKeys.detail(id),
          }),
        ]);
        message.success('Image uploaded Successfully');
      })
      .catch((error) => {
        const uiMessage = handleError(error);
        console.error(uiMessage);

        message.warning(
          'The Package has been successfully created, but gallery image upload has failed. Please try uploading the image again.',
        );
      });
  };

  return {
    uploadImage,
  };
};
