import { Button, Form, Grid, Select } from 'antd';
import { ContentFilters } from 'components';
import { StandardItemsWrapper } from 'components/content-filters/content-filters.styles';
import { FacilitySelect } from 'features/facilities/components/facility-select/facility-select';
import { SportSelect } from 'features/sports';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { FacilitiesFilterFormField, FacilitiesFilterFormValues } from './types';

const { useBreakpoint } = Grid;

type FacilitiesFilterFormProps = {
  formId: string;
  handleFilter: (values: FacilitiesFilterFormValues) => void;
  initialValues: FacilitiesFilterFormValues;
  closeModal?: () => void;
  companyId?: string;
};

export const FacilitiesFilterForm = ({
  formId,
  handleFilter,
  initialValues,
  closeModal,
  companyId,
}: FacilitiesFilterFormProps) => {
  const [validateTrigger, setValidateTrigger] = useState(['onSubmit']);
  const [form] = Form.useForm<FacilitiesFilterFormValues>();
  const { t } = useTranslation();
  const screens = useBreakpoint();

  const onReset = () => {
    form.resetFields();
  };

  const handleOnFinish = (values: FacilitiesFilterFormValues) => {
    handleFilter(values);
    closeModal?.();
  };

  return (
    <Form<FacilitiesFilterFormValues>
      id={formId}
      name={formId}
      form={form}
      layout='vertical'
      onFinish={handleOnFinish}
      initialValues={initialValues}
      onFinishFailed={() => {
        setValidateTrigger(['onChange']);
      }}
      validateTrigger={validateTrigger}
    >
      <ContentFilters.FormItemsWrapper>
        <StandardItemsWrapper>
          <Form.Item<FacilitiesFilterFormValues>
            label={t('facilities.formFields.facilityName.label')}
            name={FacilitiesFilterFormField.FacilityName}
          >
            <FacilitySelect
              companyId={companyId}
              labelInValue={true}
              placeholder={t('facilities.formFields.facilityName.placeholder')}
              className='filter'
            />
          </Form.Item>
          <Form.Item<FacilitiesFilterFormValues>
            label={t('facilities.formFields.sportName.label')}
            name={FacilitiesFilterFormField.SportName}
          >
            <SportSelect
              labelInValue={true}
              placeholder={t('facilities.formFields.sportName.placeholder')}
              className='filter'
            />
          </Form.Item>
          <Form.Item<FacilitiesFilterFormValues>
            label={'Split Payment'}
            name={FacilitiesFilterFormField.SplitPayments}
          >
            <Select
              placeholder='payments'
              style={{ width: 120 }}
              options={[
                { value: null, label: 'All' },
                { value: true, label: 'Yes' },
                { value: false, label: 'No' },
              ]}
            />
          </Form.Item>
        </StandardItemsWrapper>
      </ContentFilters.FormItemsWrapper>

      {screens.lg && (
        <ContentFilters.ButtonsWrapper>
          <Button type='primary' htmlType='submit'>
            {t('bookings.buttons.filter', { count: 1 })}
          </Button>
          <Button onClick={onReset}>{t('bookings.buttons.reset')}</Button>
        </ContentFilters.ButtonsWrapper>
      )}
    </Form>
  );
};
