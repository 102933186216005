import { ReportViewModal } from 'features/community/components/modal/report-view';
import { useNavigate, useParams } from 'react-router-dom';
import { ROUTES } from 'routes/constants';
// ../components/modal/report-view
export const ReportViewDetail = () => {
  const navigate = useNavigate();
  const { id } = useParams();

  if (!id) return null;

  const closeModal = () => {
    navigate(ROUTES.REPORT_POST);
  };

  return <ReportViewModal id={id} onClose={closeModal} />;
};
