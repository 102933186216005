import {
  apiClient,
  ApiClientConfig,
  PaginatedRequestParams,
  PaginatedResponse,
} from 'api';
import {
  UserWithWallet,
  WalletTransactionDetails,
  WalletTransactionsHistoryItem,
} from 'types';

export type UsersWithWalletResponse = PaginatedResponse<UserWithWallet>;

export type UsersWithWalletParams = {
  phone_number?: string;
  user_name?: string;
} & PaginatedRequestParams;

export type WalletTransactionsHistoryResponse = {
  wallet_owner: string;
} & PaginatedResponse<WalletTransactionsHistoryItem>;

export type TopUpWalletDTO = {
  wallet_id: string;
};
export type CreateApprovedDTO = {
  question?: string;
  OptionList?: any;
};
export type CreateRejectDTO = {
  question?: string;
  OptionList?: any;
};

export type deductWalletDTO = {
  wallet_id: string;
  amount: number;
  currency: string;
};

export const WithdrawApiService = () => {
  const getUsersWithWithdraw = async (
    params: UsersWithWalletParams,
    callConfig?: Partial<ApiClientConfig>,
  ) => {
    const response = await apiClient<UsersWithWalletResponse>({
      ...callConfig,
      endpoint: `/admin/withdraw/withdrawList`,
      params,
    });

    return response.data;
  };
  const getWithdrawTransactionDetails = async (
    id: string,
    callConfig?: Partial<ApiClientConfig>,
  ) => {
    const response = await apiClient<WalletTransactionDetails>({
      ...callConfig,
      endpoint: `admin/withdraw/withdrawList?${id}`,
    });

    return response.data;
  };
  const createApproved = async (payload: CreateApprovedDTO) => {
    const response = await apiClient({
      endpoint: `admin/withdraw/ApproveNReject`,
      method: 'POST',
      data: payload,
    });

    return response.data;
  };
  const createReject = async (payload: CreateApprovedDTO) => {
    const response = await apiClient({
      endpoint: `admin/withdraw/ApproveNReject`,
      method: 'POST',
      data: payload,
    });

    return response.data;
  };
  
return {
    getUsersWithWithdraw,
    createApproved,
    createReject,
    getWithdrawTransactionDetails,
  };
};
