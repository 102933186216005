import { useMutation } from '@tanstack/react-query';
import { App } from 'antd';
import { handleError } from 'api';
import { LabelApiService } from 'features/social-label/api/label-api.service';
import { UpdateUserDTO } from 'features/users/api/user-api.service';
import { userQueryKeys } from 'features/users/use-cases/query-keys';
import i18n from 'locales/i18n';
import { queryClient } from 'providers';
const { updateLabel } = LabelApiService();

export const useUpdateLabel = () => {
  const { message } = App.useApp();

  return useMutation(
    (payload: UpdateUserDTO) => {
      return updateLabel(payload);
    },
    {
      onError: (error) => {
        const uiMessage = handleError(error);

        message.error(uiMessage);
      },
      onSuccess: (data, variables) => {
        Promise.all([
          queryClient.invalidateQueries({
            queryKey: userQueryKeys.lists(),
          }),
          queryClient.invalidateQueries({
            queryKey: userQueryKeys.detail(variables.id),
          }),
        ]);
        message.success(i18n.t('social.update.successfulUpdate'));
      },
    },
  );
};
