import { Button, Space, Table } from 'antd';
import { Column } from 'components';
import dayjs from 'dayjs';
import { useState } from 'react';
export const ReportInfoFormItems = ({ report }: { report: any }) => {
  const [expanded, setExpanded] = useState(false);

  const [pagination1, setPagination1] = useState<any>({
    current: report.page, // Initial page number
    pageSize: report.size, // Initial page size
    total: report.total, // Total number of items
  });
  const handleTableChange = (pagination: any) => {
    setPagination1(pagination);
  };
  const truncateText = (text: any, wordLimit: any) => {
    const words = text.split(' ');
    if (words.length > wordLimit) {
      return {
        truncated: words.slice(0, wordLimit).join(' '),
        isTruncated: true,
      };
    }
    
return { truncated: text, isTruncated: false };
  };
  const ReasonColumn = ({ text }: any) => {
    const { truncated, isTruncated } = truncateText(text, 20);
    
return (
      <div>
        {expanded ? text : truncated}
        {isTruncated && !expanded && (
          <Button type='link' onClick={() => setExpanded(true)}>
            Read More
          </Button>
        )}
        {isTruncated && expanded && (
          <Button type='link' onClick={() => setExpanded(false)}>
            Show Less
          </Button>
        )}
      </div>
    );
  };

  return (
    <Table
      dataSource={report?.items}
      rowKey='id'
      className='rept_view_table'
      onChange={handleTableChange}
      pagination={pagination1}
    >
      <Column
        title={'Reporter'}
        dataIndex={'post_title'}
        render={(_, record: any) => (
          <Space>
            {record.post_reporter_name}
            {record.post_reporter_phone_number}
          </Space>
        )}
      />
      <Column
        title={'Report Date'}
        dataIndex={'post_creator_name'}
        render={(_, record: any) => (
          <Space>{dayjs(record.report_on).format('DD-MM-YYYY')}</Space>
        )}
      />
      <Column
        title={'Reason'}
        dataIndex={'reason'}
        render={(text) => <ReasonColumn text={text} />}
      />
    </Table>
  );
};
