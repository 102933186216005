import { EditUserQuestionModal } from 'features/user-level/components/modals/edit-user-question-modal';
import { useNavigate, useParams } from 'react-router-dom';
import { ROUTES } from 'routes/constants';

export const UserQuestionUpdateView = () => {
  const navigate = useNavigate();
  const { id } = useParams();

  if (!id) return null;

  const closeModal = () => {
    navigate(ROUTES.QUESTION);
    document.body.style.overflow = 'auto';
  };

  return (
    <EditUserQuestionModal
      id={id}
      onClose={closeModal}
      onSuccess={closeModal}
    />
  );
};
