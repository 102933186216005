import { Button, Checkbox } from 'antd';
import { StyledTableWrapper } from 'components/table/table.styled';
import { useState } from 'react';
export const RoleManagementView = () => {
  const permissionLabels = [
    'Permission 1',
    'Permission 2',
    'Permission 3',
    'Permission 4',
    'Permission 5',
  ];
  const [checkedState, setCheckedState] = useState({
    all: false,
    permissions: new Array(permissionLabels.length).fill(false),
  });
  const handleAllChange = (e: any) => {
    const { checked } = e.target;
    setCheckedState({
      all: checked,
      permissions: new Array(permissionLabels.length).fill(checked),
    });
  };
  const handleIndividualChange = (index: number) => (e: any) => {
    const { checked } = e.target;
    const updatedPermissions = [...checkedState.permissions];
    updatedPermissions[index] = checked;
    const allChecked = updatedPermissions.every((item) => item);
    setCheckedState({
      all: allChecked,
      permissions: updatedPermissions,
    });
  };
  
return (
    <StyledTableWrapper className='pemsn_wrap'>
      <div className='pems_head'>
        <h4>Permissions</h4>
        <div className='perms_btn'>
          <Button className='save_btn'>Save</Button>
          <Button className='rset_permsn'>Reset Permissions</Button>
        </div>
      </div>

      <div className='perms_list_wrap'>
        <ul className='permsn_list'>
          <li>
            <span>All Permission Select</span>
            <Checkbox checked={checkedState.all} onChange={handleAllChange} />
          </li>
          {permissionLabels.map((label, index) => (
            <li key={index}>
              <span>{label}</span>
              <Checkbox
                checked={checkedState.permissions[index]}
                onChange={handleIndividualChange(index)}
              />
            </li>
          ))}
        </ul>
      </div>
    </StyledTableWrapper>
  );
};
