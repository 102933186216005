import { EditLabelModal } from 'features/social-label/components/modal/edit-label-modal';
import { useNavigate, useParams } from 'react-router-dom';
import { ROUTES } from 'routes/constants';
export const LabelUpdateView = () => {
  const navigate = useNavigate();
  const { id } = useParams();

  if (!id) return null;

  const closeModal = () => {
    navigate(ROUTES.LABEL);
  };

  return <EditLabelModal id={id} onClose={closeModal} onSuccess={closeModal} />;
};
