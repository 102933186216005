import { SocialViewEditModal } from 'features/social-match/components/modal/social-view-edit';
import { useNavigate, useParams } from 'react-router-dom';
import { ROUTES } from 'routes/constants';
export const SocialEdit = () => {
  const navigate = useNavigate();
  const { id } = useParams();

  if (!id) return null;

  const closeModal = () => {
    navigate(ROUTES.SOCIAL);
  };

  return (
    <SocialViewEditModal id={id} onClose={closeModal} onSuccess={closeModal} />
  );
};
