import styled from 'styled-components';
import { BREAKPOINTS } from 'styles';

export const StyledUserModalWrapper = styled.div`
  ${BREAKPOINTS.laptop} {
    .ant-modal-body {
      // max-height: 80vh;
      //overflow-y: auto;
    }
  }
`;
