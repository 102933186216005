import { CloseCircleFilled } from '@ant-design/icons';
import {
  Button,
  Col,
  Empty,
  message,
  Pagination,
  Popconfirm,
  Row,
  Spin,
} from 'antd';
import { RecordCountBadge } from 'components';
import {
  StyledTableHeader,
  StyledTableWrapper,
} from 'components/table/table.styled';
import { OffensiveApiService } from 'features/offensive/api/offensive-api.service';
import { usePermissions } from 'features/users';
import { useEffect,useState } from 'react';
import { useTranslation } from 'react-i18next';

type UsersWithWalletTableProps = {
  isLoading: boolean;
  data?: any;
  refetch?: (params?: any) => void;
  params?: {
    page: number;
    size: number;
  };
  onPageChange?: (page: number, size: number) => void;
};

type Response = {
  word_en: string;
  word_ar: string;
  id: string;
};

export const OffensiveViewTable = ({
  data,
  isLoading,
  refetch,
  onPageChange,
  params = { page: 1, size: 10 }, // Default params for page and size
}: UsersWithWalletTableProps) => {
  const { t } = useTranslation();
  const permissions = usePermissions();

  const [currentPage, setCurrentPage] = useState(params.page);
  const [pageSize, setPageSize] = useState(params.size);

  useEffect(() => {
    // When `params` change, update the local state
    setCurrentPage(params.page);
    setPageSize(params.size);
  }, [params]);

  if (!permissions) return null;

  const handleDelete = async (record: string) => {
    try {
      const datapayload = { id: record };
      await OffensiveApiService().offensiveDelete(datapayload);
      if (refetch) {
        refetch({ page: currentPage, size: pageSize });
      }
    } catch (error: any) {
      message.error(error.message || 'Error occurred while deleting');
    }
  };

  const handlePageChange = (page: number, size?: number) => {
    setCurrentPage(page);
    setPageSize(size || pageSize);

    if (onPageChange) {
      onPageChange(page, size || pageSize);
    } else if (refetch) {
      refetch({ page, size: size || pageSize });
    }
  };

  return (
    <StyledTableWrapper>
      <StyledTableHeader>
        <RecordCountBadge
          text={t('offensive.offensivefound')}
          count={data?.total || 0}
        />
      </StyledTableHeader>
      {isLoading ? (
        <div className='add_spin'>
          <Spin />
        </div>
      ) : (
        <StyledTableWrapper>
          <div className='offen_box'>
            <Row className='add_wrd_wrap'>
              {data?.items?.length ? (
                data.items.map((item: Response, index: number) => {
                  return (
                    <Col className='word_bx' key={item.id || index}>
                      <span>{item.word_en}</span>
                      <span>{item.word_ar || item.word_en}</span>
                      <div className='crss_icon'>
                        <Popconfirm
                          title='Delete the Word'
                          description='Are you sure to delete this Word?'
                          onConfirm={() => handleDelete(item.id)}
                          okText='Yes'
                          cancelText='No'
                        >
                          <Button className='closeoffensive'>
                            <CloseCircleFilled />
                          </Button>
                        </Popconfirm>
                      </div>
                    </Col>
                  );
                })
              ) : (
                <div className='no_data'>
                  <Empty />
                </div>
              )}
            </Row>
          </div>
          <Pagination
            current={currentPage} // Controlled by state
            pageSize={pageSize}
            total={data?.total || 0}
            onChange={handlePageChange} // Use local handler
            showSizeChanger
            className='offen_pagination'
          />
        </StyledTableWrapper>
      )}
    </StyledTableWrapper>
  );
};
