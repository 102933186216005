import { message } from 'antd';
import { RcFile } from 'antd/es/upload';
import { Button, ContentTopBar } from 'components';
import { StyledContentBackground } from 'components/content-full-screen/content-full-screen';
import { AddPackageForm } from 'features/package/components/add-company-form/add-package-form';
import { useCreatePackage } from 'features/package/use-cases/create-package';
import { useUploadPackageGalleryImage } from 'features/package/use-cases/upload-gallery';
import { useUploadPackageImage } from 'features/package/use-cases/upload-package-image';
import { useTranslation } from 'react-i18next';

type AddCompanyProps = {
  onCancel: () => void;
  onSuccess: () => void;
};

export const AddViewPackage = ({ onCancel, onSuccess }: AddCompanyProps) => {
  const { t } = useTranslation();
  const { isLoading, mutate } = useCreatePackage();
  const { uploadImage } = useUploadPackageImage();
  const { uploadImage: uploadGalleryImage } = useUploadPackageGalleryImage();
  const handleUpdate = async (
    formValues: any,
    fileList: RcFile[],
    galleryFileList: RcFile[],
  ) => {
    if (fileList.length == 0) {
      return message.error('Please upload Image');
    } else {
      message.success('Image upload successfully');
    }
    const dayNames: any = {
      0: 'Sunday',
      1: 'Monday',
      2: 'Tuesday',
      3: 'Wednesday',
      4: 'Thursday',
      5: 'Friday',
      6: 'Saturday',
    };
    const sortedPackageDays = formValues.package_days.sort(
      (a: number, b: number) => a - b,
    );
    const orderPayloadDay = sortedPackageDays.map((day: any) => dayNames[day]);
    const updatedFormValues1 = {
      ...formValues,
      package_days: orderPayloadDay,
    };
    const [lat, lang] = (formValues?.latlang || '').split(',');
    const packagestart_time = formValues.package_time_start;
    const packageend_time = formValues.package_time_end;
    const end_time = formValues.packageend_time;
    const start_time = formValues.packagestart_time;
    const timeString1 = end_time.format('HH:mm');
    const timeStart = start_time.format('HH:mm');
    const formatDateOnly = (datetimeString: any) => {
      const date = new Date(datetimeString);
      const year = date.getFullYear();
      const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Months are zero-based
      const day = date.getDate().toString().padStart(2, '0');

      return `${year}-${month}-${day}`;
    };
    const formatDateTime = (dateString: string, timeString: string) => {
      return `${dateString}T${timeString}:00.000Z`;
    };
    const endOnly = formatDateOnly(packageend_time);
    const startOnly = formatDateOnly(packagestart_time);
    const formattedEndTime = formatDateTime(endOnly, timeString1);
    const formattedstartTime = formatDateTime(startOnly, timeStart);
    const updatedFormValues = {
      // ...formValues
      ...updatedFormValues1,
      lat: lat.trim() || '',
      lon: lang.trim() || '',
      month_session_count: +formValues.month_session_count,
      package_type_id: +formValues.package_type_id,
      total_seat: +formValues.total_seat,
      package_time_start: formattedstartTime,
      package_time_end: formattedEndTime,
      price: +formValues.price,
    };
    delete updatedFormValues.packageend_time;
    delete updatedFormValues.packagestart_time;
    delete updatedFormValues.latlang;
    mutate(updatedFormValues, {
      onSuccess(data) {
        const { package_id } = data as any;
        // if(fileList.length){
        const formData = new FormData();
        formData.append('file', fileList[0] as RcFile);

        uploadImage(package_id, formData).finally(() => onSuccess());
        // }
        // if(galleryFileList.length){
        galleryFileList.map(async (galleryImage) => {
          const galleryImageformData = new FormData();
          galleryImageformData.append('file', galleryImage as RcFile);

          return uploadGalleryImage(package_id, galleryImageformData);
        });
        // }
      },
    });
  };

  return (
    <>
      <ContentTopBar
        title={t('package.addpackage.heading')}
        renderButtons={
          <Button onClick={onCancel}>{t('companies.buttons.cancel')}</Button>
        }
      />

      <StyledContentBackground className='main_bx'>
        <AddPackageForm handleUpdate={handleUpdate} disabled={isLoading} />
      </StyledContentBackground>
    </>
  );
};
