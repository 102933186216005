import { RcFile } from 'antd/es/upload';
import { Button, ContentTopBar } from 'components';
import { StyledContentBackground } from 'components/content-full-screen/content-full-screen';
import { CreateFacilityDTO } from 'features/facilities/api/facilities-api.service';
import { CreateFacilityForm } from 'features/facilities/components/create-facility/create-facility-form';
import {
  useCreateFacility,
  useUploadFacilityImage,
} from 'features/facilities/use-cases';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Facility } from 'types';

import { CreateFacilityFormValues } from './types';
// ./create-facility-form
const getPayload = (
  formValues: CreateFacilityFormValues,
): CreateFacilityDTO => {
  const {
    company,
    area,
    facility_name,
    facility_name_in_arabic,
    geolocation,
    payment,
    split_payment,
  } = formValues;

  const getGeolocationArray = geolocation.split(',');

  return {
    name: facility_name,
    address: '',
    payment_methods: payment,
    lat: Number(getGeolocationArray[0].trim()),
    lon: Number(getGeolocationArray[1].trim()),
    name_arabic: facility_name_in_arabic,
    company_id: company,
    area_id: area,
    is_split_payment: Boolean(split_payment),
  };
};

export const CreateFacility = ({
  onSuccess,
  onCancel,
}: {
  onCancel: () => void;
  onSuccess: () => void;
}) => {
  const { t } = useTranslation();
  const { uploadImage } = useUploadFacilityImage();
  const [isLoadingUploadImage, setIsLoadingUploadImage] = useState(false);
  const { isLoading, mutate } = useCreateFacility();

  const handleUpdate = async (
    formValues: CreateFacilityFormValues,
    fileList: RcFile[],
  ) => {
    const payload = getPayload(formValues);

    mutate(payload, {
      onSuccess(data) {
        const { id } = data as Facility;

        if (fileList.length) {
          const formData = new FormData();
          formData.append('file', fileList[0] as RcFile);

          setIsLoadingUploadImage(true);

          uploadImage(id, formData).finally(() => {
            setIsLoadingUploadImage(false);

            return onSuccess();
          });
        } else {
          onSuccess();
        }
      },
    });
  };

  return (
    <>
      <ContentTopBar
        title={t('facilities.addNewFacility')}
        renderButtons={
          <Button onClick={onCancel}>{t('facilities.buttons.cancel')}</Button>
        }
      />
      {/* className='main_bx' */}
      <StyledContentBackground>
        <CreateFacilityForm
          handleUpdate={handleUpdate}
          disabled={isLoading || isLoadingUploadImage}
        />
      </StyledContentBackground>
    </>
  );
};
