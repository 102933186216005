import { StyledTableWrapper } from 'components/table/table.styled';
import { usePermissions } from 'features/users';

import { RoleManagementView } from './role-management/role-view';
import { DashSideView } from './sidebar/Dashboard-side';

export const DashBoardView = () => {
  const permissions = usePermissions();
  if (!permissions) return null;

  return (
    <StyledTableWrapper className='cstmtablecs'>
      <div className='role_wrap'>
        <div className='sidebar'>
          <DashSideView />
        </div>
        <div className='permisson'>
          <RoleManagementView />
        </div>
      </div>
    </StyledTableWrapper>
  );
};
