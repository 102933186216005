import { ContentFilters, ContentTopBar } from 'components';
import { SocailFilterForm } from 'features/social-match/components/social-filter-form/social-filter-form';
import { UserFilterFormValues } from 'features/users/components/users-filter-form/types';
import { Dispatch, SetStateAction, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CompanyType } from 'types';
type UsersTopBarProps = {
  getInitialFilterValues: UserFilterFormValues;
  setQueryFilters: Dispatch<SetStateAction<any>>;
  resetTableCurrentPage: () => void;
};

export const SocailListTopBar = ({
  getInitialFilterValues,
  setQueryFilters,
  resetTableCurrentPage,
}: UsersTopBarProps) => {
  useState<CompanyType>();
  const { t } = useTranslation();
  const formId = 'users-filters-form';

  const handleFiltersChange = (values: any) => {
    setQueryFilters(values);
    // resetPage();
    resetTableCurrentPage();
  };

  return (
    <>
      <ContentTopBar
        title={t('social.socialList.heading')}
        // renderButtons={
        //   <>
        //     <Button
        //       icon={!screens.xs ? <PlusOutlined /> : undefined}
        //       onClick={() => setShowAddUserModal(true)}
        //     >
        //       {t('adminUsers.addNewUser')}
        //     </Button>
        //     {showAddUserModal && (
        //       <AddAdminUserModal onClose={() => setShowAddUserModal(false)} />
        //     )}
        //   </>
        // }
      />
      <ContentFilters formId={formId}>
        <SocailFilterForm
          formId={formId}
          handleFilter={handleFiltersChange}
          initialValues={getInitialFilterValues}
        />
      </ContentFilters>
    </>
  );
};
