import {
  QueryFunction,
  QueryKey,
  useQuery,
  UseQueryOptions,
} from '@tanstack/react-query';
import { App } from 'antd';
import { handleError } from 'api';
import { WithdrawApiService } from 'features/withdraw/api/withdraw-api.service';
import { WalletTransactionDetails } from 'types';

import { withdrawQueryKeys } from './query-keys';
const { getWithdrawTransactionDetails } = WithdrawApiService();

const getWalletTransactionDetailsQuery = (
  id: string,
  options?: UseQueryOptions<WalletTransactionDetails>,
): {
  queryKey: QueryKey;
  queryFn: QueryFunction<WalletTransactionDetails>;
  options?: UseQueryOptions<WalletTransactionDetails>;
} => ({
  queryKey: withdrawQueryKeys.detail(id),
  queryFn: ({ signal }) =>
    getWithdrawTransactionDetails(id, {
      config: { signal },
    }),
  ...options,
});

export const useGetWithdrawTransactionDetails = (
  id: string,
  options?: UseQueryOptions<WalletTransactionDetails>,
) => {
  const { message } = App.useApp();

  return useQuery<WalletTransactionDetails, Error>(
    getWalletTransactionDetailsQuery(id, {
      onError: (error: unknown) => {
        const uiMessage = handleError(error);

        message.error(uiMessage);
      },
      ...options,
    }),
  );
};
