import { PaginatedRequestParams } from 'api';
import { Option } from 'types';

export enum FacilitiesFilterFormField {
  CompanyId = 'company_id',
  FacilityName = 'facility_name',
  SportName = 'sport_name',
  SplitPayments = 'is_split_payment',
}

export type FacilitiesFilterFormValues = {
  [FacilitiesFilterFormField.CompanyId]: string | undefined;
  [FacilitiesFilterFormField.FacilityName]: Option | undefined;
  [FacilitiesFilterFormField.SportName]: Option | undefined;
  [FacilitiesFilterFormField.SplitPayments]: any;
};

export type FacilitiesFilterFormPaginated = FacilitiesFilterFormValues &
  PaginatedRequestParams;
