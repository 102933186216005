import { useMutation, useQueryClient } from '@tanstack/react-query';
import { App } from 'antd';
import { handleError } from 'api';
import { PackageApiService } from 'features/package/api/package-api.service';
import { CreateBadgeDTO } from 'features/user-level/api/questions-api.service';
import i18next from 'i18next';

import { packageQueryKeys } from './query.keys';
const { createCancellation } = PackageApiService();
export const useCreateTermCancellation = () => {
  const { message } = App.useApp();
  const queryClient = useQueryClient();

  return useMutation(
    (payload: CreateBadgeDTO) => {
      return createCancellation(payload);
    },
    {
      onError: (error) => {
        const uiMessage = handleError(error);

        message.error(uiMessage);
      },
      onSuccess: () => {
        // TODO: invalidate week view endpoint as well, when ready
        queryClient.invalidateQueries({
          queryKey: packageQueryKeys.all,
        });

        message.success(i18next.t('userLevel.create.successfulCreate'));
      },
    },
  );
};
