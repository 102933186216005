import { useMutation, useQueryClient } from '@tanstack/react-query';
import { App } from 'antd';
import { handleError } from 'api';
import { CommunityApiService } from 'features/community/api/community-api.service';
import { CreateBadgeDTO } from 'features/user-level/api/questions-api.service';
import i18next from 'i18next';

import { GroupTermQueryKeys } from './query.keys';
// ../api/community-api.service
const { createTerm } = CommunityApiService();
export const useCreateTerm = () => {
  const { message } = App.useApp();
  const queryClient = useQueryClient();

  return useMutation(
    (payload: CreateBadgeDTO) => {
      return createTerm(payload);
    },
    {
      onError: (error) => {
        const uiMessage = handleError(error);

        message.error(uiMessage);
      },
      onSuccess: () => {
        // TODO: invalidate week view endpoint as well, when ready
        queryClient.invalidateQueries({
          queryKey: GroupTermQueryKeys.all,
        });

        message.success(i18next.t('userLevel.create.successfulCreate'));
      },
    },
  );
};
