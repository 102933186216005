import { ContentFilters, ContentTopBar } from 'components';
import { GroupsFilterForm } from 'features/community/components/groups-filter-form/groups-filter-form';
import {
  CommunityFilterFormPaginated,
  CommunityFilterFormValues,
} from 'features/community/components/groups-filter-form/type';
import { GroupsViewTable } from 'features/community/components/groups-table/groups-view';
import { useGetCommunityList } from 'features/community/use-cases/community.list';
import { usePaginationParams } from 'hooks';
import { Outlet } from 'react-router-dom';

const initialFilterValues: CommunityFilterFormValues = {
  group_type_id: undefined,
  group_name: undefined,
};
export const GroupView = () => {
  const { params, updateParams, resetPage } =
    usePaginationParams<CommunityFilterFormPaginated>({
      page: 1,
      size: 10,
      ...initialFilterValues,
    });
  const { data, isFetching } = useGetCommunityList(params, { retry: false });
  const handleFiltersChange = (values: CommunityFilterFormValues) => {
    updateParams(values);
    resetPage();
  };
  const formId = 'users-with-wallet-filters-form';

  return (
    <>
      <ContentTopBar title={'Group List'} />

      <ContentFilters formId={formId}>
        <GroupsFilterForm
          formId={formId}
          handleFilter={handleFiltersChange}
          initialValues={initialFilterValues}
        />
      </ContentFilters>
      <GroupsViewTable
        isLoading={isFetching}
        data={data}
        tableParams={params}
        updateParams={updateParams}
      />

      <Outlet />
    </>
  );
};
