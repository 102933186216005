import {
  DollarOutlined,
  DownloadOutlined,
  FileDoneOutlined,
  FileTextOutlined,
} from '@ant-design/icons';
import { Button, DatePicker, Typography } from 'antd';
import { StyledContentBackground } from 'components/content-full-screen/content-full-screen';
import { Dayjs } from 'dayjs';
import { canDownloadAccountsReport, useUserContext } from 'features/users';
import { useTranslation } from 'react-i18next';
import { colors } from 'styles';
import { DATE_FORMATS } from 'utils/date';

import {
  CardWrapper,
  ContentHeaderWrapper,
  StyledAvatar,
  StyledButtonWrapper,
  StyledCard,
  StyledText,
} from './reports.styles';

const { RangePicker: DateRangePicker } = DatePicker;

type ReportProps = {
  handleConsolidatedReport: () => void;
  handleAccountsReport: () => void;
  handleSalesReport: () => void;
  handleCancelReport: () => void;
  handleCustomizeAccountsReport: () => void;
  handleDate: (date: [Dayjs | null, Dayjs | null] | null) => void;
  loading: any;
};

export const Reports = ({
  handleDate,
  handleConsolidatedReport,
  handleAccountsReport,
  handleSalesReport,
  handleCancelReport,
  handleCustomizeAccountsReport,
  loading,
}: ReportProps) => {
  const { user } = useUserContext();
  const { t } = useTranslation();

  return (
    <>
      <StyledContentBackground>
        <ContentHeaderWrapper>
          <div>{t('reports.title')}</div>
          <div>
            <DateRangePicker
              allowEmpty={[true, true]}
              format={DATE_FORMATS.date}
              size='large'
              onChange={handleDate}
            />
          </div>
        </ContentHeaderWrapper>
        <CardWrapper className='crd_list'>
          <StyledCard>
            <StyledAvatar
              icon={<FileTextOutlined style={{ color: colors.colorPrimary }} />}
            />
            <StyledText>{t('reports.consolidatedReport')}</StyledText>
            <StyledButtonWrapper className='reprt_btn'>
              <Typography.Text strong style={{ fontSize: '1.2rem' }}>
                {t('reports.xlsFile')}
              </Typography.Text>
              <Button
                onClick={handleConsolidatedReport}
                loading={loading.loading3}
                type='primary'
                icon={<DownloadOutlined />}
              >
                {t('reports.get')}
              </Button>
            </StyledButtonWrapper>
          </StyledCard>

          {canDownloadAccountsReport(user) ? (
            <StyledCard>
              <StyledAvatar
                icon={<DollarOutlined style={{ color: colors.colorPrimary }} />}
              />
              <StyledText>{t('reports.accountsReport')}</StyledText>
              <StyledButtonWrapper className='reprt_btn'>
                <Typography.Text strong style={{ fontSize: '1.2rem' }}>
                  {t('reports.xlsFile')}
                </Typography.Text>
                <Button
                  onClick={handleAccountsReport}
                  loading={loading.loading1}
                  type='primary'
                  icon={<DownloadOutlined />}
                >
                  {t('reports.get')}
                </Button>
              </StyledButtonWrapper>
            </StyledCard>
          ) : null}
          {canDownloadAccountsReport(user) ? (
            <StyledCard>
              <StyledAvatar
                icon={
                  <FileDoneOutlined style={{ color: colors.colorPrimary }} />
                }
              />
              <StyledText>{t('reports.salesReport')}</StyledText>
              <StyledButtonWrapper className='reprt_btn'>
                <Typography.Text strong style={{ fontSize: '1.2rem' }}>
                  {t('reports.xlsFile')}
                </Typography.Text>
                <Button
                  onClick={handleSalesReport}
                  type='primary'
                  loading={loading.loading}
                  icon={<DownloadOutlined />}
                >
                  {t('reports.get')}
                </Button>
              </StyledButtonWrapper>
            </StyledCard>
          ) : null}
          <StyledCard>
            <StyledAvatar
              icon={<FileTextOutlined style={{ color: colors.colorPrimary }} />}
            />
            <StyledText>{t('reports.CancelReport')}</StyledText>
            <StyledButtonWrapper className='reprt_btn'>
              <Typography.Text strong style={{ fontSize: '1.2rem' }}>
                {t('reports.xlsFile')}
              </Typography.Text>
              <Button
                onClick={handleCancelReport}
                loading={loading.loading2}
                type='primary'
                icon={<DownloadOutlined />}
              >
                {t('reports.get')}
              </Button>
            </StyledButtonWrapper>
          </StyledCard>
          <StyledCard>
            <StyledAvatar
              icon={<FileTextOutlined style={{ color: colors.colorPrimary }} />}
            />
            <StyledText>Customize Account Report</StyledText>
            <StyledButtonWrapper className='reprt_btn'>
              <Typography.Text strong style={{ fontSize: '1.2rem' }}>
                {t('reports.xlsFile')}
              </Typography.Text>
              <Button
                onClick={handleCustomizeAccountsReport}
                loading={loading.loading4}
                type='primary'
                icon={<DownloadOutlined />}
              >
                {t('reports.get')}
              </Button>
            </StyledButtonWrapper>
          </StyledCard>
        </CardWrapper>
      </StyledContentBackground>
    </>
  );
};
