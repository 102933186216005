import { EditCancellationModal } from 'features/package/components/modal/edit-term-modal';
import { useNavigate, useParams } from 'react-router-dom';
import { ROUTES } from 'routes/constants';
export const CancellationViewEdit = () => {
  const navigate = useNavigate();
  const { id } = useParams();

  if (!id) return null;

  const closeModal = () => {
    navigate(ROUTES.CANCELLATION_POLICY);
  };

  return (
    <EditCancellationModal
      id={id}
      onClose={closeModal}
      onSuccess={closeModal}
    />
  );
};
