import { useMutation } from '@tanstack/react-query';
import { App } from 'antd';
import { handleError } from 'api';
import { DeleteFieldImageDTO } from 'features/fields';
import { PackageApiService } from 'features/package/api/package-api.service';
import { queryClient } from 'providers';

import { packageQueryKeys } from './query.keys';

const { deletePackageGalleryImage } = PackageApiService();

export const useDeletePackageGalleryImage = () => {
  const { message } = App.useApp();

  return useMutation(
    (payload: DeleteFieldImageDTO) => {
      return deletePackageGalleryImage(payload);
    },
    {
      onError: (error) => {
        const uiMessage = handleError(error);

        console.error(uiMessage);
        message.error(
          'The Package has been successfully created, but gallery image upload has failed. Please try uploading the image again.',
        );
      },
      onSuccess: (_, variables) => {
        Promise.all([
          queryClient.invalidateQueries({
            queryKey: packageQueryKeys.lists(),
          }),
          queryClient.invalidateQueries({
            queryKey: packageQueryKeys.detail(variables.id),
          }),
        ]);
        message.success('Package gallery image has been deleted successfully');
      },
    },
  );
};
