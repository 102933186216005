import { BadgeTable } from 'features/user-level/components/badge/badge-table';
import { BadgeTopBar } from 'features/user-level/components/top-bar/badge-top-bar';
import { useGetBadge } from 'features/user-level/use-cases/get-badge';
import { Outlet } from 'react-router-dom';
export const BadgeView = () => {
  const { data, isFetching } = useGetBadge('' as any);

  return (
    <>
      <BadgeTopBar />
      <BadgeTable isLoading={isFetching} data={data} />
      <Outlet />
    </>
  );
};
