import { AddViewPackage } from 'features/package/components/add-package/add-packages';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from 'routes/constants';
export const AddPackageView = () => {
  const navigate = useNavigate();

  const navigateToList = () => {
    navigate(ROUTES.PACKAGE_MASTER);
  };

  return (
    <AddViewPackage onCancel={navigateToList} onSuccess={navigateToList} />
  );
};
