import { Grid, Switch, TablePaginationConfig } from 'antd';
import { FilterValue, SorterResult } from 'antd/es/table/interface';
import {
  Column,
  DynamicImportIcon,
  FallbackIcon,
  RecordCountBadge,
  Table,
} from 'components';
import {
  StyledTableHeader,
  StyledTableWrapper,
} from 'components/table/table.styled';
import { SportsResponse, useUpdateSport } from 'features/sports';
import { SportsFilterFormPaginated } from 'features/sports/components/sports-filter-form/types';
import { usePermissions } from 'features/users';
import { Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import { Sport } from 'types/sport';
import { SportTranslationsMap } from 'utils/sport';
const { useBreakpoint } = Grid;

type SportsTableProps = {
  isLoading: boolean;
  data?: SportsResponse;
  tableParams: SportsFilterFormPaginated;
  updateParams: Dispatch<SetStateAction<SportsFilterFormPaginated>>;
};

export const SportsTable = ({
  data,
  isLoading,
  tableParams,
  updateParams,
}: SportsTableProps) => {
  const { t } = useTranslation();
  const screens = useBreakpoint();
  const permissions = usePermissions();
  // const [editingId, setEditingId] = useState('');

  const { mutate: mutateUpdateSports, isLoading: isLoadingUpdateSports } =
    useUpdateSport();

  if (!permissions) return null;

  const handleTableChange = (
    pagination: TablePaginationConfig,
    filters: Record<string, FilterValue | null>,
    sorter: SorterResult<Sport> | SorterResult<Sport>[],
  ) => {
    updateParams({
      ...tableParams,
      page: pagination.current,
      size: pagination.pageSize,
      order_by: Array.isArray(sorter)
        ? undefined
        : sorter.order === 'ascend'
        ? 'name'
        : '-name',
    });
  };
  // const isEditing = (id: string) => {
  //   return editingId === id;
  // };
  // const handleRankChange = async (
  //   id: string,
  //   event: any,
  // ) => {
  //   const newValue = +(event.target as HTMLInputElement).value;
  //   if (newValue <= 0) {
  //     message.error(t('facilities.formFields.rank.errorLessThanOne'));
  //   } else {

  //     setEditingId('');
  //   }
  // };

  return (
    <StyledTableWrapper>
      <StyledTableHeader>
        <RecordCountBadge
          text={t('sports.foundSports')}
          count={data?.total || 0}
        />
      </StyledTableHeader>
      <Table<Sport>
        loading={isLoading}
        dataSource={data?.items}
        rowKey='id'
        onChange={handleTableChange}
        pagination={{
          current: tableParams.page,
          defaultPageSize: tableParams.size,
          total: data?.total,
        }}
        scroll={{ x: 1440 }}
      >
        {/* <Column<Sport>
          title={t('fields.table.rank')}
          dataIndex={'rank'}
          sorter={true}
          sortDirections={['ascend', 'descend', 'ascend']}
          defaultSortOrder={'descend'}
          width={screens.xxl ? 'auto' : 240}
          ellipsis
          onCell={(record) => {
            return {
              onClick: (event) => {
                event.stopPropagation();
                setEditingId(record?.id);
              },
            };
          }}
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          render={(value: any, record: any) => {
            const editable = isEditing(record?.id);

            return editable ? (
              <Input
                defaultValue={value}
                onPressEnter={(e) => handleRankChange(record?.id, e)}
              />
            ) : (
              <div>{record?.rank}</div>
            );
          }}
        /> */}
        <Column<Sport>
          dataIndex={'icon_name'}
          width={screens.xxl ? 'auto' : 60}
          render={(_, record) => (
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <DynamicImportIcon
                filePath={`sports/${record.icon}`}
                label={record.name}
                fallback={<FallbackIcon label={record.name} />}
              />
            </div>
          )}
        />
        <Column<Sport>
          title={t('sports.table.name')}
          dataIndex={'name'}
          sorter={true}
          sortDirections={['ascend', 'descend', 'ascend']} // workaround to remove null as an option
          defaultSortOrder={'ascend'}
          width={screens.xxl ? 'auto' : 360}
        />
        <Column<Sport>
          title={t('sports.table.nameArabic')}
          dataIndex={'name_arabic'}
          width={screens.xxl ? 'auto' : 300}
          render={(_, record) => record.name_arabic}
        />
        <Column<Sport>
          title={t('sports.table.sportType')}
          dataIndex={'type'}
          width={screens.xxl ? 'auto' : 120}
          render={(_, record) => SportTranslationsMap[record.type]}
        />
        <Column<Sport>
          title={t('sports.table.enableDisable')}
          width={screens.xxl ? 'auto' : 136}
          dataIndex={'is_active'}
          align={'center'}
          render={(_, record) => (
            <Switch
              disabled={!permissions?.sports.edit}
              defaultChecked={record.is_active}
              checked={record.is_active}
              loading={isLoadingUpdateSports}
              onChange={(isChecked) =>
                mutateUpdateSports({
                  id: record.id,
                  is_active: isChecked,
                })
              }
              onClick={(checked, event) => {
                event.stopPropagation();
                event.preventDefault();
              }}
            />
          )}
        />
      </Table>
    </StyledTableWrapper>
  );
};
