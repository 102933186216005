import { PackageViewModal } from 'features/package/components/modal/package-detail';
import { useNavigate, useParams } from 'react-router-dom';
import { ROUTES } from 'routes/constants';
export const PackageBookingDetails = () => {
  const navigate = useNavigate();
  const { id } = useParams();

  if (!id) return null;

  const closeModal = () => {
    navigate(ROUTES.PACKAGE_BOOKINGS);
  };

  return <PackageViewModal id={id} onClose={closeModal} />;
};
