import { PaginatedRequestParams } from 'api';
import { Dayjs } from 'dayjs';

export enum packageFilterFormField {
  type = 'type_id',
  packagename = 'name',
  companyid = 'company_id',
}
export enum bookingFilterFormField {
  type = 'type_id',
  packagename = 'package_name',
  companyid = 'company_id',
  customername = 'customer_name',
  PhoneNumber = 'customer_phone_number',
  Date = 'date',
  statusid = 'status_id',
  paymentmethod = 'payment_method',
}
export type BookingPackageFilterFormValues = {
  [bookingFilterFormField.type]: string | undefined;
  [bookingFilterFormField.statusid]: string | undefined;
  [bookingFilterFormField.paymentmethod]: string | undefined;
  [bookingFilterFormField.packagename]: string | undefined;
  [bookingFilterFormField.companyid]: string | undefined;
  [bookingFilterFormField.customername]: string | undefined;
  [bookingFilterFormField.PhoneNumber]: string | undefined;
  [bookingFilterFormField.Date]: [Dayjs | undefined, Dayjs | undefined] | null;
};
export type PackageFilterFormValues = {
  [packageFilterFormField.type]: string | undefined;
  [packageFilterFormField.packagename]: string | undefined;
  [packageFilterFormField.companyid]: string | undefined;
};
export type PackageFilterFormPaginated = PackageFilterFormValues &
  PaginatedRequestParams;
export type PackageBookingFilterFormPaginated = BookingPackageFilterFormValues &
  PaginatedRequestParams;
