import { Button, Form, Grid, Input } from 'antd';
import { ContentFilters } from 'components';
import { StandardItemsWrapper } from 'components/content-filters/content-filters.styles';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { OffensiveFilterFormField, OffensiveFilterFormValues } from './type';
const { useBreakpoint } = Grid;

type OffensiveFilterFormProps = {
  formId: string;
  handleFilter: (values: OffensiveFilterFormValues) => void;
  initialValues: OffensiveFilterFormValues;
  closeModal?: () => void;
};

export const OffensiveFilterForm = ({
  formId,
  handleFilter,
  initialValues,
  closeModal,
}: OffensiveFilterFormProps) => {
  const [validateTrigger, setValidateTrigger] = useState(['onSubmit']);
  const [form] = Form.useForm<OffensiveFilterFormValues>();
  const { t } = useTranslation();
  const screens = useBreakpoint();
  const onReset = () => {
    form.resetFields();
    handleFilter({ ...initialValues });
  };

  const handleOnFinish = (values: OffensiveFilterFormValues) => {
    handleFilter(values);
    closeModal?.();
  };

  return (
    <>
      <Form<OffensiveFilterFormValues>
        id={formId}
        name={formId}
        form={form}
        layout='vertical'
        onFinish={handleOnFinish}
        initialValues={initialValues}
        onFinishFailed={() => {
          setValidateTrigger(['onChange']);
        }}
        validateTrigger={validateTrigger}
      >
        <ContentFilters.FormItemsWrapper>
          <StandardItemsWrapper>
            <Form.Item<OffensiveFilterFormValues>
              label={t('offensive.formfield.offensive.label')}
              name={OffensiveFilterFormField.Word}
            >
              <Input
                placeholder={t('offensive.formfield.offensive.placeholder')}
              />
            </Form.Item>
          </StandardItemsWrapper>
        </ContentFilters.FormItemsWrapper>

        {screens.lg && (
          <ContentFilters.ButtonsWrapper>
            <Button type='primary' htmlType='submit'>
              {t('wallet.buttons.filter', { count: 1 })}
            </Button>
            <Button onClick={onReset}>{t('wallet.buttons.reset')}</Button>
          </ContentFilters.ButtonsWrapper>
        )}
      </Form>
    </>
  );
};
