import { ContentTopBar } from 'components';
import { CompanyTermViewTable } from 'features/companies/components/term-table/company-term-view';
import { useGetCompanyTermList } from 'features/companies/use-cases/get-term';
import { Outlet } from 'react-router-dom';
// ../use-cases/get-term
// ../components/modal/add-term-modal
// ../components/term-table/company-term-view
// const { useBreakpoint } = Grid;
export const CompanyTermView = () => {
  // const { data, isFetching } = useGetCompanyTermList('' as any);
  const { data, isFetching } = useGetCompanyTermList('' as any);

  // const [showAddUserModal, setShowAddUserModal] = useState(false);
  // const screens = useBreakpoint();
  return (
    <>
      <ContentTopBar
        title={'Terms & Conditions'}
        // renderButtons={
        //   <>
        //     {!data?.id && (
        //       <Button
        //         icon={!screens.xs ? <PlusOutlined /> : undefined}
        //         onClick={() => setShowAddUserModal(true)}
        //       >
        //         Add Group T&C
        //       </Button>
        //     )}
        //     {showAddUserModal && (
        //       <AddTermModal onClose={() => setShowAddUserModal(false)} />
        //     )}
        //   </>
        // }
      />
      <CompanyTermViewTable isLoading={isFetching} data={data} />
      <Outlet />
    </>
  );
};
