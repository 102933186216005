/* eslint-disable @typescript-eslint/no-explicit-any */
import { Dayjs } from 'dayjs';

import { Amenity } from './amenity';
import { Duration } from './bookings';
import { Sport, SportType } from './sport';

export enum FieldCategory {
  Indoor = 'Indoor',
  Outdoor = 'Outdoor',
  FemaleOnly = 'FemaleOnly',
  MaleOnly = 'MaleOnly',
}
export enum FacilityCategory {
  Yes = 'true',
  No = 'false',
}

export type TimeRange = {
  end_time: string;
  id: string;
  slot_price: number;
  start_time: string;
};

export type Field = {
  [x: string]: any;
  id: string;
  name: string;
  name_arabic: string;
  rank?: number;
  facility_id: string;
  type: SportType;
  price: number; // TODO: change to Money
  open_from: string;
  open_to: string;
  first_range_start_time: string;
  first_range_end_time: string;
  first_range_slot_price: number;
  second_range_start_time: string;
  second_range_end_time: string;
  second_range_slot_price: number;
  max_players: number;
  sports: Pick<Sport, 'id' | 'name' | 'icon'>[];
  time_slot: Duration;
  width: number;
  length: number;
  is_active: boolean;
  categories: FieldCategory[];
  amenities: Amenity[];
  cover_image_url: string;
  image_urls?: {
    url: string;
  }[];
};

export type AllDays = {
  all_days_range: Dayjs[];
  all_days_slot_price: number;
  fieldId: string;
  timeRangeId: string;
};

export type ExceptionDays = {
  exception_days_range: Dayjs[];
  exception_days_slot_price: string;
};

export type TimePrice = {
  all_days: AllDays[];
  exception_days_list: Dayjs[];
  exception_days: ExceptionDays[];
};
