import {
  QueryFunction,
  QueryKey,
  useQuery,
  UseQueryOptions,
} from '@tanstack/react-query';
import { App } from 'antd';
import { handleError } from 'api';
import { QuestionApiService } from 'features/user-level/api/questions-api.service';
import { userQueryKeys } from 'features/users/use-cases/query-keys';
import { User } from 'types';

const { getUser } = QuestionApiService();

const getUserQuery = (
  id: string,
  options?: UseQueryOptions<User>,
): {
  queryKey: QueryKey;
  queryFn: QueryFunction<User>;
  options?: UseQueryOptions<User>;
} => ({
  queryKey: userQueryKeys.detail(id),
  queryFn: ({ signal }) => getUser(id, { config: { signal } }),
  ...options,
});

export const useGetUserQuestion = (
  id: string,
  options?: UseQueryOptions<any>,
) => {
  const { message } = App.useApp();

  return useQuery<any, Error>(
    getUserQuery(id, {
      onError: (error: unknown) => {
        const uiMessage = handleError(error);

        message.error(uiMessage);
      },
      ...options,
    }),
  );
};
