import { CloseOutlined } from '@ant-design/icons';
import { Button, Form, Input, message, Modal, Row } from 'antd';
import {
  QuestionApiService,
  UpdateQuestionDTO,
} from 'features/user-level/api/questions-api.service';
import { EndQuestionrFormValues } from 'features/user-level/components/interface/type';
import { useUpdateQuestion } from 'features/user-level/use-cases/update-question';
import { StyledUserModalWrapper } from 'features/users/components/modals/user-modal.styles';
import { t } from 'i18next';
import { useEffect } from 'react';

const EditModal = ({ open, handlecancel, id, setOpen }: any) => {
  const { mutate: mutateUpdateQuestion } = useUpdateQuestion();
  // const [loading,setLoading]=useState(false)
  const [form] = Form.useForm();
  const initial = async () => {
    try {
      if (id) {
        const apires = await QuestionApiService().getUser(`question_id=${id}`);
        form.setFieldValue('question', apires?.items[0].question);
        form.setFieldValue(
          'OptionList',
          apires?.items[0].optionList.map((res: any) => {
            return {
              option: res.option,
              weightage: res.weightage,
            };
          }),
        );
      }
    } catch (error) {
      message.error('This wrong');
    }
  };
  const handleUpdate = (formData: EndQuestionrFormValues) => {
    try {
      // setLoading(true)
      const payload: UpdateQuestionDTO = {
        question_id: id,
        question: formData.question,
        OptionList: formData.OptionList,
      };
      mutateUpdateQuestion(payload);
      setOpen(false);
    } catch (error) {
      // setLoading(false)
    }
  };
  useEffect(() => {
    initial();
  }, [id]);

  return (
    <>
      <StyledUserModalWrapper>
        <Modal
          footer={null}
          centered={true}
          open={open}
          title={'Update Question'} // Set the title based on modalOperation
          onCancel={handlecancel}
        >
          <Form
            layout='vertical'
            form={form}
            onFinish={handleUpdate}
            initialValues={{
              OptionList: [{ option: null, weightage: null }],
            }}
          >
            <Form.Item
              name='question'
              label='Question'
              rules={[{ required: true, message: 'Please Enter Question' }]}
            >
              <Input placeholder='Enter your Question' />
            </Form.Item>
            <Form.List name='OptionList'>
              {(fields, { add, remove }) => (
                <>
                  {fields.map(({ key, name, ...restField }, index) => (
                    <div key={key} className='ques_card'>
                      <div className='head_bx'>
                        {index > 0 && (
                          <Row className='icon'>
                            <CloseOutlined onClick={() => remove(name)} />
                          </Row>
                        )}
                      </div>
                      <Row className='option_item'>
                        <Form.Item
                          {...restField}
                          name={[name, 'option']}
                          style={{ width: '100%' }}
                          label={`Option ${index + 1}`}
                          rules={[
                            { required: true, message: 'Enter your option' },
                          ]}
                        >
                          <Input placeholder={`Enter your option`} />
                        </Form.Item>
                      </Row>
                      <Row className='option_item'>
                        <Form.Item
                          {...restField}
                          name={[name, 'weightage']}
                          style={{ width: '100%' }}
                          rules={[
                            { required: true, message: 'Enter Your Weightage' },
                          ]}
                          label={'Weightage'}
                        >
                          <Input
                            maxLength={17}
                            placeholder='Enter your weightage'
                            onKeyPress={(e) => {
                              const isDigit = /^\d$/.test(e.key);
                              const isBackspace = e.key === 'Backspace';
                              const isPoint = e.key === '.';
                              const inputValue = (e.target as HTMLInputElement)
                                .value;
                              if (
                                (!isDigit && !isBackspace && !isPoint) ||
                                (inputValue.replace(/\D/g, '').length >= 4 &&
                                  !isBackspace &&
                                  !isPoint)
                              ) {
                                e.preventDefault();
                              }
                            }}
                          />
                        </Form.Item>
                      </Row>
                    </div>
                  ))}
                  <Form.Item>
                    <Button type='dashed' onClick={() => add()} block>
                      {t('userLevel.buttons.addUserLevel')}
                    </Button>
                  </Form.Item>
                </>
              )}
            </Form.List>
            <Button type='primary' htmlType='submit' className='btn_rgt'>
              {t('userLevel.update.updatebutton')}
            </Button>
          </Form>
        </Modal>
      </StyledUserModalWrapper>
    </>
  );
};
export default EditModal;
