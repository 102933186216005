import { PlusOutlined } from '@ant-design/icons';
import { Grid } from 'antd';
import { Button, ContentTopBar } from 'components';
import { AddCancellationModal } from 'features/package/components/modal/add-cancellation-modal';
import { CancellationTermViewTable } from 'features/package/components/package-table/cancellation-term-view';
import { useGetCancellationList } from 'features/package/use-cases/get-cancellation-policy';
import { useState } from 'react';
import { Outlet } from 'react-router-dom';
const { useBreakpoint } = Grid;
export const AcademyTermView = () => {
  const { data, isFetching } = useGetCancellationList('' as any);
  const [showAddUserModal, setShowAddUserModal] = useState(false);
  const screens = useBreakpoint();

  return (
    <>
      <ContentTopBar
        title={'Cancellation-Policy'}
        renderButtons={
          <>
            {!data?.id && (
              <Button
                icon={!screens.xs ? <PlusOutlined /> : undefined}
                onClick={() => setShowAddUserModal(true)}
              >
                Add Group T&C
              </Button>
            )}
            {showAddUserModal && (
              <AddCancellationModal
                onClose={() => setShowAddUserModal(false)}
              />
            )}
          </>
        }
      />
      <CancellationTermViewTable isLoading={isFetching} data={data} />
      <Outlet />
    </>
  );
};
