import { Dayjs } from 'dayjs';

export enum SocialFilterFormField {
  Date = 'date',
  FIELDNAME = 'field_name',
  MATCHTYPE = 'match_type',
  MATCH = 'match_status_id',
  SPORTNAME = 'sport_name',
}
export type SocialFilterFormValues = {
  [SocialFilterFormField.Date]: [Dayjs | undefined, Dayjs | undefined] | null;
  [SocialFilterFormField.FIELDNAME]: string | undefined;
  [SocialFilterFormField.MATCHTYPE]: string | undefined;
  [SocialFilterFormField.SPORTNAME]: string | undefined;
  [SocialFilterFormField.MATCH]: string | undefined;
};
