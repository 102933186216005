import { Form, Spin } from 'antd';
import { Loader, Modal, Result404 } from 'components';
import { useGetSocialMatchScore } from 'features/social-match/use-cases/get-socialmatch';
import { useUpdateSocial } from 'features/social-match/use-cases/update.social';
import { StyledUserModalWrapper } from 'features/users/components/modals/user-modal.styles';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
export const SocialViewEditModal = ({
  onClose,
  onSuccess,
  id,
}: {
  onClose: () => void;
  onSuccess: () => void;
  id: string;
}) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const formName = 'edit-user-form';
  const [validateTrigger, setValidateTrigger] = useState(['onSubmit']);
  const userQueryResult = useGetSocialMatchScore(id, {
    cacheTime: 0,
  });
  const {
    isLoading: isLoadingGetLabel,
    isFetching: isFetchingGetLabel,
    isError: isErrorLabel,
  } = userQueryResult;
  const { mutate: mutateUpdateQuestion, isLoading: isLoadingUpdateSocial } =
    useUpdateSocial();
  const handleUpdate = (formdata: any) => {
    const {
      team_a_score,
      team_a_score1,
      team_a_score2,
      team_b_score,
      team_b_score2,
      team_b_score3,
    } = formdata;
    const payload = {
      match_id: id,
      score: [
        { set_no: 1, team_a_score, team_b_score },
        { set_no: 2, team_a_score: team_a_score1, team_b_score: team_b_score2 },
        { set_no: 3, team_a_score: team_a_score2, team_b_score: team_b_score3 },
      ],
    };
    mutateUpdateQuestion(payload as any, { onSuccess });
  };
  const isLoading = {
    loading: isLoadingGetLabel || isFetchingGetLabel || isLoadingUpdateSocial,
    isLoadingGetLabel,
    isLoadingUpdateSocial,
  };

  return (
    <StyledUserModalWrapper>
      <Modal
        className='modal_social right-modal'
        open
        centered
        title={t('social.list.heading')}
        onCancel={() => {
          // document.body.style.overflow = 'none';
          onClose(); // Call onClose function
        }}
        getContainer={false}
        onOk={form.submit}
        okText={t('social.update.updatescore')}
        cancelButtonProps={{ style: { display: 'none' } }}
        okButtonProps={{
          form: formName,
        }}
      >
        {isLoadingGetLabel ? (
          <Loader />
        ) : isErrorLabel ? (
          <Result404 />
        ) : (
          <Spin spinning={isLoading.loading}>
            <Form
              id={formName}
              name={formName}
              form={form}
              layout='vertical'
              // initialValues={initialValues}
              onFinishFailed={() => {
                setValidateTrigger(['onChange']);
              }}
              validateTrigger={validateTrigger}
              disabled={isLoading.loading}
              onFinish={handleUpdate}
            >
              {/* <SocialInfoEditDetails SocailData={SocailData} form={form} /> */}
            </Form>
          </Spin>
        )}
      </Modal>
    </StyledUserModalWrapper>
  );
};
