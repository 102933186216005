export enum QuestionFormField {
  question_id = 'question_id',
  question = 'question',
  OptionList = 'OptionList',
  question_arabic = 'question_arabic',
  option_arabic = 'option_arabic',
}
export type QuestionFormValues = {
  [QuestionFormField.question_id]: string;
  [QuestionFormField.question]: string;
  [QuestionFormField.OptionList]: string;
};
export enum EndQuestionFormField {
  question_id = 'question_id',
  question = 'question',
  OptionList = 'OptionList',
}

export type EndQuestionrFormValues = {
  [QuestionFormField.question_id]: string;
  [QuestionFormField.question]: string;
  [QuestionFormField.OptionList]: string;
  [QuestionFormField.question_arabic]: string;
  [QuestionFormField.option_arabic]: string;
};

// upgrade code
export enum QuestionFormFields {
  question_id = 'question_id',
  question = 'question',
  OptionList = 'OptionList',
  option = 'option',
  weightage = 'weightage',
  question_arabic = 'question_arabic',
  option_arabic = 'option_arabic',
}
export enum BadgeFormFields {
  id = 'id',
  title = 'title',
  description = 'description',
  title_arabic = 'title_arabic',
  descriptionArabic = 'description_arabic',
}
export type QuestionrFormFields = {
  [QuestionFormFields.question_id]: string;
  [QuestionFormFields.question]: string;
  [QuestionFormFields.OptionList]: string;
  [QuestionFormFields.option]: string;
  [QuestionFormFields.weightage]: string;
  [QuestionFormFields.question_arabic]: string;
  [QuestionFormFields.option_arabic]: string;
};
export type BadgeFormDataFields = {
  [BadgeFormFields.id]: string;
  [BadgeFormFields.title]: string;
  [BadgeFormFields.description]: string;
  [BadgeFormFields.title_arabic]: string;
  [BadgeFormFields.descriptionArabic]: string;
};
