import { apiClient, ApiClientConfig } from 'api';
// const baseURL='https://itplapiv2.li3ib.com/sports/api/v2'

export type updateSocialDTO = {
  match_id?: string;
  score?: string;
  set_no?: number;
  team_a_score?: string;
  team_b_score?: string;
};
export type updateStatus = {
  id?: string | undefined;
};
const baseURL = process.env.REACT_APP_BASE_V2_URL;
export const SocialApiService = () => {
  const getSocial = async (
    params: any,
    callConfig?: Partial<ApiClientConfig>,
  ) => {
    const response = await apiClient<any>({
      ...callConfig,
      endpoint: `${baseURL}/Admin/socialmatch`,
      params,
    });

    return response.data;
  };
  const getSocialMatchStatus = async (
    params: any,
    callConfig?: Partial<ApiClientConfig>,
  ) => {
    const response = await apiClient<any>({
      ...callConfig,
      endpoint: `${baseURL}/admin/SocialMatch/GetMatchStatus`,
      params,
    });

    return response.data;
  };
  const getSocialdetails = async (
    id: string,
    callConfig?: Partial<ApiClientConfig>,
  ) => {
    const response = await apiClient<any>({
      ...callConfig,
      endpoint: `${baseURL}/admin/SocialMatch/${id}`,
    });

    return response.data;
  };
  const getSocialMatchScore = async (
    id: string,
    callConfig?: Partial<ApiClientConfig>,
  ) => {
    const response = await apiClient<any>({
      ...callConfig,
      endpoint: `${baseURL}/SocialMatch/GetMatchScores/${id}`,
    });

    return response.data;
  };
  const updateSocial = async (
    payload: updateSocialDTO,
    callConfig?: Partial<ApiClientConfig>,
  ) => {
    const { match_id, ...data } = payload;

    const response = await apiClient<any>({
      ...callConfig,
      endpoint: `${baseURL}/admin/SocialMatch/UpdateSocialMatchScoreRequest`,
      method: 'POST',
      data: {
        match_id,
        ...data, // Include other data from the payload
      },
    });

    return response.data;
  };
  const updateStatusSocial = async (
    payload: updateStatus,
    callConfig?: Partial<ApiClientConfig>,
  ) => {
    const { id, ...data } = payload;

    const response = await apiClient<any>({
      ...callConfig,
      endpoint: `${baseURL}/admin/SocialMatch/MakeActiveInactive`,
      method: 'POST',
      data,
    });

    return response.data;
  };

  return {
    getSocial,
    getSocialdetails,
    updateSocial,
    updateStatusSocial,
    getSocialMatchStatus,
    getSocialMatchScore,
  };
};
