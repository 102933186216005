import { DownloadOutlined, PlusOutlined } from '@ant-design/icons';
import { Grid } from 'antd';
import { Button, ContentFilters, ContentTopBar } from 'components';
import { AddOffensiveImportModal } from 'features/offensive/components/modal/add-offensive-import-modal';
import { AddOffensiveModal } from 'features/offensive/components/modal/add-offensive-modal';
import { OffensiveFilterForm } from 'features/offensive/components/offensive-filter-form/offensive-filter-form';
import {
  OffensiveFilterFormPaginated,
  OffensiveFilterFormValues,
} from 'features/offensive/components/offensive-filter-form/type';
import { OffensiveViewTable } from 'features/offensive/components/offensive-view/offensive';
import { useGetOffensiveList } from 'features/offensive/use-cases/get-offensive';
import { usePaginationParams } from 'hooks';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Outlet } from 'react-router-dom';
import { downloadExcel } from 'utils/offensive-download';

const initialFilterValues: OffensiveFilterFormValues = {
  word: undefined,
  word_ar: undefined,
  word_en: undefined,
};

const { useBreakpoint } = Grid;

export const OffensiveView = () => {
  const { t } = useTranslation();
  const screens = useBreakpoint();
  const [showAddUserModal, setShowAddUserModal] = useState(false);
  const [showImportModal, setShowImportModal] = useState(false);

  // Pagination parameters with initial values
  const { params, updateParams, resetPage } =
    usePaginationParams<OffensiveFilterFormPaginated>({
      page: 1,
      size: 100,
      ...initialFilterValues,
    });

  // Fetch data with the current pagination parameters
  const { data, isFetching, refetch } = useGetOffensiveList(params, {
    retry: false,
  });

  // Handle changes in filter form
  const handleFiltersChange = (values: OffensiveFilterFormValues) => {
    updateParams(values); // Update filter parameters
    resetPage(); // Reset to the first page
  };

  // Handle page change (e.g., user navigates to a different page or changes the page size)
  const handlePageChange = (page: number, size?: number) => {
    updateParams({ page, size: size || params.size });
    refetch(); // Refetch data with the updated parameters
  };

  const formId = 'users-with-wallet-filters-form';

  return (
    <>
      <ContentTopBar
        title={t('offensive.heading')}
        renderButtons={
          <>
            <div className='offensive-words'>
              <Button
                icon={<DownloadOutlined />}
                onClick={downloadExcel}
                className='smple_btn'
              >
                Sample Format
              </Button>
              <Button
                icon={!screens.xs ? <PlusOutlined /> : undefined}
                onClick={() => setShowImportModal(true)}
              >
                Import
              </Button>
              <Button
                icon={!screens.xs ? <PlusOutlined /> : undefined}
                onClick={() => setShowAddUserModal(true)}
              >
                {t('offensive.buttons.addOffensive')}
              </Button>
              {showAddUserModal && (
                <AddOffensiveModal
                  onClose={() => setShowAddUserModal(false)}
                  refetch={refetch}
                />
              )}
              {showImportModal && (
                <AddOffensiveImportModal
                  onClose={() => setShowImportModal(false)}
                  refetch={refetch}
                />
              )}
            </div>
          </>
        }
      />
      <ContentFilters formId={formId}>
        <OffensiveFilterForm
          formId={formId}
          handleFilter={handleFiltersChange}
          initialValues={initialFilterValues}
        />
      </ContentFilters>
      <OffensiveViewTable
        isLoading={isFetching}
        data={data}
        refetch={refetch}
        params={params as any}
        onPageChange={handlePageChange as any} // Pass the page change handler
      />

      <Outlet />
    </>
  );
};
