import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { Button, message, Popconfirm, Switch } from 'antd';
import { RecordCountBadge } from 'components';
import {
  StyledTableHeader,
  StyledTableWrapper,
} from 'components/table/table.styled';
import {
  QuestionApiService,
  QuestionResponse,
} from 'features/user-level/api/questions-api.service';
import EditModal from 'features/user-level/components/modals/edit-modal';
import { useUpdateStatus } from 'features/user-level/use-cases/update-status';
import { usePermissions } from 'features/users';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from 'routes/constants';
import { getRoute } from 'routes/utils';
type UserLevelTableProps = {
  // isLoading: boolean;
  data?: QuestionResponse;
  // showModal?: any;
  // tableParams: CompaniesFilterFormPaginated;
  // updateParams: Dispatch<SetStateAction<CompaniesFilterFormPaginated>>;
};
export const UserLevelTable = ({
  data,
}: // isLoading,
// tableParams,
// updateParams,
UserLevelTableProps) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const [ids] = useState('');
  const permissions = usePermissions();
  const navigate = useNavigate();
  const [isUpdating, setIsUpdating] = useState(false);

  const { mutate: mutateUpdateQuestion, isLoading: isLoadingUpdateQuestion } =
    useUpdateStatus();
  if (!permissions) return null;
  // const handleTableChange = (pagination: TablePaginationConfig) => {
  //   updateParams({
  //     ...tableParams,
  //     page: pagination.current,
  //     size: pagination.pageSize,
  //   });
  // };
  const handlecancel = () => {
    setOpen(false);
  };
  const onChangeDelete = async (value: any, id: any) => {
    if (isUpdating) return; // If already updating, prevent further updates
    setIsUpdating(true); // Set updating state to true

    try {
      const dataValue = {
        question_id: id,
        status_id: value,
      };
      await QuestionApiService().updateStatusQuestion(dataValue as any);
      message.success('Question Delete Successfully');
      setTimeout(() => {
        window.location.reload(); // Reload the window after successful update
      }, 1000);
    } catch (error) {
      message.error('An error occurred');
    } finally {
      setIsUpdating(false); // Reset updating state after update attempt
    }
  };

  // const onChange = () => {

  // };
  // const items = data?.items.map((res: any, index: number) => {
  //   return {
  //     key: index, // Use the index as the key
  //     label: (
  //       <div className='head'>
  //         <div className='lft_dta'>
  //           <span>{res.question}</span>
  //         </div>
  //         <div className='rgt_dta'>
  //           <EditOutlined
  //             onClick={() => {
  //               navigate(getRoute(ROUTES.USERS_QUESTION_EDIT, res.question_id));
  //               document.body.style.overflow = 'hidden';
  //               // showModal()
  //               // setIds(res?.question_id)
  //               // setOpen(true)
  //             }}
  //             style={{
  //               marginRight: '10px',
  //               cursor: 'pointer',
  //               color: 'green',
  //               fontSize: '20px',
  //               height: '20px',
  //               width: '20px',
  //             }}
  //           />
  //           <Switch
  //             // disabled={!permissions?.facilities.edit}
  //             defaultChecked={res.is_active}
  //             checked={res.is_active}
  //             loading={isLoadingUpdateQuestion}
  //             onChange={(isChecked) => {
  //               mutateUpdateQuestion({
  //                 question_id: res.question_id,
  //                 is_active: isChecked,
  //               });
  //               // Update the is_active status locally
  //               res.is_active = isChecked;
  //             }}
  //             onClick={(checked, event) => {
  //               event.stopPropagation();
  //               event.preventDefault();
  //             }}
  //           />
  //         </div>
  //       </div>
  //     ),
  //     children: (
  //       <ul className='list_user'>
  //         {res?.optionList?.map((item: any, value: number) => (
  //           <li key={item.id}>
  //             <span>
  //               {value + 1}. {item.option}
  //             </span>{' '}
  //             <span>{item.weightage}</span>{' '}
  //           </li>
  //         ))}
  //       </ul>
  //     ),
  //   };
  // });
  return (
    <StyledTableWrapper>
      <StyledTableHeader>
        <RecordCountBadge
          text={t('userLevel.foundUser')}
          count={data?.total || 0}
        />
      </StyledTableHeader>
      {/* <Collapse accordion items={items} destroyInactivePanel={false}>
        <Table
          dataSource={items} // Pass the actual data source here
          loading={isLoading}
          
          pagination={{
            current: tableParams.page,
            pageSize: tableParams.size,
            total: data?.total,
            showSizeChanger: true, // Optional: Show size changer dropdown
          }}
          onChange={handleTableChange}
        />
      </Collapse> */}
      <div className='question_ans'>
        {data?.items.map((res: any, index: number) => {
          return (
            <div className='ad' key={index}>
              <div
                className='ques_no'
                role='button'
                style={{ cursor: 'pointer' }}
                // onClick={() => {
                //   navigate(
                //     getRoute(ROUTES.USERS_QUESTION_EDIT, res.question_id),
                //   );
                //   document.body.style.overflow = 'hidden';

                // }}
              >
                <div>
                  <h3>
                    <span>{index + 1}.</span> {res.question}
                  </h3>
                  <h3> {res.question_arabic}</h3>
                </div>

                <div className='btn_edit'>
                  <EditOutlined
                    onClick={() => {
                      navigate(
                        getRoute(ROUTES.USERS_QUESTION_EDIT, res.question_id),
                      );
                      document.body.style.overflow = 'hidden';
                      // showModal()
                      // setIds(res?.question_id)
                      // setOpen(true)
                    }}
                    style={{
                      marginRight: '10px',
                      cursor: 'pointer',
                      color: 'green',
                      fontSize: '20px',
                      height: '20px',
                      width: '20px',
                    }}
                  />
                  <Switch
                    // disabled={!permissions?.facilities.edit}
                    defaultChecked={res.is_active}
                    checked={res.is_active}
                    loading={isLoadingUpdateQuestion}
                    onChange={(isChecked) => {
                      mutateUpdateQuestion({
                        question_id: res.question_id,
                        status_id: isChecked == false ? 2 : 1,
                      });
                      // Update the is_active status locally
                      res.is_active = isChecked;
                    }}
                    onClick={(checked, event) => {
                      event.stopPropagation();
                      event.preventDefault();
                    }}
                  />
                  <Popconfirm
                    title='Delete the Question'
                    description='Are you sure to delete this Question?'
                    onConfirm={() => onChangeDelete(3, res.question_id)}
                    // onCancel={cancel as any}
                    okText='Yes'
                    cancelText='No'
                  >
                    <Button style={{ marginLeft: '15px' }}>
                      <DeleteOutlined />
                    </Button>
                  </Popconfirm>
                  {/* <Button style={{ marginLeft: "15px" }} onClick={() => onChangeDelete(3, res.question_id)}>
                    <DeleteOutlined />
                  </Button> */}
                </div>
              </div>
              <ul className='ans_list'>
                {res?.optionList?.map((item: any, value: number) => {
                  return (
                    <li key={value}>
                      <span>{value + 1}.</span>
                      {item.option} {item.option_arabic}
                    </li>
                  );
                })}
              </ul>
            </div>
          );
        })}
      </div>
      <EditModal
        open={open}
        setOpen={setOpen}
        handlecancel={handlecancel}
        id={ids}
      />
    </StyledTableWrapper>
  );
};
