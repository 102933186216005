import { Form, Grid } from 'antd';
import { Modal } from 'components';
import {
  BadgeFormDataFields,
  BadgeFormFields,
} from 'features/user-level/components/interface/type';
import { useCreateBadge } from 'features/user-level/use-cases/create-badge';
import { StyledUserModalWrapper } from 'features/users/components/modals/user-modal.styles';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { BadgeInfoFormItems } from './badge-info-form-items';
const { useBreakpoint } = Grid;
export const AddBadgeModal = ({ onClose }: { onClose: () => void }) => {
  const formName = 'add-user-form';
  const screens = useBreakpoint();
  const [validateTrigger, setValidateTrigger] = useState(['onSubmit']);
  const [form] = Form.useForm<BadgeFormDataFields>();
  const { t } = useTranslation();
  const { isLoading, mutate } = useCreateBadge();
  const getInitialValues = {
    [BadgeFormFields.title]: undefined,
    [BadgeFormFields.description]: undefined,
  };
  const handleUpdate = (formData: BadgeFormDataFields) => {
    mutate(formData, { onSuccess: onClose });
  };

  return (
    <StyledUserModalWrapper>
      <Modal
        open
        centered
        title={t('userLevel.buttons.addBedage')}
        onCancel={onClose}
        getContainer={false}
        onOk={form.submit}
        okText={t('userLevel.buttons.addBedage')}
        cancelButtonProps={{ style: { display: 'none' } }}
        width={screens.lg ? '520px' : '100%'}
        okButtonProps={{
          form: formName,
        }}
      >
        <Form<BadgeFormDataFields>
          id={formName}
          name={formName}
          form={form}
          layout='vertical'
          initialValues={getInitialValues}
          onFinish={handleUpdate as any}
          requiredMark={'optional'}
          onFinishFailed={() => {
            setValidateTrigger(['onChange']);
          }}
          validateTrigger={validateTrigger}
          disabled={isLoading}
        >
          <BadgeInfoFormItems form={form} />
          {/* <PasswordFormItems /> */}
        </Form>
      </Modal>
    </StyledUserModalWrapper>
  );
};
