import { RcFile } from 'antd/es/upload';
import { Button, ContentTopBar } from 'components';
import { StyledContentBackground } from 'components/content-full-screen/content-full-screen';
import {
  AddCompanyForm,
  CompanyDetailsFormValues,
  useUploadCompanyImage,
} from 'features/companies';
import { useCreateCompany } from 'features/companies/use-cases/create-company';
import { useTranslation } from 'react-i18next';
import { Company } from 'types';

const getPayload = (formValues: CompanyDetailsFormValues) => {
  const {
    name,
    name_arabic,
    address,
    country,
    phone_number,
    email,
    geolocation,
    type,
  } = formValues;

  const getGeolocationArray = geolocation.split(',');

  return {
    name,
    name_arabic,
    address,
    country,
    phone_number,
    email,
    lat: getGeolocationArray[0].trim(),
    lon: getGeolocationArray[1].trim(),
    type,
  };
};

type AddCompanyProps = {
  onCancel: () => void;
  onSuccess: () => void;
};

export const AddCompany = ({ onCancel, onSuccess }: AddCompanyProps) => {
  const { t } = useTranslation();
  const { isLoading, mutate } = useCreateCompany();
  const { uploadImage } = useUploadCompanyImage();

  const handleUpdate = async (
    formValues: CompanyDetailsFormValues,
    fileList: RcFile[],
  ) => {
    const payload = getPayload(formValues);

    mutate(payload, {
      onSuccess(data) {
        const { id } = data as Company;

        const formData = new FormData();
        formData.append('file', fileList[0] as RcFile);

        uploadImage(id, formData).finally(() => onSuccess());
      },
    });
  };

  return (
    <>
      <ContentTopBar
        title={t('companies.addCompany.heading')}
        renderButtons={
          <Button onClick={onCancel}>{t('companies.buttons.cancel')}</Button>
        }
      />

      <StyledContentBackground>
        <AddCompanyForm handleUpdate={handleUpdate} disabled={isLoading} />
      </StyledContentBackground>
    </>
  );
};
