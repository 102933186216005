import { useMutation, useQueryClient } from '@tanstack/react-query';
import { App } from 'antd';
import { handleError } from 'api';
import { CreateCompanyDTO } from 'features/companies';
import { PackageApiService } from 'features/package/api/package-api.service';

import { packageQueryKeys } from './query.keys';
const { createPackage } = PackageApiService();

export const useCreatePackage = () => {
  const { message } = App.useApp();
  const queryClient = useQueryClient();

  return useMutation(
    (payload: CreateCompanyDTO) => {
      return createPackage(payload);
    },
    {
      onError: (error) => {
        const uiMessage = handleError(error);

        message.error(uiMessage);
      },
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: packageQueryKeys.all,
        });

        message.success('Package successfully Created');
      },
    },
  );
};
