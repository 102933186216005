import { Form, FormInstance } from 'antd';
import { BookingDetailsInfo } from 'features/bookings/components';
import { FieldWithBookings } from 'features/fields';
import { PaymentMethod } from 'types';

import { slotsData } from './add-booking-modal';
import { AddBookingFormField, AddBookingFormValues } from './types';

type AddBookingDetailsInfoProps = {
  data: slotsData;
  field: FieldWithBookings;
  form: FormInstance;
};

export const AddBookingDetailsInfo = ({
  data,
  field,
  form,
}: AddBookingDetailsInfoProps) => {
  const discount = Form.useWatch<AddBookingFormValues['discount']>(
    AddBookingFormField.Discount,
    form,
  );

  const sanitizedDiscount =
    typeof discount === 'number' && discount > 0 ? discount : 0;

  return (
    <BookingDetailsInfo
      {...({
        startDate: data.startDate,
        endDate: data.endDate,
        fieldName: field.name,
        facilityName: field.facility.name,
        companyName: field.facility.company.name,
        initialPrice: data.totalCost,
        paymentMethod: PaymentMethod.Cash,
        discount: sanitizedDiscount,
        finalPrice: data.totalCost - sanitizedDiscount,
      } as any)}
    />
  );
};
