import { useMutation } from '@tanstack/react-query';
import { App } from 'antd';
import { handleError } from 'api';
import { UpdateFieldDTO } from 'features/fields';
import { PackageApiService } from 'features/package/api/package-api.service';
import { queryClient } from 'providers';

import { packageQueryKeys } from './query.keys';

const { updatePackage } = PackageApiService();

export const useUpdatePackage = () => {
  const { message } = App.useApp();

  return useMutation(
    (payload: UpdateFieldDTO) => {
      return updatePackage(payload);
    },
    {
      onError: (error) => {
        const uiMessage = handleError(error);

        message.error(uiMessage);
      },
      onSuccess: (data, variables) => {
        Promise.all([
          queryClient.invalidateQueries({
            queryKey: packageQueryKeys.lists(),
          }),
          queryClient.invalidateQueries({
            queryKey: packageQueryKeys.detail(variables.id),
          }),
        ]);
        message.success('You have successfully updated package.');
      },
    },
  );
};
