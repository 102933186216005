import { useMutation, useQueryClient } from '@tanstack/react-query';
import { App } from 'antd';
import { handleError } from 'api';
import { LabelApiService } from 'features/social-label/api/label-api.service';
import { CreateBadgeDTO } from 'features/user-level/api/questions-api.service';
import { questionQueryKeys } from 'features/user-level/use-cases/query-keys';
const { createUser } = LabelApiService();

export const useCreateLabel = () => {
  const { message } = App.useApp();
  const queryClient = useQueryClient();

  return useMutation(
    (payload: CreateBadgeDTO) => {
      return createUser(payload);
    },
    {
      onError: (error) => {
        const uiMessage = handleError(error);

        message.error(uiMessage);
      },
      onSuccess: () => {
        // TODO: invalidate week view endpoint as well, when ready
        queryClient.invalidateQueries({
          queryKey: questionQueryKeys.all,
        });

        message.success('Level added successfully');
      },
    },
  );
};
