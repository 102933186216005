import { t } from 'i18next';
import { FacilityCategory, Field, FieldCategory, SportType } from 'types';

// const MAX_PLAYERS_CAP = 24;   // old code
// export const maxPlayersValues = Array.from(
//   { length: MAX_PLAYERS_CAP / 2 },
//   (item, index) => (index + 1) * 2,
// );

// export const formatMaxPlayersToTeamSize = (maxPlayers: number) => {
//   // return `${maxPlayers / 2} - ${maxPlayers / 2}`; // new code
//   return `${maxPlayers} - ${maxPlayers}`;   // old code
// };

const MAX_PLAYERS_CAP = 12;
export const maxPlayersValues = Array.from(
  { length: MAX_PLAYERS_CAP },
  (item, index) => index + 1,
);

export const formatMaxPlayersToTeamSize = (maxPlayers: number) => {
  return `${maxPlayers} - ${maxPlayers}`;
};
export const getTeamSizeOptions = () => {
  return maxPlayersValues.map((item) => ({
    label: formatMaxPlayersToTeamSize(item),
    value: item,
  }));
};

export const timeSlotsOptions = [
  { value: '30', label: '30 min' } as const,
  { value: '60', label: '60 min' } as const,
  { value: '90', label: '90 min' } as const,
  { value: '120', label: '120 min' } as const,
];

export const genderOptions = [
  { value: 'all', label: t('fields.formFields.gender.all') } as const,
  {
    value: FieldCategory.FemaleOnly,
    label: t('fields.formFields.gender.femaleOnly'),
  } as const,
  {
    value: FieldCategory.MaleOnly,
    label: t('fields.formFields.gender.maleOnly'),
  } as const,
];

export const typeOptions = [
  {
    value: FieldCategory.Indoor,
    label: t('fields.formFields.categoriesType.indoor'),
  } as const,
  {
    value: FieldCategory.Outdoor,
    label: t('fields.formFields.categoriesType.outdoor'),
  } as const,
];
export const SplitOptions = [
  {
    value: FacilityCategory.Yes,
    label: t('facilities.formFields.splittype.yes'),
  } as const,
  {
    value: FacilityCategory.No,
    label: t('facilities.formFields.splittype.no'),
  } as const,
];

export const sportTypeOptions = [
  {
    value: SportType.courtBased,
    label: t('fields.formFields.sportType.courtBased'),
  } as const,
  {
    value: SportType.laneBased,
    label: t('fields.formFields.sportType.laneBased'),
  } as const,
];

export const fieldsFilterOptionsMap = (fields?: Field[]) => {
  if (!fields) return [];

  const fieldsOptions = fields.map((field) => ({
    label: field.name,
    value: field.id,
  }));

  return [...fieldsOptions];
};
