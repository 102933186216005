import { WithdrawViewModalReject } from 'features/withdraw/components/modal/withdraw-reject';
import { useNavigate, useParams } from 'react-router-dom';
import { ROUTES } from 'routes/constants';
// ../components/modal/withdraw-reject
export const WithdrawViewReject = () => {
  const navigate = useNavigate();
  const { id } = useParams();

  if (!id) return null;

  const closeModal = () => {
    navigate(ROUTES.WITHDRAW);
  };

  return (
    <WithdrawViewModalReject
      id={id}
      onClose={closeModal}
      onSuccess={closeModal}
    />
  );
};
