import { ContentFilters, ContentTopBar } from 'components';
import dayjs from 'dayjs';
import {
  UsersWithWithdrawFilterFormPaginated,
  UsersWithWithdrawFilterFormValues,
} from 'features/withdraw/components/users-with-wallet-filter-form/type';
import { UsersWithWithdrawFilterForm } from 'features/withdraw/components/users-with-wallet-filter-form/users-with-withdraw-filter-form';
import { UsersWithWithdrawTable } from 'features/withdraw/components/users-with-withdraw-table/users-with-withdraw-table';
import { useGetUsersWithWithdraw } from 'features/withdraw/use-cases/get-withdraw';
import { usePaginationParams } from 'hooks';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Outlet } from 'react-router-dom';
import { formatDate } from 'utils/date';
// ../components/users-with-withdraw-table/users-with-withdraw-table
// ../components/users-with-wallet-filter-form/users-with-withdraw-filter-form
// ../components/users-with-wallet-filter-form/type
// ../use-cases/get-withdraw
const initialFilterValues: UsersWithWithdrawFilterFormValues = {
  user_name: undefined,
  phone_number: undefined,
  refrence_no: undefined,
  status_id: undefined,
  remark: undefined,
  date: [dayjs(), dayjs()],
};

export const UsersWithWithdrawView = () => {
  const { t } = useTranslation();
  const [queryFilters, setQueryFilters] = useState<any>(initialFilterValues);
  const fromDate = queryFilters.date?.[0];
  const toDate = queryFilters.date?.[1];
  const { params, updateParams, resetPage } =
    usePaginationParams<UsersWithWithdrawFilterFormPaginated>({
      page: 1,
      size: 10,
      order_by_transaction: '-last_transaction_date',
      ...initialFilterValues,
    });

  const { data, isFetching } = useGetUsersWithWithdraw(
    {
      page: params.page,
      size: params.size,
      from_date: fromDate
        ? formatDate({
            date: dayjs(fromDate).startOf('day'),
            format: 'isoDate',
          })
        : undefined,
      to_date: toDate
        ? formatDate({
            date: dayjs(toDate).endOf('day'),
            format: 'isoDate',
          })
        : undefined,
      phone_number: queryFilters.phone_number,
      user_name: queryFilters.user_name,
      refrence_no: queryFilters.refrence_no,
      status_id: queryFilters.status_id,
    } as any,
    {
      retry: false,
    },
  );

  const handleFiltersChange = (values: UsersWithWithdrawFilterFormValues) => {
    updateParams(values);
    resetPage();
  };

  const formId = 'users-with-wallet-filters-form';

  return (
    <>
      <ContentTopBar title={t('withdraw.heading')} />

      <ContentFilters formId={formId}>
        <UsersWithWithdrawFilterForm
          formId={formId}
          setQueryFilters={setQueryFilters}
          handleFilter={handleFiltersChange}
          initialValues={initialFilterValues}
        />
      </ContentFilters>

      <UsersWithWithdrawTable
        isLoading={isFetching}
        data={data}
        tableParams={params}
        updateParams={updateParams}
      />

      <Outlet />
    </>
  );
};
