// import { ReportApiService } from 'features/reports';
import React from 'react';

interface allbookingStatsProps {
  // date:any;
  // company_type?:any;
  // company_id?: any;
  // facility_id?: any;
  data?: any;
}
const AllBookingStatsComponent: React.FC<allbookingStatsProps> = ({
  // date,
  // company_type,
  // company_id,
  // facility_id,
  data,
}) => {
  // const { getAllBookingStats } = ReportApiService();

  // const useGetAllBookingStats = () => {
  //   const { message } = App.useApp();

  //   return useQuery<AllBookingStatsResponse, Error>(
  //     reportsQueryKeys.list({}),
  //     () => getAllBookingStats(),
  //     {
  //       onError: (error: unknown) => {
  //         const uiMessage = handleError(error);
  //         message.error(uiMessage);
  //       },
  //     },
  //   );
  // };
  // const useGetAllBookingStats = (
  //   params?: AllBookingStatsParams | undefined,
  //   options?: UseQueryOptions<DayViewStatsResponse, Error>,
  // ) => {
  //   const { message } = App.useApp();
  //   return useQuery<AllBookingStatsResponse, Error>(
  //     reportsQueryKeys.list(params || {}),
  //     ({ signal }) => getAllBookingStats(params || {}, { config: { signal } }),
  //     {
  //       onError: (error: unknown) => {
  //         const uiMessage = handleError(error);
  //         message.error(uiMessage);
  //       },
  //       ...options,
  //     },
  //   );
  // };

  // Call the useGetDayViewStats hook directly within the component
  //const { data, isLoading, isError, error }: UseQueryResult<AllBookingStatsResponse, Error> = useGetAllBookingStats();

  // const { data }: UseQueryResult<AllBookingStatsResponse, Error> =
  //   useGetAllBookingStats({
  //     from_date:(date? date[0]?.toISOString() :null)  ,
  //     to_date:date? date[1]?.toISOString() :null ,
  //     // from_date: initialFilterValues.date[0].toISOString(),
  //     // to_date:  initialFilterValues.date[1].toISOString(),
  //     company_type: company_type === undefined ? null   : company_type,
  //     company_id: company_id === undefined ? null : company_id,
  //     facility_id: facility_id === undefined ? null : facility_id,

  //   });

  return (
    <>
      <div className='stats_detls'>
        <div className='row'>
          <div className='col'>
            <div className='stats_bx'>
              <span>Today</span>
              {data === null || data === undefined ? (
                <div className='loader'></div>
              ) : (
                <h4>{data?.totalTodaysBooking}</h4>
              )}
            </div>
          </div>
          <div className='col'>
            <div className='stats_bx'>
              <span>Upcoming</span>
              {data === null || data === undefined ? (
                <div className='loader'></div>
              ) : (
                <h4>{data?.totalUpcomingBooking}</h4>
              )}
            </div>
          </div>
          <div className='col'>
            <div className='stats_bx'>
              <span>Completed</span>
              {data === null || data === undefined ? (
                <div className='loader'></div>
              ) : (
                <h4>{data?.totalCompletedBooking}</h4>
              )}
            </div>
          </div>
          <div className='col'>
            <div className='stats_bx'>
              <span>Cancelled</span>
              {data === null || data === undefined ? (
                <div className='loader'></div>
              ) : (
                <h4>{data?.totalCancelledBooking}</h4>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AllBookingStatsComponent;
