import dayjs from 'dayjs';
import { SocialListTable } from 'features/social-match/components/list/list-table';
import { SocailListTopBar } from 'features/social-match/components/top-bar/list-top-bar';
import { useGetSocial } from 'features/social-match/use-cases/get-socials';
import { useCallback, useState } from 'react';
import { Outlet } from 'react-router-dom';
import { formatDate } from 'utils/date';
// ../use-cases/get-socials
const getInitialFilterValues: any = {
  field_name: undefined,
  phone_number: undefined,
  date: [dayjs(), dayjs()],
  email: undefined,
  company_id: undefined,
};
// interface TableParams {
//   pagination?: TablePaginationConfig;
//   field?: string;
//   order?: string;
//   filters?: Record<string, FilterValue>;
// }
// const preparePayload = (params: any) => {
//   const createdAtFrom = params.date?.[0];
//   const createdAtTo = params.date?.[1];

//   return {
//     ...omit(params, ['date']),
//     created_at_from: createdAtFrom
//       ? formatDate({
//         date: dayjs(createdAtFrom).startOf('day'),
//         format: 'dbDate',
//       })
//       : undefined,
//     created_at_to: createdAtTo
//       ? formatDate({
//         date: dayjs(createdAtTo).endOf('day'),
//         format: 'dbDate',
//       })
//       : undefined,
//   };
// };

export const SocialListView = () => {
  // const { params, updateParams, resetPage } = usePaginationParams<any>({
  //   page: 1,
  //   size: 10,
  //   ...getInitialFilterValues,
  // });
  const [queryFilters, setQueryFilters] = useState<any>(getInitialFilterValues);
  const [tableParams, setTableParams] = useState<any>({
    pagination: {
      current: 1,
      pageSize: 10,
    },
  });
  const fromDate = queryFilters.date?.[0];
  const toDate = queryFilters.date?.[1];
  const { data, isFetching } = useGetSocial(
    {
      // created_at_from: fromDate ? moment(fromDate).format('YYYY-MM-DD') : undefined,
      // created_at_to: toDate
      //   ? moment(toDate).format('YYYY-MM-DD')
      //   : undefined,
      created_at_from: fromDate
        ? formatDate({
            date: dayjs(fromDate).startOf('day'),
            format: 'isoDate',
          })
        : undefined,
      created_at_to: toDate
        ? formatDate({
            date: dayjs(toDate).endOf('day'),
            format: 'isoDate',
          })
        : undefined,
      size: tableParams.pagination?.pageSize,
      page: tableParams.pagination?.current,
      field_name: queryFilters.field_name,
      match_type: queryFilters.match_type,
      match_status_id: queryFilters.match_status_id,
    },
    {
      retry: false,
    },
  );
  const resetTableCurrentPage = useCallback(() => {
    setTableParams((prevTableParams: any) => ({
      ...prevTableParams,
      pagination: {
        ...prevTableParams.pagination,
        current: 1,
      },
    }));
  }, []);

  return (
    <>
      <SocailListTopBar
        getInitialFilterValues={getInitialFilterValues}
        setQueryFilters={setQueryFilters}
        resetTableCurrentPage={resetTableCurrentPage}
      />
      <SocialListTable
        isSocialListLoading={isFetching}
        socialList={data as any}
        // tableParams={params as any}
        // updateParams={updateParams}
        tableParams={tableParams as any}
        setTableParams={setTableParams as any}
      />
      <Outlet />
    </>
  );
};
