import { Grid, Image, Space, Switch, TablePaginationConfig } from 'antd';
import { FilterValue, SorterResult } from 'antd/es/table/interface';
import { Column, RecordCountBadge, Table } from 'components';
import {
  StyledTableHeader,
  StyledTableWrapper,
} from 'components/table/table.styled';
import { CommunityFilterFormPaginated } from 'features/community/components/groups-filter-form/type';
import defaultimg from 'features/community/components/image/default.svg';
import { useUpdateStatusCommunity } from 'features/community/use-cases/update-statuscommunity';
import { usePermissions } from 'features/users';
import { Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import { Package } from 'types/package';
import { UserWithithdraw } from 'types/withdraw';
const { useBreakpoint } = Grid;
// ../groups-filter-form/type
type UsersWithWalletTableProps = {
  isLoading: boolean;
  data?: any;
  tableParams: CommunityFilterFormPaginated;
  updateParams: Dispatch<SetStateAction<CommunityFilterFormPaginated>>;
};

export const GroupsViewTable = ({
  data,
  isLoading,
  tableParams,
  updateParams,
}: UsersWithWalletTableProps) => {
  const { t } = useTranslation();
  const screens = useBreakpoint();
  const permissions = usePermissions();
  const { mutate: mutateUpdateCommunity, isLoading: isLoadingUpdatePackage } =
    useUpdateStatusCommunity();
  if (!permissions) return null;

  const handleTableChange = (
    pagination: TablePaginationConfig,
    filters: Record<string, FilterValue | null>,
    sorter: SorterResult<UserWithithdraw> | SorterResult<UserWithithdraw>[],
  ) => {
    const sortBy = () => {
      if (Array.isArray(sorter)) return;

      const sortOrder =
        sorter.order === 'ascend' ? `${sorter.field}` : `-${sorter.field}`;

      if (sorter?.field === 'last_transaction_date') {
        return {
          order_by_balance: undefined,
          order_by_transaction: sortOrder,
        };
      }
    };

    updateParams({
      ...tableParams,
      page: pagination.current,
      size: pagination.pageSize,
      ...sortBy(),
    });
  };
  // const tableWidth = screens.xxl ? { x: 1440 } : { x: 1100 };

  return (
    <StyledTableWrapper>
      <StyledTableHeader>
        <RecordCountBadge
          text={t('community.founduser')}
          count={data?.total || 0}
        />
      </StyledTableHeader>
      <Table<Package>
        loading={isLoading}
        dataSource={data?.items}
        rowKey='id'
        className='grpview-table'
        onChange={handleTableChange}
        pagination={{
          current: tableParams.page,
          defaultPageSize: tableParams.size,
          total: data?.total,
        }}
        // onRow={(record: any) => {
        //   return {
        //     className: record.status_id !== 1 ? 'clickable-row' : '',
        //     onClick: () => {
        //       navigate(getRoute(ROUTES.PACKAGE_EDIT, record.id));
        //     },
        //   };
        // }}
      >
        <Column<UserWithithdraw>
          title={t('community.table.Image')}
          dataIndex={'group_image'}
          width={screens.xxl ? 'auto' : 300}
          render={(_, record: any) => (
            <Image
              src={record.group_image || defaultimg}
              width={50}
              height={50}
              style={{ borderRadius: '50%' }}
            />
          )}
        />
        <Column
          title={t('community.table.Groupname')}
          dataIndex={'group_name'}
          width={screens.xxl ? 'auto' : 300}
          render={(_, record: any) => record.group_name}
        />

        <Column<UserWithithdraw>
          title={'Group Admin'}
          dataIndex={'group_owner_name'}
          width={screens.xxl ? 'auto' : 300}
          render={(_, record: any) => (
            <Space>
              {record.group_owner_name}
              {record.group_owner_phone}
            </Space>
          )}
        />
        <Column<UserWithithdraw>
          title={'Group Type'}
          dataIndex={'group_type'}
          width={screens.xxl ? 'auto' : 300}
          render={(_, record: any) => <Space>{record.group_type}</Space>}
        />
        <Column<UserWithithdraw>
          title={t('package.table.action')}
          dataIndex={'last_transaction_date'}
          render={(_, record: any) => (
            <Space>
              <Switch
                // disabled={!permissions?.facilities.edit}
                defaultChecked={record.status_id}
                checked={record.status_id}
                loading={isLoadingUpdatePackage}
                onChange={(isChecked: any) => {
                  mutateUpdateCommunity({
                    group_id: record.group_id,
                    status_id: isChecked == 0 ? 0 : 1,
                  });
                  // Update the is_active status locally
                  record.status_id = isChecked;
                }}
                onClick={(checked, event) => {
                  event.stopPropagation();
                  event.preventDefault();
                }}
              />
            </Space>
          )}
        />
      </Table>
    </StyledTableWrapper>
  );
};
