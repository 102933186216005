import { apiClient, ApiClientConfig, PaginatedRequestParams } from 'api';
export type CreateLabelDTO = {
  title?: string;
  description?: string | null;
};
export type UpdateLabelDTO = {
  id?: string;
  title?: string;
  description?: string;
  title_arabic?: string;
  description_arabic?: string;
};
export type UpdateTermDTO = {
  id?: string;
  content_english?: string;
  content_arabic?: string;
};
export const CommunityApiService = () => {
  const baseURL = process.env.REACT_APP_BASE_V2_URL;

  const getGroupTerm = async (
    params: PaginatedRequestParams,
    callConfig?: Partial<ApiClientConfig>,
  ) => {
    const response = await apiClient<any>({
      ...callConfig,
      endpoint: `${baseURL}/admin/Community/getgrouptermsnconditions`,
      params,
    });

    return response.data;
  };

  const getGroupCommunity = async (
    params: PaginatedRequestParams,
    callConfig?: Partial<ApiClientConfig>,
  ) => {
    const response = await apiClient<any>({
      ...callConfig,
      endpoint: `${baseURL}/admin/community/getgroups`,
      params,
    });

    return response.data;
  };
  const getReportdetails = async (
    id: string,
    callConfig?: Partial<ApiClientConfig>,
  ) => {
    const response = await apiClient<any>({
      ...callConfig,
      endpoint: `${baseURL}/admin/CommunityPost/GetPostReportDetails?${id}`,
    });

    return response.data;
  };
  const getGroupReport = async (
    params: PaginatedRequestParams,
    callConfig?: Partial<ApiClientConfig>,
  ) => {
    const response = await apiClient<any>({
      ...callConfig,
      endpoint: `${baseURL}/admin/CommunityPost/GetReportPost`,
      params,
    });

    return response.data;
  };
  const updateStatusCommunity = async (
    payload: any,
    callConfig?: Partial<ApiClientConfig>,
  ) => {
    const { id, ...data } = payload;

    const response = await apiClient<any>({
      ...callConfig,
      endpoint: `${baseURL}/admin/Community/updatestatus`,
      method: 'POST',
      data,
    });

    return response.data;
  };
  const createTerm = async (payload: any) => {
    const response = await apiClient({
      endpoint: `${baseURL}/admin/Community/setgrouptermsnconditions`,
      method: 'POST',
      data: payload,
    });

    return response.data;
  };
  const updateTerm = async (
    payload: UpdateTermDTO,
    callConfig?: Partial<ApiClientConfig>,
  ) => {
    const { id, ...data } = payload;

    const response = await apiClient<any>({
      ...callConfig,
      endpoint: `${baseURL}/admin/Community/setgrouptermsnconditions`,
      method: 'POST',
      data: {
        id,
        ...data, // Include other data from the payload
      },
    });

    return response.data;
  };
  const reportDelete = async (
    payload: any,
    callConfig?: Partial<ApiClientConfig>,
  ) => {
    const { id, ...data } = payload;
    const response = await apiClient<any>({
      ...callConfig,
      endpoint: `${baseURL}/admin/CommunityPost/DeletePost`,
      method: 'DELETE',
      data,
    });

    return response.data;
  };

  return {
    getGroupTerm,
    updateTerm,
    createTerm,
    getGroupCommunity,
    getGroupReport,
    getReportdetails,
    updateStatusCommunity,
    reportDelete,
  };
};
