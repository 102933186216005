import { InboxOutlined } from '@ant-design/icons';
import {
  Button,
  Col,
  Form,
  Grid,
  Input,
  InputNumber,
  Radio,
  Row,
  Select,
  TimePicker,
  Upload,
} from 'antd';
import { RcFile } from 'antd/es/upload';
import ImgCrop from 'antd-img-crop';
import { RouteLeavingGuard } from 'components/route-leaving-guard/route-leaving-guard';
import { AmenitiesSelect } from 'features/amenities/components/amenities-select/amenities-select';
import { CompanySelect } from 'features/companies/components/company-select/company-select';
import { FacilitySelect } from 'features/facilities/components/facility-select/facility-select';
import { useGetFacility } from 'features/facilities/use-cases/get-facility';
import {
  FieldDetailsField,
  FieldDetailsFormValues,
  multiPleExceptionalTimeRange,
} from 'features/fields/components/add-field-form/types';
import { ExceptionTimePriceInput } from 'features/fields/components/time-price-input/exception-time-price-input';
import { TimePriceInput } from 'features/fields/components/time-price-input/time-price-input';
import {
  genderOptions,
  getTeamSizeOptions,
  sportTypeOptions,
  timeSlotsOptions,
  typeOptions,
} from 'features/fields/utils';
import { SportSelect } from 'features/sports';
import { useUploadProps } from 'hooks/use-upload-props';
import { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { getDisabledMinutes } from 'utils/date';

const { useBreakpoint } = Grid;
const { Dragger } = Upload;

type AddFieldFormProps = {
  handleCreate: (object: FieldDetailsFormValues, FileList: RcFile[]) => void;
  disabled: boolean;
};

export const AddFieldForm = ({ handleCreate, disabled }: AddFieldFormProps) => {
  const { t } = useTranslation();
  const screens = useBreakpoint();
  const { fileList, uploadProps } = useUploadProps();
  const [form] = Form.useForm<FieldDetailsFormValues>();
  const [timePriceError, setTimePriceError] = useState<boolean>(false);
  const [isFormDirty, setIsFormDirty] = useState(false);
  const [validateTrigger, setValidateTrigger] = useState(['onSubmit']);
  // type chipStateT = {
  //   label: string;
  // };
  // const [excepDays, setExcepDays] = useState<chipStateT[] | undefined>(
  //   undefined,
  // );
  // const [excheptionalDate, setExcheptionalDate] = useState('');

  const multiExceptionalValue_ = [
    {
      exceptionDays: [],
      exceptionTimeRange: [],
      exceptionWeekDays: [],
    },
  ];

  const [mulitExcepTimeRange_, setMulitExcepTimeRange_] = useState<
    multiPleExceptionalTimeRange[]
  >(multiExceptionalValue_);

  const getInitialValues = {
    [FieldDetailsField.Company]: undefined,
    [FieldDetailsField.Facility]: undefined,
    [FieldDetailsField.Name]: '',
    [FieldDetailsField.NameArabic]: '',
    [FieldDetailsField.Area]: '',
    [FieldDetailsField.Geolocation]: '',
    [FieldDetailsField.TimeSlot]: undefined,
    [FieldDetailsField.OpeningHours]: [],
    [FieldDetailsField.TimePrice]: undefined,
    [FieldDetailsField.TeamSize]: undefined,
    [FieldDetailsField.Sports]: undefined,
    [FieldDetailsField.Gender]: 'all',
    [FieldDetailsField.FreeAmenities]: [],
    [FieldDetailsField.ChargeableAmenities]: [],
    [FieldDetailsField.CategoriesType]: '',
    [FieldDetailsField.Type]: '',
    [FieldDetailsField.Width]: '',
    [FieldDetailsField.Length]: '',
    [FieldDetailsField.ExceptionTimePrice]: multiExceptionalValue_,
  };

  const companyId = Form.useWatch(FieldDetailsField.Company, form);
  const facilityId = Form.useWatch(FieldDetailsField.Facility, form);
  const timeSlot = Form.useWatch(FieldDetailsField.TimeSlot, form);
  const openingHours = Form.useWatch(FieldDetailsField.OpeningHours, form);

  const { data: facilityData } = useGetFacility(facilityId, {
    enabled: !!facilityId,
    retry: false,
    cacheTime: 0, //workaround to invalidate initialValues of client data form
  });

  useEffect(() => {
    const composeGeolocation = `${facilityData?.geolocation.lat}, ${facilityData?.geolocation.lon}`;

    return (
      facilityData &&
      form.setFieldsValue({
        area: facilityData?.area.name,
        geolocation: composeGeolocation,
      })
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [facilityData]);

  useEffect(() => {
    if (fileList.length) setIsFormDirty(true);
  }, [fileList]);

  const formItemLayout = screens.lg
    ? { labelCol: { span: 7 }, wrapperCol: { span: 10 } }
    : null;

  const handleResetFields = () => {
    form.setFieldsValue({
      [FieldDetailsField.Facility]: '',
      [FieldDetailsField.Area]: '',
      [FieldDetailsField.Geolocation]: '',
    });
  };

  const handleSubmit = (formValues: FieldDetailsFormValues) => {
    formValues.exception_time_price = mulitExcepTimeRange_;
    setIsFormDirty(false);
    handleCreate(formValues, fileList as RcFile[]);
  };

  // const updateExcepChips = (value: TimePrice) => {
  //   // console.log('value'+JSON.stringify(value));
  //   // debugger ;
  //   if (!value) return;
  //   const { exception_days: exceptionDaysRange } = value;
  //   const { exception_days_list: exceptionDaysList } = value;

  //   if (exceptionDaysList) {
  //     const exceptionDayList = `${dayjs(exceptionDaysList[0]).format(
  //       'YYYY-MM-DD',
  //     )} - ${dayjs(exceptionDaysList[1]).format('YYYY-MM-DD')}`;
  //     setExcheptionalDate(exceptionDayList);
  //   }

  //   if (exceptionDaysRange) {
  //     const chipsExcepValues = exceptionDaysRange.map(
  //       ({ exception_days_range, exception_days_slot_price }) => ({
  //         label: `${dayjs(exception_days_range[0]).format('hh:mm A')} - ${dayjs(
  //           exception_days_range[1],
  //         ).format('hh:mm A')} | ${exception_days_slot_price} KWD`,
  //       }),
  //     );

  //     setExcepDays(chipsExcepValues);
  //   }
  // };

  return (
    <>
      {/* <div className='packForm'>
        <div className='warp_inner'> */}
      <Form
        {...formItemLayout}
        id='field-create-form'
        name='field-create-form'
        form={form}
        layout={screens.lg ? 'horizontal' : 'vertical'}
        initialValues={getInitialValues}
        onFinish={(formValues) => handleSubmit(formValues)}
        onFinishFailed={() => {
          setValidateTrigger(['onChange']);
        }}
        onFieldsChange={() => {
          setIsFormDirty(true);
          // console.log('exceptionTimePriceInput'+JSON.stringify(value));
          // if (value[0].name[0] === 'exception_time_price') {
          //   updateExcepChips(value[0].value);
          //   //console.log('ExceptionalDays'+JSON.stringify(value[0].value));
          // }
        }}
        validateTrigger={validateTrigger}
        disabled={disabled}
        requiredMark={true}
      >
        {/* <Row> */}
        {/* <Col span={8}> */}
        <Form.Item<FieldDetailsField>
          label={t('fields.formFields.company.label')}
          name={FieldDetailsField.Company}
          rules={[
            {
              required: true,
              whitespace: true,
            },
          ]}
        >
          <CompanySelect
            allowClear={false}
            onChange={handleResetFields}
            size='large'
          />
        </Form.Item>
        {/* </Col> */}
        {/* <Col span={8}> */}
        <Form.Item<FieldDetailsField>
          label={t('fields.formFields.facility.label')}
          name={FieldDetailsField.Facility}
          rules={[
            {
              required: true,
              whitespace: true,
            },
          ]}
        >
          <FacilitySelect
            allowClear={false}
            allowPullAll={false}
            disabled={!companyId}
            companyId={companyId ? companyId : undefined}
            size='large'
          />
        </Form.Item>
        {/* </Col> */}
        {/* <Col span={8}> */}
        <Form.Item<FieldDetailsField>
          label={t('fields.formFields.area.label')}
          name={FieldDetailsField.Area}
          rules={[
            {
              required: true,
              whitespace: true,
            },
          ]}
        >
          <Input
            disabled
            placeholder={t('fields.formFields.area.placeholder')}
            size='large'
          />
        </Form.Item>
        {/* </Col> */}
        {/* <Col span={8}> */}
        <Form.Item<FieldDetailsField>
          label={t('fields.formFields.name.label')}
          name={FieldDetailsField.Name}
          rules={[
            {
              required: true,
              whitespace: true,
            },
          ]}
        >
          <Input
            placeholder={t('fields.formFields.name.placeholder')}
            size='large'
          />
        </Form.Item>
        {/* </Col> */}
        {/* <Col span={8}> */}
        <Form.Item<FieldDetailsField>
          label={t('fields.formFields.nameArabic.label')}
          name={FieldDetailsField.NameArabic}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input
            placeholder={t('fields.formFields.nameArabic.placeholder')}
            dir='rtl'
            size='large'
          />
        </Form.Item>
        {/* </Col> */}
        {/* <Col span={8}> */}
        <Form.Item<FieldDetailsField>
          label={t('fields.formFields.geolocation.label')}
          name={FieldDetailsField.Geolocation}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input
            disabled
            placeholder={t('fields.formFields.geolocation.placeholder')}
            size='large'
          />
        </Form.Item>
        {/* </Col> */}

        {/* <Col span={8}> */}
        <Form.Item<FieldDetailsField>
          label={t('fields.formFields.teamSize.label')}
          name={FieldDetailsField.TeamSize}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Select
            placeholder={t('fields.formFields.teamSize.placeholder')}
            options={getTeamSizeOptions()}
            allowClear
            size='large'
          />
        </Form.Item>
        {/* </Col> */}
        <Form.Item<FieldDetailsField>
          label={t('fields.formFields.timeSlot.label')}
          name={FieldDetailsField.TimeSlot}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Select
            placeholder={t('fields.formFields.timeSlot.placeholder')}
            options={timeSlotsOptions}
            allowClear
            size='large'
          />
        </Form.Item>
        {timeSlot && Object.keys(timeSlot)?.length > 0 ? (
          <Form.Item<FieldDetailsField>
            label={t('fields.formFields.timePrice.label')}
            name={FieldDetailsField.TimePrice}
            rules={[
              {
                required: true,
              },
              // () => ({
              //   validator(_, value) {
              //     if (value) {
              //       setTimePriceError(false);

              //       return Promise.resolve();
              //     }
              //     setTimePriceError(true);

              //     return Promise.reject();
              //   },
              () => ({
                validator(_, value) {
                  if (value) {
                    setTimePriceError(false);

                    return Promise.resolve();
                  }
                  setTimePriceError(true);

                  return Promise.reject();
                },
              }),
            ]}
          >
            <TimePriceInput
              error={timePriceError}
              timeSlot={timeSlot}
              openingHours={openingHours}
            />
          </Form.Item>
        ) : null}
        {timeSlot && Object.keys(timeSlot)?.length > 0 ? (
          <Form.Item<FieldDetailsField>
            label={t('fields.formFields.excepTimePrice.label')}
            name={FieldDetailsField.ExceptionTimePrice}
            rules={[
              {
                required: true,
              },
              () => ({
                validator(_, value) {
                  if (value) {
                    setTimePriceError(false);

                    return Promise.resolve();
                  }
                  setTimePriceError(true);

                  return Promise.reject();
                },
              }),
            ]}
          >
            <ExceptionTimePriceInput
              error={timePriceError}
              timeSlot={timeSlot}
              setMulitExcepTimeRange={setMulitExcepTimeRange_}
              mulitExcepTimeRange={mulitExcepTimeRange_}
            />
          </Form.Item>
        ) : null}
        {/* <Col span={8}> */}
        <Form.Item<FieldDetailsField>
          label={t('fields.formFields.sport.label')}
          name={FieldDetailsField.Sports}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <SportSelect
            mode='multiple'
            size='large'
            customParams={{ is_active: true }}
          />
        </Form.Item>
        {/* </Col> */}
        {/* <Col span={8}> */}
        <Form.Item<FieldDetailsField>
          label={t('fields.formFields.freeAmenities.label')}
          name={FieldDetailsField.FreeAmenities}
        >
          <AmenitiesSelect
            size='large'
            mode='multiple'
            customParams={{ chargeable: false, is_active: true }}
          />
        </Form.Item>
        {/* </Col> */}
        {/* <Col span={8}> */}
        <Form.Item<FieldDetailsField>
          label={t('fields.formFields.chargeableAmenities.label')}
          name={FieldDetailsField.ChargeableAmenities}
        >
          <AmenitiesSelect
            size='large'
            mode='multiple'
            customParams={{ chargeable: true, is_active: true }}
          />
        </Form.Item>
        {/* </Col> */}
        {/* <Col span={8}> */}
        <Form.Item<FieldDetailsField>
          label={t('fields.formFields.gender.label')}
          name={FieldDetailsField.Gender}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Select
            placeholder={t('fields.formFields.gender.placeholder')}
            options={genderOptions}
            allowClear
            size='large'
          />
        </Form.Item>
        {/* </Col> */}
        {/* <Col span={8}> */}
        <Form.Item<FieldDetailsField>
          label={t('fields.formFields.width.label')}
          name={FieldDetailsField.Width}
          rules={[
            {
              required: true,
              validator: (_, value) => {
                if (value === 0) {
                  return Promise.reject(new Error('Width cannot be zero'));
                }

                return Promise.resolve();
              },
            },
          ]}
        >
          <InputNumber
            style={{ width: '100%' }}
            min={0}
            placeholder={t('fields.formFields.width.placeholder')}
            size='large'
            defaultValue={0}
          />
        </Form.Item>
        {/* </Col> */}
        {/* <Col span={8}> */}
        <Form.Item<FieldDetailsField>
          label={t('fields.formFields.length.label')}
          name={FieldDetailsField.Length}
          rules={[
            {
              required: true,
              validator: (_, value) => {
                if (value === 0) {
                  return Promise.reject(new Error('length cannot be zero'));
                }

                return Promise.resolve();
              },
            },
          ]}
        >
          <InputNumber
            style={{ width: '100%' }}
            min={0}
            placeholder={t('fields.formFields.length.placeholder')}
            size='large'
            defaultValue={0}
          />
        </Form.Item>
        {/* </Col> */}
        {/* <Col span={8}> */}
        <Form.Item<FieldDetailsField>
          label={t('fields.formFields.categoriesType.label')}
          name={FieldDetailsField.CategoriesType}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Radio.Group>
            {typeOptions.map((option) => (
              <Radio value={option.value} key={option.value}>
                {option.label}
              </Radio>
            ))}
          </Radio.Group>
        </Form.Item>
        {/* </Col> */}
        {/* <Col span={8}> */}
        <Form.Item<FieldDetailsField>
          label={t('fields.formFields.sportType.label')}
          name={FieldDetailsField.Type}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Radio.Group>
            {sportTypeOptions.map((option) => (
              <Radio value={option.value} key={option.value}>
                {option.label}
              </Radio>
            ))}
          </Radio.Group>
        </Form.Item>
        {/* </Col> */}
        {/* <Col span={8}> */}
        <Form.Item
          label={t('fields.formFields.coverImage.label')}
          getValueFromEvent={(event) => event?.fileList}
          rules={[
            {
              required: true,
            },
          ]}
        >
          {fileList.length ? (
            <Upload
              {...uploadProps}
              name='file'
              accept='.png, .jpg, .jpeg'
              maxCount={1}
              listType='picture-card'
            />
          ) : (
            <ImgCrop rotationSlider aspect={2}>
              <Dragger {...uploadProps} multiple={false}>
                <p className='ant-upload-drag-icon'>
                  <InboxOutlined />
                </p>
                <p className='ant-upload-text'>{t('upload.dragOrClick')}</p>
                <p className='ant-upload-hint'>
                  <Trans i18nKey='upload.hint' components={{ br: <br /> }} />
                </p>
              </Dragger>
            </ImgCrop>
          )}
        </Form.Item>
        {/* </Col> */}
        {/* <Col span={8}> */}

        {/* </Col> */}
        {/* <Col span={8}> */}
        <Form.Item<FieldDetailsField>
          label={t('fields.formFields.openingHours.label')}
          name={FieldDetailsField.OpeningHours}
          style={{ display: 'none' }}
          rules={[
            {
              required: false,
            },
          ]}
          wrapperCol={{ lg: 10, xs: 24 }}
        >
          <TimePicker.RangePicker
            size='large'
            style={{ width: '100%' }}
            format='h mm A'
            showSecond={false}
            inputReadOnly={true}
            hideDisabledOptions={true}
            disabledTime={getDisabledMinutes}
            order={false}
          />
        </Form.Item>

        {/* </Col> */}
        {/* </Row> */}

        {/* {timeSlot &&
        openingHours &&
        excheptionalDate &&
        Object.keys(openingHours)?.length > 0 ? (
          <Form.Item<FieldDetailsField>
            label='Exception Days'
            name='ExceptionDate'
          >
            <TimePriceInputWrapper error={false}>
              <TimePriceInputChip>
                {excheptionalDate ? excheptionalDate : ''}
              </TimePriceInputChip>
            </TimePriceInputWrapper>
          </Form.Item>
        ) : null}
        {timeSlot &&
        openingHours &&
        excheptionalDate &&
        Object.keys(openingHours)?.length > 0 ? (
          <Form.Item<FieldDetailsField>
            label='Exception - price'
            name='ExceptionDay'
          >
            <TimePriceInputWrapper error={false}>
              {excepDays && excepDays?.length > 0
                ? excepDays.map((exchipsq: chipStateT) => (
                    <TimePriceInputChip key={exchipsq?.label}>
                      {exchipsq?.label}
                    </TimePriceInputChip>
                  ))
                : null}
            </TimePriceInputWrapper>
          </Form.Item>
        ) : null} */}
        <Col span={screens.lg ? 24 : 'auto'} offset={screens.lg ? 7 : 0}>
          <Row justify='end'>
            <Button disabled={disabled} type='primary' htmlType='submit'>
              {t('fields.buttons.addField')}
            </Button>
          </Row>
        </Col>
      </Form>
      {/* </div>
      </div> */}
      <RouteLeavingGuard blocked={isFormDirty} />
    </>
  );
};
