import { RcFile } from 'antd/es/upload';
import { Button, ContentTopBar } from 'components';
import { StyledContentBackground } from 'components/content-full-screen/content-full-screen';
import dayjs from 'dayjs';
import {
  AddFieldForm,
  FieldDetailsFormValues,
  useCreateField,
} from 'features/fields';
import { useUploadFieldCoverImage } from 'features/fields/use-cases/upload-field-image';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from 'routes/constants';
import { Field, FieldCategory } from 'types';
type AddFieldProps = {
  onCancel: () => void;
  onSuccess: () => void;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const formatTimeRange = (timerange: any) => {
  const format = 'HH:mm:ss';
  const time_ranges = timerange.all_days?.map((range: any) => ({
    start_time: dayjs(range?.all_days_range[0]).second(0).format(format),
    end_time: dayjs(range?.all_days_range[1]).second(0).format(format),
    slot_price: range?.all_days_slot_price,
  }));

  return time_ranges;
};

const formatExcepTimeRange = (timerange: any) => {
  const format = 'HH:mm:ss';
  const response: any = [];
  if (timerange !== undefined && timerange.length > 0) {
    timerange.forEach((value: any) => {
      const exceptionDate = value.exceptionDays;
      const extimeRange = value.exceptionTimeRange?.map((range: any) => ({
        start_time: dayjs(range?.exception_days_range[0])
          .second(0)
          .format(format),
        end_time: dayjs(range?.exception_days_range[1])
          .second(0)
          .format(format),
        slot_price: range?.exception_days_slot_price,
      }));
      const weekDays = value.exceptionWeekDays;
      const exceptionData_ = {
        // start_date: dayjs(exceptionDate ? exceptionDate[0] : ''),
        // end_date: dayjs(exceptionDate ? exceptionDate[1] : ''),
        start_date: dayjs(exceptionDate ? exceptionDate[0] : '').format(
          'YYYY-MM-DD',
        ),
        end_date: dayjs(exceptionDate ? exceptionDate[1] : '').format(
          'YYYY-MM-DD',
        ),
        weekdays: weekDays,
        time_ranges: extimeRange,
      };

      response.push(exceptionData_);
    });

    if (
      Object.keys(response[0].weekdays).length &&
      response[0].weekdays.length > 0
    ) {
      return response;
    } else {
      return [];
    }

    return response;
  } else {
    return null;
  }
};

const getPayload = (formValues: FieldDetailsFormValues) => {
  const {
    name,
    name_arabic,
    // opening_hours,
    rank,
    time_price,
    facility_id,
    max_players,
    sport_ids,
    time_slot,
    free_amenities,
    chargeable_amenities,
    categories_type,
    gender,
    type,
    width,
    length,
    exception_time_price,
  } = formValues;
  //

  const composeCategories = () => {
    const categories = [categories_type];

    if (
      gender === FieldCategory.FemaleOnly ||
      gender === FieldCategory.MaleOnly
    ) {
      return [...categories, gender];
    }

    return categories;
  };

  const format = 'HH:mm:ss';

  const firstRangeStartTime = dayjs(
    time_price?.all_days?.[0]?.all_days_range?.[0],
  )
    .second(0)
    .format(format);

  const firstRangeEndTime = dayjs(
    time_price?.all_days?.[0]?.all_days_range?.[1],
  )
    .second(0)
    .format(format);

  const firstRangeSlotPrice = time_price?.all_days?.[0]?.all_days_slot_price;

  let secondRangeStartTime;
  let secondRangeEndTime;
  let secondRangeSlotPrice;

  if (time_price?.all_days?.[1] !== undefined) {
    secondRangeStartTime = dayjs(time_price?.all_days?.[1]?.all_days_range?.[0])
      .second(0)
      .format(format);

    secondRangeEndTime = dayjs(time_price?.all_days?.[1]?.all_days_range?.[1])
      .second(0)
      .format(format);

    secondRangeSlotPrice = time_price?.all_days?.[1]?.all_days_slot_price;
  }

  const time_ranges = formatTimeRange(time_price);

  const exception_ranges = formatExcepTimeRange(exception_time_price);

  return {
    name,
    name_arabic,
    rank,
    facility_id,
    categories: composeCategories(),
    max_players,
    time_slot,
    sport_ids,
    // open_from: dayjs(opening_hours[0]).format(format),
    open_from: '00:00:00',
    open_to: '23:59:00',
    // open_to: dayjs(opening_hours[1]).format(format),
    first_range_start_time: firstRangeStartTime,
    first_range_end_time: firstRangeEndTime,
    first_range_slot_price: firstRangeSlotPrice,
    second_range_start_time: secondRangeStartTime,
    second_range_end_time: secondRangeEndTime,
    second_range_slot_price: secondRangeSlotPrice,
    price: Number(firstRangeSlotPrice),
    time_ranges,
    //amenity_ids: [...free_amenities, ...chargeable_amenities],
    amenity_ids: [...free_amenities],
    chargeable_amenity_ids: [...chargeable_amenities],
    type,
    width: width ? width : 0,
    length: length ? length : 0,
    exception_ranges,
  };
};

export const AddField = ({ onCancel, onSuccess }: AddFieldProps) => {
  const { t } = useTranslation();
  const { isLoading, mutate } = useCreateField();
  const { uploadImage } = useUploadFieldCoverImage();
  const navigate = useNavigate();

  const handleCreate = async (
    formValues: FieldDetailsFormValues,
    fileList: RcFile[],
  ) => {
    const payload = getPayload(formValues);

    mutate(payload, {
      onSuccess(data) {
        const { id } = data as Field;
        // Only call the image api if file is selected
        if (fileList.length > 0) {
          const formData = new FormData();
          formData.append('file', fileList[0] as RcFile);
          uploadImage(id, formData).finally(() => onSuccess());
        }
        navigate(ROUTES.FIELDS);
      },
    });
  };

  return (
    <>
      <ContentTopBar
        title={t('fields.addField.heading')}
        renderButtons={
          <Button onClick={onCancel}>{t('fields.buttons.cancel')}</Button>
        }
      />

      <StyledContentBackground>
        <AddFieldForm handleCreate={handleCreate} disabled={isLoading} />
      </StyledContentBackground>
    </>
  );
};
