import 'react-quill/dist/quill.snow.css';

import { Form, FormInstance, Input } from 'antd';
import TextArea from 'antd/es/input/TextArea';
import {
  BadgeFormDataFields,
  BadgeFormFields,
} from 'features/user-level/components/interface/type';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { FormSectionsSeparator } from './form-sections-separator';
export const BadgeDataInfoFormItems = ({
  form,
}: {
  form: FormInstance<BadgeFormDataFields>;
}) => {
  const { t } = useTranslation();
  useEffect(() => {
    form.getFieldValue('question');
  });

  return (
    <FormSectionsSeparator title={t('userLevel.Bedage.UpdateBadge')}>
      <Form.Item<BadgeFormDataFields>
        name={BadgeFormFields.title}
        label='Title in English'
        rules={[{ required: true, message: 'Please Enter Question' }]}
      >
        <Input placeholder='Enter your Question' />
      </Form.Item>
      <Form.Item<BadgeFormDataFields>
        name={BadgeFormFields.title_arabic}
        label='Title in Arabic'
        rules={[{ required: true, message: 'Please Enter Title in Arabic' }]}
      >
        <Input placeholder='Enter your Question' dir='rtl' />
      </Form.Item>
      <Form.Item<BadgeFormDataFields>
        name={BadgeFormFields.description}
        label='Description in English'
        rules={[{ required: true, message: 'Please Enter Description' }]}
      >
        {/* <ReactQuill
          theme='snow'
          className='text_Quill'
          style={{ height: '100px' }}
        /> */}
        <TextArea />
      </Form.Item>
      <div style={{ marginTop: '60px' }}>
        <Form.Item<BadgeFormDataFields>
          name={BadgeFormFields.descriptionArabic}
          label='Description in Arabic'
          rules={[{ required: true, message: 'Please Enter Description' }]}
        >
          {/* <ReactQuill
            theme='snow'
            className='text_Quill'
            style={{ height: '100px' }}
          /> */}
          <TextArea dir='rtl' />
        </Form.Item>
      </div>
    </FormSectionsSeparator>
  );
};
