import { useMutation } from '@tanstack/react-query';
import { App } from 'antd';
import { handleError } from 'api';
import {
  SocialApiService,
  updateSocialDTO,
} from 'features/social-match/api/social-api.service';
import { questionQueryKeys } from 'features/user-level/use-cases/query-keys';
import i18n from 'locales/i18n';
import { queryClient } from 'providers';
// ../api/social-api.service
const { updateSocial } = SocialApiService();

export const useUpdateSocial = () => {
  const { message } = App.useApp();

  return useMutation(
    (payload: updateSocialDTO) => {
      return updateSocial(payload as any);
    },
    {
      onError: (error) => {
        const uiMessage = handleError(error);

        message.error(uiMessage);
      },
      onSuccess: (data, variables) => {
        Promise.all([
          queryClient.invalidateQueries({
            queryKey: questionQueryKeys.lists(),
          }),
          queryClient.invalidateQueries({
            queryKey: questionQueryKeys.detail(variables.match_id as any),
          }),
        ]);
        message.success(i18n.t('userLevel.update.successfulUpdate'));
      },
    },
  );
};
