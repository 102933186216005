import { ContentTopBar } from 'components';
import { AddSocialLabelModal } from 'features/social-label/components/modal/add-social-label-modal';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CompanyType } from 'types';
export const DashBoardTopBar = () => {
  useState<CompanyType>();
  const { t } = useTranslation();
  const [showAddUserModal, setShowAddUserModal] = useState(false);

  return (
    <>
      <ContentTopBar
        title={t('rolemanagement.heading')}
        renderButtons={
          <>
            {/* <Button className='arr_btn'>
                            <LeftOutlined />
                        </Button> */}
            {showAddUserModal && (
              <AddSocialLabelModal onClose={() => setShowAddUserModal(false)} />
            )}
          </>
        }
      />
    </>
  );
};
