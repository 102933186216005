import { CloseOutlined, PlusOutlined } from '@ant-design/icons';
import { Form, Grid, Input, message, Modal, Row } from 'antd';
import { Button, ContentTopBar } from 'components';
import {
  CompaniesFilterFormPaginated,
  CompaniesFilterFormValues,
} from 'features/companies';
import { QuestionApiService } from 'features/user-level/api/questions-api.service';
import { UserLevelTable } from 'features/user-level/components/level/user-level';
// import { useCreateQuestion } from 'features/user-level/use-cases/create-question';
import { useGetQuestion } from 'features/user-level/use-cases/get-questions';
import { usePaginationParams } from 'hooks';
import { t } from 'i18next';
import { useState } from 'react';
import { Outlet } from 'react-router-dom';

const { useBreakpoint } = Grid;
const initialFilterValues: CompaniesFilterFormValues = {
  company_name: undefined,
  phone_number: undefined,
};
export const QuestionView = () => {
  const screens = useBreakpoint();
  const [modalOperation, setModalOperation] = useState('');
  // const { mutate } = useCreateQuestion();
  const [form] = Form.useForm();
  const { params } = usePaginationParams<CompaniesFilterFormPaginated>({
    page: 1,
    size: 10,
    ...initialFilterValues,
  });
  const [open, setOpen] = useState(false);
  const showModal = (e: any) => {
    setOpen(true);
    setModalOperation(e);
  };
  const { data } = useGetQuestion(params, {
    retry: false,
  });
  // const navigate = useNavigate()
  const handleCancel = () => {
    setOpen(false);
    form.resetFields();
  };
  // const getPayload = (formValues: CreateQuestionDTO) => {
  //   const { question, OptionList } = formValues;
  //   const payload = {
  //     question,
  //     OptionList: addSortOrderToOptions(OptionList),
  //   };

  //   return payload;
  // };
  // const addSortOrderToOptions = (options: any[]) => {
  //   return options.map((option, index) => ({
  //     ...option,
  //     sort_order: index + 1, // Assuming you want sort_order to start from 1
  //   }));
  // };

  // const handleSubmit = async (formValues: CreateQuestionDTO) => {
  //   const payload = getPayload(formValues);

  //   try {
  //     await mutate(payload, {
  //       onSuccess: () => {
  //         form.resetFields();
  //         setOpen(false);
  //       },
  //       onError: (error: any) => {
  //         if (error?.ResponseStatus === 2) {
  //           message.error(error.ResponseMessage);
  //         } else {
  //           message.error("An error occurred");
  //         }
  //       },
  //     });
  //   } catch (error) {
  //     message.error("An error occurred");
  //   }
  // };
  const handleSubmit = async (formValues: any) => {
    try {
      const apires: any = await QuestionApiService().createQuestion(formValues);
      setOpen(false);
      if (apires.ResponseMessage == 'Question already exists') {
        message.error(apires.ResponseMessage);
      } else {
        message.success('Question added successfully');
      }
    } catch (ResponseMessage: any) {
      message.error(ResponseMessage);
    }
    form.resetFields();
    setTimeout(() => {
      window.location.reload();
    }, 1000);
  };

  return (
    <>
      <ContentTopBar
        title={t('userLevel.heading')}
        renderButtons={
          <Button
            icon={!screens.xs ? <PlusOutlined /> : undefined}
            onClick={() => showModal('add')}
          >
            {t('userLevel.buttons.addQuestion')}
          </Button>
        }
      />
      <UserLevelTable
        // isLoading={isFetching}
        data={data}
        // tableParams={params}
        // updateParams={updateParams}
        // showModal={() => showModal('update')}
      />
      <Outlet />
      {/* Modal  */}
      <Modal
        className='right-modal'
        footer={null}
        centered={false}
        open={open}
        title={'Add Question'} // Set the title based on modalOperation
        onCancel={handleCancel}
      >
        <Form
          layout='vertical'
          form={form}
          onFinish={(values) => handleSubmit(values)}
        >
          <Form.Item
            name='question'
            label='Question in English'
            rules={[{ required: true, message: 'Please Enter Question' }]}
          >
            <Input placeholder='Enter your Question' />
          </Form.Item>
          <Form.Item
            name='question_arabic'
            label='Question in Arabic'
            rules={[{ required: true, message: 'Please Enter Question' }]}
          >
            <Input placeholder='Enter your Question' />
          </Form.Item>

          <Form.List name='OptionList' initialValue={[{}]}>
            {(fields, { add, remove }) => (
              <>
                {fields.map(({ key, name, ...restField }, index) => (
                  <div key={key} className='ques_card'>
                    <div className='head_bx'>
                      {index > 0 && (
                        <Row className='icon'>
                          <CloseOutlined onClick={() => remove(name)} />
                        </Row>
                      )}
                    </div>
                    <div className='option_ques_edit'>
                      <Row className='option_item'>
                        <Form.Item
                          {...restField}
                          name={[name, 'option_arabic']}
                          style={{ width: '100%' }}
                          label={`Option in English ${index + 1}`}
                          rules={[
                            { required: true, message: 'Enter your Option' },
                          ]}
                        >
                          <Input placeholder={`Enter your option`} />
                        </Form.Item>
                        <Form.Item
                          {...restField}
                          name={[name, 'option']}
                          style={{ width: '100%' }}
                          label={`Option in Arabic ${index + 1}`}
                          rules={[
                            { required: true, message: 'Enter your Option' },
                          ]}
                        >
                          <Input placeholder={`Enter your option`} dir='rtl' />
                        </Form.Item>

                        <Form.Item
                          {...restField}
                          name={[name, 'weightage']}
                          style={{ width: '100%' }}
                          rules={[
                            { required: true, message: 'Enter your Weightage' },
                          ]}
                          label={'Weightage'}
                        >
                          <Input
                            maxLength={17}
                            placeholder='Enter your weightage'
                            onKeyPress={(e) => {
                              const isDigit = /^\d$/.test(e.key);
                              const isBackspace = e.key === 'Backspace';
                              const isPoint = e.key === '.';
                              const inputValue = (e.target as HTMLInputElement)
                                .value;
                              if (
                                (!isDigit && !isBackspace && !isPoint) ||
                                (inputValue.replace(/\D/g, '').length >= 4 &&
                                  !isBackspace &&
                                  !isPoint)
                              ) {
                                e.preventDefault();
                              }
                            }}
                          />
                        </Form.Item>
                      </Row>
                    </div>
                  </div>
                ))}
                <Form.Item>
                  <Button type='dashed' onClick={() => add()} block>
                    {t('userLevel.buttons.addUserLevel')}
                  </Button>
                </Form.Item>
              </>
            )}
          </Form.List>
          <Button type='primary' htmlType='submit' className='btn_rgt'>{`${
            modalOperation == 'update' ? 'Update Question' : 'Add Question'
          }`}</Button>
        </Form>
      </Modal>
    </>
    //   <Button
    //     icon={!screens.xs ? <PlusOutlined /> : undefined}
    //     onClick={() => showModal('add')}
    //   >
    //     {t('userLevel.buttons.addQuestion')}
    //   </Button>
    // }
    // />
    //   < UserLevelTable

    // data = { data }

    //   />
    //   <Outlet />

    // <Modal
    //   className='right-modal'
    //   footer={null}
    //   centered={false}
    //   open={open}
    //   title={'Add Question'}
    //   onCancel={handleCancel}
    // >
    //   <Form
    //     layout='vertical'
    //     form={form}
    //     onFinish={(values) => handleSubmit(values)}
    //   >
    //     <Form.Item
    //       name='question'
    //       label='Question'
    //       rules={[{ required: true, message: 'Please Enter Question' }]}
    //     >
    //       <Input placeholder='Enter your Question' />
    //     </Form.Item>
    //     <Form.List name='OptionList' initialValue={[{}]}>
    //       {(fields, { add, remove }) => (
    //         <>
    //           {fields.map(({ key, name, ...restField }, index) => (
    //             <div key={key} className='ques_card'>
    //               <div className='head_bx'>
    //                 {index > 0 && (
    //                   <Row className='icon'>
    //                     <CloseOutlined onClick={() => remove(name)} />
    //                   </Row>
    //                 )}
    //               </div>
    //               <div className='option_ques'>
    //                 <Row className='option_item'>
    //                   <Form.Item
    //                     {...restField}
    //                     name={[name, 'option']}
    //                     style={{ width: '100%' }}
    //                     label={`Option ${index + 1}`}
    //                     rules={[
    //                       { required: true, message: 'Enter your Option' },
    //                     ]}
    //                   >
    //                     <Input placeholder={`Enter your option`} />
    //                   </Form.Item>
    //                 </Row>
    //                 <Row className='option_item'>
    //                   <Form.Item
    //                     {...restField}
    //                     name={[name, 'weightage']}
    //                     style={{ width: '100%' }}
    //                     rules={[
    //                       { required: true, message: 'Enter your Weightage' },
    //                     ]}
    //                     label={'Weightage'}
    //                   >
    //                     <Input
    //                       maxLength={17}
    //                       placeholder='Enter your weightage'
    //                       onKeyPress={(e) => {
    //                         const isDigit = /^\d$/.test(e.key);
    //                         const isBackspace = e.key === 'Backspace';
    //                         const isPoint = e.key === '.';
    //                         const inputValue = (e.target as HTMLInputElement)
    //                           .value;
    //                         if (
    //                           (!isDigit && !isBackspace && !isPoint) ||
    //                           (inputValue.replace(/\D/g, '').length >= 4 &&
    //                             !isBackspace &&
    //                             !isPoint)
    //                         ) {
    //                           e.preventDefault();
    //                         }
    //                       }}
    //                     />
    //                   </Form.Item>
    //                 </Row>
    //               </div>
    //             </div>
    //           ))}
    //           <Form.Item>
    //             <Button type='dashed' onClick={() => add()} block>
    //               {t('userLevel.buttons.addUserLevel')}
    //             </Button>
    //           </Form.Item>
    //         </>
    //       )}
    //     </Form.List>
    //     <Button type='primary' htmlType='submit' className='btn_rgt'>{`${modalOperation == 'update' ? 'Update Question' : 'Add Question'
    //       }`}</Button>
    //   </Form>
    // </Modal>
    //     </>
  );
};
