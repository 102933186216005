import { useMutation } from '@tanstack/react-query';
import { App } from 'antd';
import { handleError } from 'api';
import { fieldsQueryKeys } from 'features/fields';
import { SocialApiService } from 'features/social-match/api/social-api.service';
import i18n from 'locales/i18n';
import { queryClient } from 'providers';
// ../api/social-api.service
const { updateStatusSocial } = SocialApiService();

export const useUpdateStatusSocial = () => {
  const { message } = App.useApp();

  return useMutation(
    (payload: any) => {
      return updateStatusSocial(payload as any);
    },
    {
      onError: (error) => {
        const uiMessage = handleError(error);

        message.error(uiMessage);
      },
      onSuccess: (data, variables) => {
        Promise.all([
          queryClient.invalidateQueries({
            queryKey: fieldsQueryKeys.lists(),
          }),
          queryClient.invalidateQueries({
            queryKey: fieldsQueryKeys.detail(variables.id as any),
          }),
        ]);
        message.success(i18n.t('userLevel.update.statusChange'));
      },
    },
  );
};
