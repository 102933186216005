import { Button, Form, Grid, Input, Select } from 'antd';
import { ContentFilters } from 'components';
import { StandardItemsWrapper } from 'components/content-filters/content-filters.styles';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { CommunityFilterFormField, CommunityFilterFormValues } from './type';

const { useBreakpoint } = Grid;

type UsersWithWalletFilterFormProps = {
  formId: string;
  handleFilter: (values: CommunityFilterFormValues) => void;
  initialValues: CommunityFilterFormValues;
  closeModal?: () => void;
};

export const GroupsFilterForm = ({
  formId,
  handleFilter,
  initialValues,
  closeModal,
}: UsersWithWalletFilterFormProps) => {
  const [validateTrigger, setValidateTrigger] = useState(['onSubmit']);
  const [form] = Form.useForm<CommunityFilterFormValues>();
  const { t } = useTranslation();
  const screens = useBreakpoint();

  const onReset = () => {
    form.resetFields();
    handleFilter({ ...initialValues });
  };

  const handleOnFinish = (values: CommunityFilterFormValues) => {
    handleFilter(values);
    // setQueryFilters(values);
    closeModal?.();
  };

  return (
    <>
      <Form<CommunityFilterFormValues>
        id={formId}
        name={formId}
        form={form}
        layout='vertical'
        onFinish={handleOnFinish}
        initialValues={initialValues}
        onFinishFailed={() => {
          setValidateTrigger(['onChange']);
        }}
        validateTrigger={validateTrigger}
      >
        <ContentFilters.FormItemsWrapper>
          <StandardItemsWrapper>
            <Form.Item<CommunityFilterFormValues>
              label={'GroupType'}
              name={CommunityFilterFormField.type}
            >
              <Select
                style={{ width: 120 }}
                placeholder='All'
                options={[
                  { value: 0, label: 'All' },
                  { value: '1', label: 'Private' },
                  { value: '2', label: 'Public' },
                ]}
              />
            </Form.Item>
            <Form.Item<CommunityFilterFormValues>
              label={'GroupName'}
              name={CommunityFilterFormField.GroupName}
            >
              <Input placeholder={'Enter GroupName'} />
            </Form.Item>
          </StandardItemsWrapper>
        </ContentFilters.FormItemsWrapper>

        {screens.lg && (
          <ContentFilters.ButtonsWrapper>
            <Button type='primary' htmlType='submit'>
              {t('wallet.buttons.filter', { count: 1 })}
            </Button>
            <Button onClick={onReset}>{t('wallet.buttons.reset')}</Button>
          </ContentFilters.ButtonsWrapper>
        )}
      </Form>
    </>
  );
};
