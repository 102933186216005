import {
  QueryFunction,
  QueryKey,
  useInfiniteQuery,
  UseInfiniteQueryOptions,
  useQuery,
  UseQueryOptions,
} from '@tanstack/react-query';
import { App } from 'antd';
import { handleError } from 'api';
import { CompaniesParams } from 'features/companies';
import { companiesQueryKeys } from 'features/companies/use-cases/query-keys';
import {
  QuestionApiService,
  QuestionResponse,
} from 'features/user-level/api/questions-api.service';

const { getQuestions } = QuestionApiService();

const getCompaniesQuery = (
  params: CompaniesParams,
  options?: UseQueryOptions<QuestionResponse>,
): {
  queryKey: QueryKey;
  queryFn: QueryFunction<QuestionResponse>;
  options?: UseQueryOptions<QuestionResponse>;
} => ({
  queryKey: companiesQueryKeys.list(params),
  queryFn: ({ signal }) => getQuestions(params, { config: { signal } }),
  ...options,
});

export const useGetQuestion = (
  params: CompaniesParams,
  options?: UseQueryOptions<QuestionResponse>,
) => {
  const { message } = App.useApp();

  return useQuery<QuestionResponse, Error>(
    getCompaniesQuery(params, {
      onError: (error: unknown) => {
        const uiMessage = handleError(error);

        message.error(uiMessage);
      },
      ...options,
    }),
  );
};

export const useGetCompaniesInfiniteQuery = (
  params: CompaniesParams,
  options?: UseInfiniteQueryOptions<QuestionResponse>,
) => {
  const { message } = App.useApp();

  return useInfiniteQuery({
    queryKey: companiesQueryKeys.list(params),
    queryFn: ({ signal, pageParam }) =>
      getQuestions(
        { ...params, page: pageParam || params.page },
        { config: { signal } },
      ),
    getNextPageParam: (lastPage) => {
      const hasMorePages = lastPage.pages > lastPage.page;

      return hasMorePages ? lastPage.page + 1 : undefined;
    },
    onError: (error: unknown) => {
      const uiMessage = handleError(error);

      message.error(uiMessage);
    },
    ...options,
  });
};
