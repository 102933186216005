import { apiClient, ApiClientConfig, PaginatedRequestParams } from 'api';
import { label } from 'types/label';
export type CreateLabelDTO = {
  title?: string;
  description?: string | null;
};
export type UpdateLabelDTO = {
  id?: string;
  title?: string;
  description?: string;
  title_arabic?: string;
  description_arabic?: string;
};
export const LabelApiService = () => {
  const baseURL = process.env.REACT_APP_BASE_V2_URL;
  const createUser = async (
    payload: CreateLabelDTO,
    callConfig?: Partial<ApiClientConfig>,
  ) => {
    const response = await apiClient<label>({
      ...callConfig,
      endpoint: `${baseURL}/Admin/socialmatch/UpdateLevelMaster`,
      method: 'POST',
      data: payload,
    });

    return response.data;
  };
  const getLabel = async (
    params: PaginatedRequestParams,
    callConfig?: Partial<ApiClientConfig>,
  ) => {
    const response = await apiClient<any>({
      ...callConfig,
      endpoint: `${baseURL}/admin/SocialMatch/MatchLevel`,
      params,
    });

    return response.data;
  };
  const updateStatusSocial = async (
    payload: any,
    callConfig?: Partial<ApiClientConfig>,
  ) => {
    const { id, ...data } = payload;

    const response = await apiClient<any>({
      ...callConfig,
      endpoint: `${baseURL}/admin/SocialMatch/UpdateLevelStatus`,
      method: 'POST',
      data,
    });

    return response.data;
  };
  const getLabeldetails = async (
    id: string,
    callConfig?: Partial<ApiClientConfig>,
  ) => {
    const response = await apiClient<any>({
      ...callConfig,
      endpoint: `${baseURL}/admin/SocialMatch/MatchLevel?${id}`,
    });

    return response.data;
  };
  const updateLabel = async (
    payload: UpdateLabelDTO,
    callConfig?: Partial<ApiClientConfig>,
  ) => {
    const { id, ...data } = payload;

    const response = await apiClient<any>({
      ...callConfig,
      endpoint: `${baseURL}/Admin/socialmatch/UpdateLevelMaster`,
      method: 'POST',
      data: {
        id,
        ...data, // Include other data from the payload
      },
    });

    return response.data;
  };

  return {
    createUser,
    getLabel,
    getLabeldetails,
    updateLabel,
    updateStatusSocial,
  };
};
