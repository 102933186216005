export enum LabelFormField {
  title = 'title',
  description = 'description',
  title_arabic = 'title_arabic',
  description_arabic = 'description_arabic',
}
export type LabelFormValues = {
  [LabelFormField.title]: any;
  [LabelFormField.description]: any;
  [LabelFormField.title_arabic]: any;
  [LabelFormField.description_arabic]: any;
};
