import { PackageDetails } from 'features/package/components/package-details/Package-details';
import { useNavigate, useParams } from 'react-router-dom';
import { ROUTES } from 'routes/constants';
export const PackageDetailsView = () => {
  const navigate = useNavigate();
  const { id } = useParams();

  if (!id) return null;

  const navigateToList = () => {
    navigate(ROUTES.PACKAGE_MASTER);
  };

  return <PackageDetails Id={id} onSuccess={navigateToList} />;
};
