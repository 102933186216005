import { Form, FormInstance, Input } from 'antd';
import TextArea from 'antd/es/input/TextArea';
import { LabelFormField } from 'features/social-label/components/interface/type';
import { FormSectionsSeparator } from 'features/users/components/modals/form-sections-separator';
import { UserFormValues } from 'features/users/components/users-filter-form';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

export const SocialInfoFormItems = ({
  form,
}: {
  form: FormInstance<UserFormValues>;
}) => {
  const { t } = useTranslation();
  useEffect(() => {
    form.getFieldValue('label');
  });

  return (
    <FormSectionsSeparator title={t('social.formfield.labelinfo')}>
      <Form.Item<LabelFormField>
        label={'Title in English'}
        name={LabelFormField.title}
        rules={[{ required: true, message: 'Please enter tittle' }]}
      >
        <Input placeholder='Enter your Tittle' />
      </Form.Item>
      <Form.Item<LabelFormField>
        label={'Title in Arabic'}
        name={LabelFormField.title_arabic}
        rules={[{ required: true, message: 'Please enter tittle' }]}
      >
        <Input placeholder='Enter your Tittle' dir='rtl' />
      </Form.Item>
      <Form.Item<LabelFormField>
        label={'Description in English'}
        name={LabelFormField.description}
        rules={[{ required: true, message: 'Please enter description' }]}
      >
        <TextArea placeholder='Enter your description' />
      </Form.Item>
      <Form.Item<LabelFormField>
        label={'Description in Arabic'}
        name={LabelFormField.description_arabic}
        rules={[{ required: true, message: 'Please enter description' }]}
      >
        <TextArea
          placeholder='Enter your description'
          style={{ textAlign: 'right' }}
        />
      </Form.Item>
    </FormSectionsSeparator>
  );
};
