import { Form, Spin } from 'antd';
import { Loader, Modal, Result404 } from 'components';
import { UpdateBadgeDTO } from 'features/user-level/api/questions-api.service';
import {
  BadgeFormDataFields,
  BadgeFormFields,
} from 'features/user-level/components/interface/type';
import { BadgeDataInfoFormItems } from 'features/user-level/components/modals/badge-info';
import { useGetBadge } from 'features/user-level/use-cases/get-badge';
import { useUpdateBadge } from 'features/user-level/use-cases/update.badge';
import { StyledUserModalWrapper } from 'features/users/components/modals/user-modal.styles';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
export const EditBadgeModal = ({
  onClose,
  onSuccess,
  id,
}: {
  onClose: () => void;
  onSuccess: () => void;
  id: string;
}) => {
  const [form] = Form.useForm();
  const [validateTrigger, setValidateTrigger] = useState(['onSubmit']);
  const formName = 'edit-user-form';
  //   const { id } = useParams();
  const { t } = useTranslation();
  const userQueryResult = useGetBadge('' as any);
  const {
    isLoading: isLoadingGetQuestion,
    isFetching: isFetchingGetQuestion,
    data: questionData,
    isError: isErrorQuestion,
  } = userQueryResult;
  const { mutate: mutateUpdateQuestion, isLoading: isLoadingUpdateQuestion } =
    useUpdateBadge();
  const isLoading = {
    loading:
      isLoadingGetQuestion || isFetchingGetQuestion || isLoadingUpdateQuestion,
    isLoadingGetQuestion,
    isLoadingUpdateQuestion,
  };
  const handleUpdate = (formData: BadgeFormDataFields) => {
    const payload: UpdateBadgeDTO = {
      id: id,
      title: formData.title,
      description: formData.description,
      title_arabic: formData.title_arabic,
      description_arabic: formData.description_arabic,
    };
    mutateUpdateQuestion(payload as any, { onSuccess });
  };
  const getInitialValues = () => ({
    [BadgeFormFields.title]: questionData?.title,
    [BadgeFormFields.description]: questionData?.description,
    [BadgeFormFields.title_arabic]: questionData?.title_arabic,
    [BadgeFormFields.descriptionArabic]: questionData?.description_arabic,
  });

  const initialValues = getInitialValues();

  return (
    <StyledUserModalWrapper>
      <Modal
        open
        centered
        className='right-modal'
        title={t('userLevel.Bedage.UpdateBadge')}
        onCancel={() => {
          document.body.style.overflow = 'auto';
          onClose(); // Call onClose function
        }}
        getContainer={false}
        onOk={form.submit}
        okText={t('userLevel.Bedage.UpdateBadge')}
        cancelButtonProps={{ style: { display: 'none' } }}
        okButtonProps={{
          form: formName,
        }}
      >
        {isLoadingGetQuestion ? (
          <Loader />
        ) : isErrorQuestion ? (
          <Result404 />
        ) : (
          <Spin spinning={isLoading.loading}>
            <Form
              id={formName}
              name={formName}
              form={form}
              layout='vertical'
              initialValues={initialValues}
              requiredMark={'optional'}
              onFinishFailed={() => {
                setValidateTrigger(['onChange']);
              }}
              validateTrigger={validateTrigger}
              disabled={isLoading.loading}
              onFinish={handleUpdate}
            >
              <BadgeDataInfoFormItems form={form} />
              {/* <PasswordFormItems title={t('adminUsers.accountInfo')} /> */}
            </Form>
          </Spin>
        )}
      </Modal>
    </StyledUserModalWrapper>
  );
};
