import { WithdrawViewModal } from 'features/withdraw/components/modal/withdraw-view-data';
import { useNavigate, useParams } from 'react-router-dom';
import { ROUTES } from 'routes/constants';
// ../components/modal/withdraw-detail
// ../components/modal/withdraw-view-data
export const WithdrawViewDetails = () => {
  const navigate = useNavigate();
  const { id } = useParams();

  if (!id) return null;

  const closeModal = () => {
    navigate(ROUTES.WITHDRAW);
  };

  return (
    <WithdrawViewModal id={id} onClose={closeModal} onSuccess={closeModal} />
  );
};
